import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewAppointment from "../../ViewAppointments/view-appointments.component";
import { getBusinessBookings } from "../../../Backend API's/SlotsAPI/appointment";
import { getCurrentUser } from "../../../Redux/user/user.selector";
import { getBusinessByUserID } from "../../../Backend API's/BusinessAPI/business_api";

const ViewBookedSlots = () => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBusinessByUserID(currentUser?.token);
      const businessId = response.data[0]._id;
      const resp = await getBusinessBookings(currentUser?.token, businessId);
      setAllEvents(resp.data);
      console.log(resp, resp.data);
      const interim = [];
      for (let i = 0; i < resp.data.length; i++) {
        const item = resp.data[i];
        console.log(item, i);
        interim.push({
          id: item._id,
          title: item?.user
            ? item.user?.firstname + " " + item.user.lastname
            : item?.unregisteredUserName,
          start: item.startTime,
          end: item.endTime,
        });
      }
      console.log(interim);
      setEvents(interim);
    };
    fetchData().catch((error) => console.error);
  }, []);
  return (
    <>
      {allEvents && events ? (
        <ViewAppointment
          allEvents={allEvents}
          events={events}
          role={"business"}
        />
      ) : (
        <div> Processing your appointments</div>
      )}
    </>
  );
};

export default ViewBookedSlots;
