import React from "react";
import { FaStar } from "react-icons/fa";
import ReadMore from "../../Components/ReadMoreReadLess/readMoreReadLess.component";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedBusiness } from "../../Redux/Businesses/businesses.action";

const DisplayBusinessesList = ({ businesses }) => {
  const dispatch = useDispatch();
  return businesses.map((businessObj) => {
    const { _id, name, images, rating, category, description, location } =
      businessObj;
    return (
      <div
        key={_id}
        className="md:grid md:grid-cols-5 sm:flex sm:flex-col max-h-max w-full px-2 py-2 pb-4 pt-4 border-b-2"
      >
        <Link
          to={`/businesses/${_id}`}
          className="rounded-md md:col-span-2 sm:w-full"
          //class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          onClick={() => dispatch(setSelectedBusiness(businessObj))}
          style={{
            backgroundImage: `url(${images[0]?.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="flex flex-col px-4 w-full col-span-3">
          <Link
            to={`/businesses/${_id}`}
            onClick={() => dispatch(setSelectedBusiness(businessObj))}
            className="md:text-xl sm:text-sm font-semibold"
          >
            {name} 
          </Link>
          <div className="text-sm" style={{color: 'green'}}>
            {
              businessObj?.dist?.calculated && <p>{`${(businessObj?.dist?.calculated * 0.000621371).toFixed(0)} miles away`}</p>
            }
          </div>
          <div className="text-sm"> {location?.formattedAddress}</div>
          <div className="flex flex-row ">
            {[...Array(5)].map((star, i) => {
              const ratingValue = i + 1;
              return (
                <FaStar
                  className="hover:cursor-pointer"
                  color={ratingValue <= rating ? "#ffc107" : "lightgray"}
                  size={20}
                />
              );
            })}
            {rating > 4.5 ? (
              <div className="text-sm px-2"> Exceptional</div>
            ) : (
              <div className="text-sm px-2"> Awesome</div>
            )}
          </div>
          <div className="text-sm"> {category.title}</div>
          {/* <div className='grid grid-cols-5 h-10 pt-1 justify-between '>
                {
                    appointments.map((item) => {
                        console.log(item);
                        let time = item.split(":");
                        return (<button className="bg-black text-red-50 px-2 w-20 col-span-1 hover:bg-white hover:border hover:text-black hover:font-bold h-10 rounded-md  border-2 items-center justify-center">
                            {time[0] + ':' + time[1]}
                        </button>)
                    }
                    )
                }

            </div> */}
          <div className="flex text-sm pt-3">
            <ReadMore dataLimit="200">{description}</ReadMore>
          </div>
        </div>
      </div>
    );
  });
};

export default DisplayBusinessesList;
