import React, { useState } from "react";
import CustomButton from "../CustomButton/custom-button.component";
import DatePicker from "react-datepicker";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getServices } from "../../Redux/Businesses/businesses.selector";
import ManageSlots from "./dialogactions.component";

const MakeAppointment = ({ businessId, currentUser }) => {
  const services = useSelector(getServices);
  const [service, setService] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "service")
      setService(services.find((services) => services._id === value));
  };
  const handleDateChange = (date) => {
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = new Date(currentYear, currentMonth, currentDayOfMonth);
    setDate(dateString);
  };

  // const toggleDatePicker = () => {
  //     setDatePickerShown(prevState => !prevState);
  // }

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center shadow-xl border-2  justify-center px-4 py-9 w-full h-96">
          <div className="md:text-xl sm:text-sm bg-fuchsia-50 w-full shadow-xl text-center font-bold py-4 rounded-md">
            Make an Appointment
          </div>
          {!currentUser ? (
            <div className="mt-2 text-rose-700 text-center w-full ">
              {" "}
              Please{" "}
              <Link
                to="/signinandsignup"
                className="underline hover:text-blue-700 font-bold"
              >
                Login/Sign up
              </Link>{" "}
              to make appointment with our business.
            </div>
          ) : (
            <div />
          )}

          <div className="text-md pt-5 pb-2 font-bold ">Select Services</div>
          <select
            id="service"
            value={service._id}
            name="service"
            required
            className="required focus:outline-none focus:border-fuchsia-900 w-48 wrap-content border-fuchsia-900 border-b-2 text-sm border-transparent bg-white text-md focus:border-b-2"
            placeholder="Service"
            onChange={handleChange}
          >
            <option disabled selected value="" hidden>
              Select Service
            </option>
            {services?.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
          <div className=" text-md font-bold pb-2 pt-4">Select Date </div>
          <div className=" focus:outline-none border-fuchsia-900 w-48 border-b-2 ">
            <DatePicker
              onChange={handleDateChange}
              selected={date}
              dateFormat="MMMM dd, yyyy"
              minDate={new Date()}
              // maxDate={addDays(new Date(), 7)}
            />
          </div>

          {/* <div className=" focus:outline-none border-fuchsia-900 w-48 border-b-2 ">
                <DatePicker
                    onChange={handleDateChange}
                    selected={date}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                // maxDate={addDays(new Date(), 7)} 
                />
            </div> */}

          <div className="flex flex-col w-full items-center justify-center ">
            <CustomButton type="submit" onClick={() => setDialogOpen(true)}>
              {" "}
              Book Appointment{" "}
            </CustomButton>
          </div>

          {dialogOpen && (
            <ManageSlots
              service={service}
              businessId={businessId}
              date={date.toISOString()}
              setDialogOpen={setDialogOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(MakeAppointment);

// services={services}
// selectedService={service}
// date={date}
// handleDateChange={handleDateChange}
// handleChange={handleChange}
