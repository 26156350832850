import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addServiceToBusiness = (formData, token, businessId) => {
    console.log(formData)
    return axios({
        method: "POST",
        data: formData,
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/services`,
    }).catch((error) => {
        //console.log("In axios", error.response);
        throw error;
    });
};

export const getServicesByBusinessId = (token, businessId) => {
    return axios({
        method: "GET",
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/services`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};