import React, { useState } from 'react';
import { addCategory } from '../../Backend API\'s/Authentication/categories.api';
import CustomButton from '../CustomButton/custom-button.component';

const AddCategory = () => {
    const [category, setCategory] = useState('');
    const [image, setImage] = useState([]);

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', category);
        formData.append('image', image);
        try {
            const res = await addCategory(formData);
        }
        catch (error) {
            alert(error.response.data.message);
            console.log(error.response.data.message)
        }
        setCategory('');
        setImage([]);

    }

    const handleChange = (event) => {
        const { name, value, files } = event.target;

        if (name === "category") {
            setCategory(value);
        }
        else if (name === "image") {
            setImage(files[0]);
        }

    }

    return (
        <div className=" flex flex-col items-center w-full">
            <div className="w-1/2">
                <div className=" justify-center">
                    <div>

                        <h2 className="mt-6 text-center md:text-3xl sm:text-sm font-extrabold text-gray-900">AddCategory</h2>
                        {/* {error ?
                                        (<p className=" mt-2 text-center md:text-xl sm:text-sm text-gray-600">

                                            <span className="font-medium text-red-600 "> {error}</span>
                                        </p>) : (<div />)} */}

                    </div>
                    <form className="mt-8 space-y-6 " action="/" onSubmit={handleSubmit} encType="multipart/form-data">
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm -space-y-px">

                            <div>
                                <label htmlFor="category" className="sr-only">Category</label>
                                <input value={category} id="category" name="category" type="text" required className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" onChange={handleChange} placeholder="Category" />
                            </div>
                            <div>
                                <input type="file" accept=".png, .jpg, .jpeg" multiple onChange={handleChange} name="image" id="" className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />
                            </div>

                        </div>
                        <div className="flex flex-col h-24 items-center justify-center">
                            <CustomButton type="submit">Add Category</CustomButton>
                        </div>



                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddCategory;