
import { SET_SELECTED_MARKER, REMOVE_SELECTED_MARKER } from "./mapview.types"

const INITIAL_STATE = {
    selectedMarker: null
}

const mapViewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_MARKER:
            return {
                ...state,
                selectedMarker: action.payload
            }
        case REMOVE_SELECTED_MARKER:
            return {
                ...state,
                selectedMarker: null
            }
        default:
            return state;
    }
}

export default mapViewReducer;
