/* eslint import/no-webpack-loader-syntax: off */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Map, { Marker } from "react-map-gl";
//import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import {
  getSelectedBusiness,
  getServices,
} from "../../Redux/Businesses/businesses.selector";

mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

const ContactTab = () => {
  const business = useSelector(getSelectedBusiness);
  const services = useSelector(getServices);

  const [viewState, setViewState] = useState({
    longitude: -78.82282,
    latitude: 42.986482,
    zoom: 14,
  });

  const [marker, setMarker] = useState([-78.82282, 42.986482]);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      // const res = await getBusinessesDetailsByBusinessId(businessId, currentUser?.token);

      // console.log(res.data.location.formattedAddress);
      setViewState({
        longitude: business.location.coordinates[0],
        latitude: business.location.coordinates[1],
        zoom: 14,
      });
      // console.log(res.data)
      // setBusiness(res.data);
      setMarker(business.location.coordinates);
      // const resp = await getServicesByBusinessId(currentUser?.token, res.data[0]?._id);

      // const resp = await getServicesByBusinessId(currentUser?.token, businessId);
      // const services = resp.data;
      // console.log(resp.data);
      services.map((service) => {
        // setMarkers((prevMarkers) => [
        //     ...prevMarkers,
        //     {
        //         longitude: service.location.coordinates[0],
        //         latitude: service.location.coordinates[1],
        //     },
        // ]);
        console.log(service.location.coordinates);
      });
    };

    // call the function, make sure to catch any error
    fetchData().catch(console.error);
  }, []);

  return (
    <div className="flex flex-col w-full h-full mt-5 ">
      <div className="flex md:text-xl sm:text-sm ">
        <b>Address</b> : {business?.location?.formattedAddress}
      </div>
      <div className="flex md:text-xl sm:text-sm  ">
        <b>Phone</b> : {business?.phone}
      </div>

      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{
          borderStyle: "solid",
          borderWidth: 4,
          borderColor: "black",
          marginBlock: 20,
          width: "80%",
          height: "50vh",
        }}
        mapboxAccessToken="pk.eyJ1Ijoic2FyYS1yZGthciIsImEiOiJjbDI3b24wNzIwMDhtM2RuN2NucHFyY3EzIn0.tEcoXi9a7knVQ-HUGNCzrw"
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        {/* {
                markers.map((longitude, latitude) => {
                    <Marker longitude={longitude} latitude={latitude} color="red" />

                })
            } */}
        <Marker
          longitude={marker[0]}
          latitude={marker[1]}
          className="animate-bounce"
          color="  red"
        />
      </Map>
    </div>
  );
};

export default ContactTab;
