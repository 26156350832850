import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBusinessByUserID } from "../../../../Backend API's/BusinessAPI/business_api";
import {
  addStaff,
  getStaffByBusiness,
} from "../../../../Backend API's/StaffAPI/staff.api";
import useInput from "../../../../hooks/use-input";
import CustomButton from "../../../CustomButton/custom-button.component";
import StaffListItem from "../staffListItem/staffListItem.component";
import StaffSocialsInputComponent from "../StaffSocialsInput/StaffSocialsInput.component";
import { useSnackbar } from "notistack";

const FORM_CONTROL = "form-control";

const StaffList = ({ currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [allStaff, setAllStaff] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [socialMediaSections, setSocialMediaSections] = useState(1);
  const [businessId, setBusinessId] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const {
    value: enteredFirstName,
    isTouched: isEnteredFirstNameTouched,
    hasError: isEnteredFirstNameInvalid,
    inputChangeHandler: firstNameChangeHandler,
    inputBlueHandler: firstNameBlurHandler,
    reset: firstNameReset,
    patch: firstNamePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredLastName,
    isTouched: isEnteredLastNameTouched,
    hasError: isEnteredLastNameInvalid,
    inputChangeHandler: lastNameChangeHandler,
    inputBlueHandler: lastNameBlurHandler,
    reset: lastNameReset,
    patch: lastNamePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredEmail,
    isTouched: isEnteredEmailTouched,
    hasError: isEnteredEmailInvalid,
    inputChangeHandler: emailChangeHandler,
    inputBlueHandler: emailBlurHandler,
    reset: emailReset,
    patch: emailPatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredPhone,
    isTouched: isEnteredPhoneTouched,
    hasError: isEnteredPhoneInvalid,
    inputChangeHandler: phoneChangeHandler,
    inputBlueHandler: phoneBlurHandler,
    reset: phoneReset,
    patch: phonePatch,
  } = useInput((value) => value.trim().length > 0);

  let isFormValid = false;

  if (
    !(
      isEnteredFirstNameInvalid &&
      isEnteredLastNameInvalid &&
      isEnteredEmailInvalid &&
      isEnteredPhoneInvalid
    )
  ) {
    isFormValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }

    var socialMedia = {};
    const _ = [...Array(socialMediaSections)].map((item, key) => {
      const selection = event.target.elements?.[`social_media_${key}`]?.value;
      const otherKey =
        event.target.elements?.[`social_media_other_${key}`]?.value;
      if (selection === "other") {
        socialMedia[otherKey] =
          event.target.elements[`social_media_other_url_${key}`].value;
      } else if (selection !== "Choose an option") {
        socialMedia[selection] =
          event.target.elements[`social_media_other_url_${key}`].value;
      }
    });

    //socialMedia = JSON.stringify(socialMedia);
    console.log("Social Media", socialMedia);

    const data = {
      firstname: enteredFirstName,
      lastname: enteredLastName,
      email: enteredEmail,
      phone: enteredPhone,
      socialmedia: socialMedia,
    };

    try {
      const res = await getBusinessByUserID(currentUser?.token);
      const businessId = res.data[0]?._id;

      const addRes = await addStaff(data, currentUser?.token, businessId);
      enqueueSnackbar("Staff added", { variant: "info" });
      refreshAllStaffListHandler();
      handleCancel();
    } catch (error) {
      // alert(error);
    }
  };

  const clearForm = () => {
    firstNameReset();
    lastNameReset();
    emailReset();
    phoneReset();
  };

  const handleCancel = () => {
    clearForm();
    handleFormDisplay(false);
  };

  const handleFormDisplay = (isShow) => {
    setShowForm(isShow);
  };

  useEffect(() => {
    getAllStaff();
  }, [refresh]);

  const getAllStaff = async () => {
    setIsLoading(true);
    try {
      const res = await getBusinessByUserID(currentUser?.token);
      const businessId = res.data[0]?._id;
      setBusinessId(businessId);
      const resAllStaff = await getStaffByBusiness(
        businessId,
        currentUser?.token
      );
      setAllStaff([...resAllStaff?.data?.staff]);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const refreshAllStaffListHandler = () => {
    setRefresh((prev) => prev + 1);
  };

  const firstNameClasses = isEnteredFirstNameInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const lastNameClasses = isEnteredLastNameInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const emailClasses = isEnteredEmailInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const phoneClasses = isEnteredPhoneInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  return (
    <>
      {!showForm && (
        <span>
          <CustomButton
            type="button"
            primary="bg-pink-500"
            secondary="bg-lime-500"
            onClick={(e) => handleFormDisplay(true)}
          >
            Add Staff
          </CustomButton>
        </span>
      )}
      {showForm && (
        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className={firstNameClasses}>
              <label htmlFor="firstName">
                First Name<em class="error-text">*</em>
              </label>
              <input
                type="text"
                id="firstName"
                value={enteredFirstName}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {isEnteredFirstNameInvalid && (
                <p className="error-text">First Name can not be empty</p>
              )}
            </div>
            <div className={lastNameClasses}>
              <label htmlFor="lastName">
                Last Name<em class="error-text">*</em>
              </label>
              <input
                type="text"
                id="lastName"
                value={enteredLastName}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
              />
              {isEnteredLastNameInvalid && (
                <p className="error-text">Last Name can not be empty</p>
              )}
            </div>
            <div className={emailClasses}>
              <label htmlFor="lastName">
                Email<em class="error-text">*</em>
              </label>
              <input
                type="email"
                id="email"
                value={enteredEmail}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {isEnteredEmailInvalid && (
                <p className="error-text">Please enter a valid Email </p>
              )}
            </div>
            <div className={phoneClasses}>
              <label htmlFor="lastName">
                Phone<em class="error-text">*</em>
              </label>
              <input
                type="p"
                id="email"
                value={enteredPhone}
                onChange={phoneChangeHandler}
                onBlur={phoneBlurHandler}
              />
              {isEnteredPhoneInvalid && (
                <p className="error-text">Please enter a valid Phone number </p>
              )}
            </div>
            <div style={{ flexBasis: "100%" }}></div>
            {[...Array(socialMediaSections)].map((item, key) => (
              <StaffSocialsInputComponent suffix={key} key={key} />
            ))}
            <div
              className="form-control social-media-container"
              style={{ paddingTop: "0.5em" }}
            >
              {
                <CustomButton
                  type="button"
                  primary="bg-blue-700"
                  secondary="bg-blue-500"
                  onClick={() => {
                    setSocialMediaSections(socialMediaSections + 1);
                  }}
                >
                  +
                </CustomButton>
              }
            </div>
          </div>
          <div className="form-actions">
            <CustomButton
              type="submit"
              disabled={!isFormValid}
              primary="bg-lime-700"
              secondary="bg-lime-500"
            >
              Submit
            </CustomButton>
            <CustomButton
              type="button"
              primary="bg-rose-700"
              secondary="bg-rose-500"
              onClick={handleCancel}
            >
              cancel
            </CustomButton>
          </div>
        </form>
      )}
      <hr />
      {allStaff.map((item) => (
        <StaffListItem
          details={item}
          key={item._id}
          refreshAllStaffListHandler={refreshAllStaffListHandler}
          businessId={businessId}
        />
      ))}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(StaffList);
