import React from "react";
import Dialog from "../DialogBox/dialogbox.component";

export const PaymentDialog = ({ currentBooking, setPay, setDialogOpen }) => {
  console.log(currentBooking);

  const handleCloseDialog = async () => {
    setDialogOpen(false);
  };
  const handlePayment = () => {
    //hit payment Api
    console.log("Current Booking -> ", currentBooking);
  };

  const DialogMessageActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-green-600 
              font-medium text-white hover:bg-green-700 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          //to={`/booking/${currentBooking?.bookedSlot._id}`}
          onClick={() => setPay(false)}
        >
          Back
        </button>

        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
              font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseDialog}
        >
          Close
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
              font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handlePayment}
        >
          Continue to Pay
        </button>
      </div>
    );
  };
  return (
    <Dialog
      title={`Are you sure you want to continue to pay for this appointment`}
      handleCloseDialog={handleCloseDialog}
      actionsPannel={DialogMessageActions("bg-red")}
      size={"w-4/5"}
      color={"bg-red"}
    ></Dialog>
  );
};
