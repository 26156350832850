export const SET_ALL_BUSINESSES = 'SET_ALL_BUSINESSES';
export const REMOVE_ALL_BUSINESSES = 'REMOVE_ALL_BUSINESSES';
export const SET_SEARCHED_BUSINESSES = 'SET_SEARCHED_BUSINESSES';
export const REMOVE_SEARCHED_BUSINESSES = 'REMOVE_SEARCHED_BUSINESSES';
export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';
export const REMOVE_SELECTED_BUSINESS = 'REMOVE_SELECTED_BUSINESS';
export const SET_MARKERS = 'SET_MARKERS';
export const REMOVE_MARKERS = 'REMOVE_MARKERS';



