import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import "./category-item.styles.scss";

const CategoryItem = ({ id, title, imageUrl, size }) => {
  const { url, path } = useRouteMatch();

  return (
    <Link to={`/categories/${id}`} className="category-item">
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <div className="content">
        <div className="title md:md:text-2xl sm:text-sm sm:text-sm"> {title}</div>
      </div>
    </Link>
  );
};

export default CategoryItem;
