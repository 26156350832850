import React, { createRef, useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { getSelectedBusiness } from "../../Redux/Businesses/businesses.selector";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import DatePicker from "react-datepicker";
import { CyanCustomButton } from "../CustomButton/custom-button.component";
import { getOpenAppointments } from "../../Backend API's/SlotsAPI/appointment";
import { DateTime } from "luxon";
import ManageSlots from "../MakeAppointment/dialogactions.component";

const ViewOpenAppointments = () => {
  const selectedBusiness = useSelector(getSelectedBusiness);
  const [selectedService, setSelectedService] = useState({});
  const [openAppointments, setOpenAppointments] = useState([]);
  const [dialogOpen, setDialogOpen] = useState("");
  const [date, setDate] = useState(new Date());

  const fetchData = async (data) => {
    const response = await getOpenAppointments(data);
    console.log(response);
    setOpenAppointments(
      response.data.map((appt) => {
        return {
          id: appt.start,
          start: appt.start,
          end: DateTime.fromISO(appt.start).plus({ hours: 24 }).toISO(),
          title: appt.open_slots + "  slots open",
        };
      })
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedService(
      selectedBusiness?.services.find(
        (service) => service._id === event.target.value
      )
    );
    const data = {
      serviceId: value,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      date: new Date().toISOString(),
    };
    fetchData(data).catch((error) => console.error);
  };
  // const handleDateChange = (date) => {
  //   const currentDayOfMonth = date.getDate();
  //   const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
  //   const currentYear = date.getFullYear();

  //   const dateString = new Date(currentYear, currentMonth, currentDayOfMonth);
  //   setDate(dateString);
  // };

  const handleEventClick = ({ event, el }) => {
    setDate(event._def.publicId);
    setDialogOpen(true);
  };

  const calendarRef = createRef();
  return (
    <>
      <div class="flex flex-col">
        <div class="flex flex-row mb-2 space-x-1">
          <div>
            <p className="text-md  font-bold ">Select Services</p>
            <select
              id="service"
              value={selectedService._id}
              name="service"
              required
              className="required focus:outline-none focus:border-fuchsia-900 w-48 wrap-content border-fuchsia-900 border-b-2 text-sm border-transparent bg-white md:text-md focus:border-b-2"
              placeholder="Service"
              onChange={handleChange}
            >
              <option disabled selected value="" hidden>
                Select Service
              </option>
              {selectedBusiness?.services?.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div>
          <p className=" text-md font-bold">Select Date </p>
          <div className=" focus:outline-none border-fuchsia-900 w-48 border-b-2 ">
            <DatePicker
              onChange={handleDateChange}
              selected={date}
              dateFormat="MMMM dd, yyyy"
              minDate={new Date()}
              // maxDate={addDays(new Date(), 7)}
            />
          </div>
        </div> */}
        </div>

        <FullCalendar
          events={openAppointments}
          eventBackgroundColor="#378006"
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          initialView="dayGridMonth"
          customButtons={{
            timeDayButton: {
              text: "day",
              click() {
                const calendar = calendarRef.current;
                if (calendar) {
                  const calendarApi = calendar.getApi();
                  calendarApi.changeView("timeGridDay");
                }
              },
            },
            timeWeekButton: {
              text: "week",
              click() {
                const calendar = calendarRef.current;
                if (calendar) {
                  const calendarApi = calendar.getApi();
                  calendarApi.changeView("timeGridWeek");
                }
              },
            },
          }}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "today,dayGridMonth,timeDayButton,timeWeekButton,listWeek",
          }}
          eventClick={handleEventClick}
        />
      </div>

      {dialogOpen && (
        <ManageSlots
          service={selectedService}
          businessId={selectedBusiness._id}
          date={date}
          setDialogOpen={setDialogOpen}
        />
      )}
    </>
  );
};

export default ViewOpenAppointments;
