import React from "react";
import SignIn from "../../Components/SignIn/sign-in.component";
import SignUp from "../../Components/SignUp/sign-up.component";
import "./sign-in-and-sign-up.styles.scss";

const SignInAndSignUpPage = () => {
  return (
    <div className="home">
      <div className="sm:text-sm  my-3.5 md:md:text-3xl sm:text-sm">
        Want to book an appointment with our Businesses?{" "}
      </div>
      <div className="sm:text-sm md:md:text-3xl sm:text-sm">
        Want to register your business with us?{" "}
      </div>
      <div>
        <b className="text-rose-700 sm:text-sm md:md:text-3xl sm:text-sm">Single platform!</b>{" "}
        Sign up now{" "}
      </div>

      <div className="md:w-full flex flex-col space-y-8 md:flex-row md:justify-between md:mr-3.5 md:my-7 ">
        <SignIn />
        <SignUp />
      </div>
    </div>
  );
};

export default SignInAndSignUpPage;
