import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "../DialogBox/dialogbox.component";
import { FaFilter } from "react-icons/fa";
import CustomButton from "../CustomButton/custom-button.component";
import DatePicker from "react-datepicker";
import { fetchServicesWithinCategory } from "../../Backend API's/Services/service";
import { getAppointmentsInCategory } from "../../Backend API's/SlotsAPI/appointment";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import BookSlot from "../MakeAppointment/ActonsAfterSlotSelected";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { getBusinessByUserID } from "../../Backend API's/BusinessAPI/business_api";
import { SlotCards } from "./SlotCards.component";

const SearchBusinessesAcrossCategories = ({ categoryId }) => {
  const [open, setOpen] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedService, setSelectedService] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [businessSlots, setBusinessSlots] = useState(null);
  const [next, setNext] = useState(false);
  //const [staff, setStaff] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [service, setService] = useState(null);
  const [currentUserBusinessId, setCurrentUserBusinessId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedService(value);
    console.log(name, value);
  };
  const handleDateChange = (date, dateType) => {
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = new Date(currentYear, currentMonth, currentDayOfMonth);
    if (dateType === "start") setStartDate(dateString);
    else setEndDate(dateString);
  };
  const handleCloseDialog = () => {
    setNext(false);
    setSelectedService("");
    setBusinessSlots(null);
    setSelectedSlots(null);
    setService(null);
    setSelectedBusiness(null);
    setStartDate("");
    setEndDate("");
    //setServiceList([]);
    setOpen(false);
  };
  const handleBook = (e, business, service, slots) => {
    e.preventDefault();
    setSelectedSlots(slots);
    setService(service);
    setSelectedBusiness(business);
    setNext(true);
    console.log(business, service, slots);
  };

  useEffect(() => {
    // api to fetch all services within 1 category

    const fetchServices = async () => {
      const resp = await fetchServicesWithinCategory(categoryId);
      // const res = await getBusinessByUserID(currentUser?.token);
      // const userBusinessId = res.data[0]?._id;
      // setCurrentUserBusinessId(userBusinessId || null);

      setServiceList(resp.data);
      console.log(resp.data);
    };

    fetchServices().catch(console.error);
    // setServiceList();
  }, []);
  const handleSubmit = async () => {
    const startDt = startDate.toISOString();
    const endDt = endDate.toISOString();
    const data = {
      categoryId: categoryId,
      serviceName: selectedService,
      startDate: startDt,
      endDate: endDt,
    };
    console.log("Submit called", data);
    try {
      const resp = await getAppointmentsInCategory(data);
      console.log(resp.data);
      setBusinessSlots(resp.data);
    } catch (error) {
      enqueueSnackbar(`${error.response.data.msg}`, {
        variant: "error",
      });
    }
  };
  const DialogActions = () => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        {/* <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-green-600 
              font-medium text-white hover:bg-green-700 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={handleSubmit}
        >
          Search
        </button> */}
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
              font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseDialog}
        >
          Close
        </button>
      </div>
    );
  };

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        class="flex gap-2 text-white  w-fit bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-1.5  mr-2 md:text-lg items-center "
      >
        <FaFilter />
        Show Appointments
      </button>
      {open && (
        <Dialog
          title="Select a service and date range to see the available appointments ."
          actionsPannel={DialogActions("bg-red")}
          handleCloseDialog={handleCloseDialog}
          size={"w-full "}
          color={"bg-red"}
        >
          <div style={{ height: "35rem" }} className="flex  flex-wrap ">
            <div className="flex w-full flex-wrap  h-24 items-center pb-5 border-b-2 border-fuchsia-600 ">
              <div className="mx-auto">
                <select
                  id="service"
                  value={selectedService}
                  name="service"
                  required
                  className="required max-w-sm focus:outline-none focus:border-fuchsia-900 wrap-content border-fuchsia-900 border-b-2  border-transparent bg-white text-md focus:border-b-2 p-0"
                  placeholder="service"
                  onChange={handleChange}
                >
                  <option disabled selected value="" hidden>
                    Select a service
                  </option>
                  {serviceList &&
                    serviceList?.map((item) => (
                      <option key={item._id} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="flex flex-wrap justify-center "> */}
              <div className="mx-auto">
                <div className=" text-md font-bold pb-2 pt-4">
                  Select start date{" "}
                </div>
                <div className=" focus:outline-none border-fuchsia-900 w-48 border-b-2 ">
                  <DatePicker
                    onChange={(e) => handleDateChange(e, "start")}
                    selected={startDate}
                    dateFormat="MMMM dd, yyyy"
                    minDate={new Date()}
                    // maxDate={addDays(new Date(), 7)}
                  />
                </div>
              </div>
              <div className="mx-auto">
                <div className=" text-md font-bold pb-2 pt-4">
                  Select end date{" "}
                </div>
                <div className=" focus:outline-none border-fuchsia-900 w-48 border-b-2 ">
                  <DatePicker
                    onChange={(e) => handleDateChange(e, "end")}
                    selected={endDate}
                    dateFormat="MMMM dd, yyyy"
                    minDate={new Date()}
                    // maxDate={addDays(new Date(), 7)}
                  />
                </div>
              </div>
              <button
                type="button"
                className={`mx-auto w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-green-600 
              font-medium text-white hover:bg-green-700 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
                onClick={handleSubmit}
              >
                Search
              </button>
            </div>

            {next && (
              <SlotCards
                service={service}
                business={selectedBusiness}
                slots={selectedSlots}
                setNext={setNext}
              />
            )}
            <div class="flex flex-wrap gap-2 justify-center py-5">
              {!next &&
                businessSlots &&
                businessSlots.length > 0 &&
                businessSlots.map((business) => {
                  return (
                    <div class=" w-full flex flex-col lg:max-w-full lg:flex">
                      <div
                        class="h-48 w-96 bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
                        style={{
                          backgroundImage: `url(${business.business?.images[0]?.url})`,
                        }}
                        title="Mountain"
                      ></div>
                      <div class="border-r w-full border-b border-l border-gray-400 lg:border-l lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 leading-normal">
                        <div class="mb-8">
                          <div class="text-gray-900 font-bold text-xl mb-2">
                            {business?.business?.name}
                          </div>
                          <p class="text-sm text-gray-600 flex items-center mb-1">
                            Service : {selectedService}
                          </p>
                        </div>
                        <div class="flex space-x-4 items-center">
                          <button
                            href="#"
                            class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={(e) =>
                              handleBook(
                                e,
                                business.business,
                                business.service,
                                business.slots
                                // slot
                              )
                            }
                          >
                            View Slots
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* </div> */}
        </Dialog>
      )}
    </>
  );
};

export default SearchBusinessesAcrossCategories;
