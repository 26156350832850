import React, { useState, useEffect } from "react";
import Dialog from "../DialogBox/dialogbox.component";
// import {
//   addBusinessBookedSlot,
//   addSlot,
// } from "../../Backend API's/SlotsAPI/slots";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { useSelector, useDispatch } from "react-redux";
import { getSlots } from "../../Backend API's/SlotsAPI/slots";
import { Link } from "react-router-dom";
import { getUserBookings } from "../../Backend API's/SlotsAPI/appointment";
import { setAllAppointments } from "../../Redux/BookedAppointments/appt.actions";
import { getBusinessByUserID } from "../../Backend API's/BusinessAPI/business_api";
import { useSnackbar } from "notistack";
import BookSlot from "./ActonsAfterSlotSelected";

const ManageSlots = ({ service, businessId, date, setDialogOpen }) => {
  // const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  // const [currentBooking, setCurrentBooking] = useState({});
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const currentUser = useSelector(getCurrentUser);
  const [staff, setStaff] = useState([]);
  // const [selectedStaffId, setSelectedStaffId] = useState("");
  const [next, setNext] = useState(false);
  // const [unregisteredUserEmail, setUnregisteredUserEmail] = useState("");
  // const [unregisteredUserName, setUnregisteredUserName] = useState("");
  const [currentUserBusinessId, setCurrentUserBusinessId] = useState(null);
  // const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();

  const handleSlot = (slot) => {
    console.log("Selected from manage dlot", slot);
    setSelectedSlot(slot);
    setStaff(slot?.availableStaff);
  };

  // const handleViewBooking = () => {
  //   console.log("");
  //   setSuccess("");
  //   setError("");
  //   handleCloseDialog();
  // };

  // const handleSubmit = async () => {
  //   console.log(selectedSlot);
  //   // const res = await getBusinessByUserID(currentUser?.token);
  //   // const userBusinessId = res.data[0]?._id;

  //   if(currentUser?.user?.role === "business") {
  //     const addBusinessBooking = async () => {
  //       const resp = await addBusinessBookedSlot(
  //         selectedSlot.start,
  //         service._id,
  //         businessId,
  //         selectedStaffId,
  //         unregisteredUserEmail,
  //         unregisteredUserName,
  //         currentUser?.token
  //       );
  //       console.log(resp.data);
  //       setCurrentBooking(resp.data);

  //       //getUser Bookings
  //       const res = await getUserBookings(currentUser?.token);
  //       dispatch(setAllAppointments(res.data));
  //       setSuccess("Appointment confirmed!");
  //       setError("");
  //       enqueueSnackbar(`Appointment confirmed!`, {
  //         variant: "info",
  //       });
  //     };
  //     addBusinessBooking().catch((error) => {
  //       setSuccess("");
  //       setError(error.response.data.msg);
  //       console.log(error);
  //     });
  //   } else {
  //     const addData = async () => {
  //       const resp = await addSlot(
  //         selectedSlot.start,
  //         service._id,
  //         businessId,
  //         currentUser?.token,
  //         selectedStaffId
  //       );
  //       console.log(resp.data);
  //       setCurrentBooking(resp.data);

  //       //getUser Bookings
  //       const res = await getUserBookings(currentUser?.token);
  //       dispatch(setAllAppointments(res.data));
  //       //SetRedux AllPpts state
  //       setSuccess("Appointment confirmed!");
  //       enqueueSnackbar(`Appointment confirmed!`, {
  //         variant: "info",
  //       });
  //       setError("");
  //     };
  //     addData().catch((error) => {
  //       setSuccess("");
  //       setError(error.response.data.msg);
  //       console.log(error);
  //       enqueueSnackbar(`${error.response.data.msg} Please try again`, {
  //         variant: "error",
  //       });
  //     });
  //   }
  // };
  const handleCloseDialog = async () => {
    setDialogOpen(false);
    //setSuccess("");
    setError("");
  };
  // const handleChange = (e) => {
  //   setSelectedStaffId(e.target.value);
  // };
  const goToNext = () => {
    setNext(true);
  };
  // const goToPrevious = () => {
  //   setStaff([]);
  //   setSelectedStaffId({});
  //   setNext(false);
  // };

  useEffect(() => {
    // declare the data fetching function
    console.log(date);
    const fetchSlots = async () => {
      //console.log(date.toISOString());
      const resp = await getSlots(date, service?._id, businessId);
      console.log(resp?.data);
      const res = await getBusinessByUserID(currentUser?.token);
      const userBusinessId = res.data[0]?._id;
      setCurrentUserBusinessId(userBusinessId || null);

      if (Array.isArray(resp?.data)) setSlots(resp?.data);
    };

    fetchSlots().catch((error) => {
      console.log(error?.response?.data?.msg);
      setError(error?.response?.data?.msg);
    });

    // const fetchStaff = async () => {
    //   const resp = await getStaff(businessId, currentUser?.token);
    //   console.log(resp?.data);
    //   if (Array.isArray(resp?.data?.staff)) setStaff(resp?.data?.staff);
    // };
    // fetchStaff().catch((error) => {
    //   console.log(error?.response?.data?.msg);
    //   setError(error?.response?.data?.msg);
    // });

    // call the function, make sure to catch any error
    // const CloseOptions = (e) => {
    //   console.log(e);
    //   if (e.path[0].tagName !== "BUTTON") {
    //     setSelectedSlot(selectedSlot);
    //   }
    // };
    // document.body.addEventListener("click", CloseOptions);
    // return () => document.body.removeEventListener("click", CloseOptions);
  }, []);

  const DialogActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-green-600 
              font-medium text-white hover:bg-green-700 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={goToNext}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
              font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseDialog}
        >
          Cancel
        </button>
      </div>
    );
  };

  // if (success?.length > 0)
  //   return (
  //     <Dialog
  //       title={`Your slot is booked. Click View Booking to view and edit the booking`}
  //       handleCloseDialog={handleCloseDialog}
  //       actionsPannel={DialogMessageActions("bg-red")}
  //       size={"w-4/5"}
  //       color={"bg-red"}
  //     ></Dialog>
  //   );
  if (error?.length > 0)
    return (
      <Dialog
        title={`${error}. Please try again`}
        handleCloseDialog={handleCloseDialog}
        actionsPannel={DialogMessageActions("bg-red")}
        size={"w-4/5"}
        color={"bg-red"}
      ></Dialog>
    );
  // if (next)
  //   return (
  //     <Dialog
  //       title="Please select a staff member from the available staff"
  //       handleCloseDialog={handleCloseDialog}
  //       actionsPannel={DialogActions("bg-red")}
  //       size={"w-4/5"}
  //       color={"bg-red"}
  //     >
  //       <select
  //         id="selectedStaff"
  //         value={selectedStaffId}
  //         name="selectedStaff"
  //         required
  //         className="required focus:outline-none focus:border-fuchsia-900 wrap-content border-fuchsia-900 border-b-2 text-sm border-transparent bg-white text-md focus:border-b-2"
  //         placeholder="selectedStaff"
  //         onChange={handleChange}
  //       >
  //         <option disabled selected value="" hidden>
  //           Select Staff
  //         </option>
  //         {staff?.map((item) => (
  //           <option key={item._id} value={item._id}>
  //             {item.firstname} {item.lastname}
  //           </option>
  //         ))}
  //       </select>
  //       {
  //         currentUser?.user?.role === "business" && currentUserBusinessId && currentUserBusinessId == businessId && (
  //           <>
  //             <div style={{marginTop: '1em', display: 'block'}}>
  //               Unregistered user's email: <input placeholder="enter email" type="email" style={{borderBottom: "2px solid black"}} onChange={(e) => setUnregisteredUserEmail(e.target.value)} required />
  //             </div>
  //             <div style={{marginTop: '1em', display: 'block'}}>
  //               Unregistered user's name: <input placeholder="enter name" type="text" style={{borderBottom: "2px solid black"}}  onChange={(e) => setUnregisteredUserName(e.target.value)} required />
  //             </div>
  //           </>
  //         )
  //       }
  //     </Dialog>
  //   );

  const DialogMessageActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-white text-base 
              font-medium text-gray-700 hover:bg-gray-50 focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCloseDialog}
        >
          Close
        </button>
      </div>
    );
  };

  if (!next)
    return (
      <>
        (
        <Dialog
          title={
            service.name
              ? `Below are the available slots for ${service.name}. Please select one slot. 
                (All slot timings displayed below are according to your local time from where you are accessing this webiste.) `
              : "Please select a service and try again. !"
          }
          handleCloseDialog={handleCloseDialog}
          actionsPannel={DialogActions("bg-red")}
          size={"w-4/5"}
          color={"bg-red"}
        >
          <div className="flex flex-row justify-center flex-wrap">
            {slots.map((slot) =>
              slot.cap != 0 ? (
                <button
                  onClick={() => handleSlot(slot)}
                  className={`my-1  cursor-progress bg-fuchsia-300 mx-1 px-2 py-1 text-black font-medium	border-transparent hover:bg-fuchsia-400 focus:outline-none
                                     focus:ring-1 rounded-md focus:bg-fuchsia-700   focus:text-white focus:ring-fuchsia-500`}
                >
                  {new Date(slot.start).toLocaleTimeString("en-US")}
                </button>
              ) : (
                <div
                  className="bg-gray-300 cursor-not-allowed text-gray-700 mx-1 px-2 py-1 my-1 border-transparent hover:bg-gray-400 focus:outline-none
                                      focus:ring-1 rounded-md focus:bg-gray-700 focus:text-gray focus:ring-gray-500"
                >
                  {new Date(slot.start).toLocaleTimeString("en-US")}
                </div>
              )
            )}
          </div>
        </Dialog>
        )
      </>
    );
  else
    return (
      <BookSlot
        service={service}
        businessId={businessId}
        date={date}
        setDialogOpen={setDialogOpen}
        selectedSlot={selectedSlot}
        next={next}
        setNext={setNext}
        staff={staff}
        currentUserBusinessId={currentUserBusinessId}
        setStaff={setStaff}
      />
    );
};

export default ManageSlots;
