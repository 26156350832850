import React from "react";
import "./custom-button.styles.scss";

const CustomButton = ({ children, primary, secondary, ...otherProps }) => {
  return (
    <button
      type="submit"
      {...otherProps}
      className={`my-5 relative py-2 px-4 border border-transparent md:text-xl sm:text-sm font-medium rounded-full text-white hover:border-black hover:text-black focus:outline-none ${
        primary ? primary : "bg-black"
      }
                 ${secondary ? " hover:" + secondary : "hover:bg-white"} `}
    >
      {children}
    </button>
  );
};

export default CustomButton;

export const CyanCustomButton = ({ children }) => {
  return (
    <button class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
      <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
        {children}
      </span>
    </button>
  );
};
