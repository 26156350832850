import { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import CustomButton from "../../../CustomButton/custom-button.component";

const StaffSocialsInput = ({ suffix }) => {
  const [socialMediaSelect, setSocialMediaSelect] = useState("");
  const [otherLinks, setOtherLinks] = useState("");
  const [url, setUrl] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    console.log(socialMediaSelect);
  }, [socialMediaSelect]);

  console.log(suffix);

  const clearInputs = () => {
    setIsDeleted(true);
    setUrl("");
    setOtherLinks("");
    setSocialMediaSelect("");
  };

  //const key = "hahaha"

  return (
    !isDeleted && (
      <div class="flex w-full">
        <div class="flex flex-wrap gap-10">
          {/* <div ></div> */}
          <div
            className="form-control social-media-container"
            style={{ flex: "None !important" }}
          >
            <label htmlFor="social-media">Social Media</label>
            <select
              value={socialMediaSelect}
              id={`social_media_${suffix}`}
              name={`social_media_${suffix}`}
              type="text"
              required
              className=" rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none border-b-black align-items "
              onChange={(event) => {
                setSocialMediaSelect(event.target.value);
              }}
              placeholder="Choose an option"
            >
              <option hidden>Choose an option</option>
              <option value="instagram" key="1">
                Instagram
              </option>
              <option value="facebook" key="2">
                Facebook
              </option>
              <option value="pinterest" key="3">
                Pinterest
              </option>
              <option value="other" key="4">
                Other
              </option>
            </select>
          </div>
          {
            // socialMediaSelect == "other" && (
            <div
              className={`${
                socialMediaSelect === "other"
                  ? "form-control social-media-container"
                  : "form-control social-media-container hidden"
              }`}
            >
              <label htmlFor="platform">Platform/Website Name</label>
              <input
                type="p"
                id={`social_media_other_${suffix}`}
                value={otherLinks}
                onChange={(event) => setOtherLinks(event.target.value)}
                name={`social_media_other_${suffix}`}
              />
            </div>
            // )
          }
          <div className="form-control social-media-container">
            {socialMediaSelect !== "" && (
              <>
                <label htmlFor="url">URL</label>
                <input
                  type="p"
                  id={`social_media_other_url_${suffix}`}
                  value={url}
                  onChange={(event) => setUrl(event.target.value)}
                  name={`social_media_other_url_${suffix}`}
                />
              </>
            )}
          </div>
        </div>

        <div
          class="ml-auto social-media-container"
          style={{ paddingTop: "0.5em" }}
        >
          {
            <CustomButton
              style={{}}
              type="button"
              primary="bg-red-700"
              secondary="bg-red-500"
              onClick={clearInputs}
            >
              <FaTrash size="20" />
            </CustomButton>
          }
        </div>
      </div>
    )
  );
};

export default StaffSocialsInput;
