import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "./../../Redux/user/user.selector";
import AppointmentHistory from "../MenuOptions/Appointment-History.component";
import PaymentMethods from "../MenuOptions/payment-methods.component";
import AccountDetails from "../MenuOptions/Account-Details.component";

const UserProfilePage = () => {
  const currentUser = useSelector(getCurrentUser);
  const [menuOption, setMenuOption] = useState([
    {
      idx: "1",
      name: "Account Details",
    },
    {
      idx: "2",
      name: "Appointment History",
    },
    {
      idx: "3",
      name: "Payment Methods",
    },
  ]);

  const [selectedOption, setSelectedOption] = useState(null);
  if (!currentUser)
    return (
      <div className="flex w-full ml-4 md:text-3xl text-lg font-bold text-left ">
        Please sign in to view this page!
      </div>
    );
  return (
    <div className="flex flex-col mt-4 md:mx-24">
      {/* Profile Header */}
      <div className="flex w-full ml-4 md:text-3xl text-lg font-bold text-left ">
        {"Hello! " +
          currentUser.user.firstname +
          " " +
          currentUser.user.lastname}
      </div>
      <div className="grid ml-4 mt-4 grid-cols-5">
        <div className=" flex md:col-span-1 col-span-2 flex-col ">
          {" "}
          {menuOption.map((option) => (
            <button
              key={option.idx}
              className="flex my-3 text-md justify-center font-bold focus:appearence-none focus:font-[1500] focus:text-amber-700 text-center hover:cursor-pointer hover:text-fuchsia-700"
              onClick={() => setSelectedOption(option)}
            >
              {option.name}
            </button>
          ))}
        </div>
        <div className=" md:col-span-4 pl-5 col-span-3">
          {selectedOption ? (
            selectedOption.idx == "1" ? (
              <AccountDetails />
            ) : selectedOption.idx == "2" ? (
              <AppointmentHistory />
            ) : selectedOption.idx == "3" ? (
              <PaymentMethods />
            ) : (
              <div />
            )
          ) : (
            <AccountDetails />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
