import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllAppointments } from "../../Redux/BookedAppointments/appt.selector";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "./../../assets/calendar-solid.svg";
import { ReactComponent as CheckIcon } from "./../../assets/circle-check-regular.svg";
import GiveReviews from "../GiveReviews/give-reviews.component";
import SuccessToast from "../ToastAlerts/toast-alerts.component";
import ErrorToast from "../ToastAlerts/error-toast.component";
import SendNote from "../Note/send-note.component";

const EditBooking = () => {
  const { bookingId } = useParams();
  const allAppointments = useSelector(getAllAppointments);
  const [booking, setBooking] = useState({});
  const [business, setBusiness] = useState(null);
  const [service, setService] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    ///get Current Booking\

    const fetchData = () => {
      console.log(bookingId);
      const foundBooking = allAppointments.find(
        (booking) => booking._id === bookingId
      );

      if (foundBooking == null) return;

      // console.log(foundBooking);
      setBooking(foundBooking);
      setBusiness(foundBooking.business);
      setService(foundBooking.service);
    };

    fetchData();
  }, []);

  const unmountElement = () => {
    setError(false);
    setSuccess(false);
  };

  return (
    <div className="flex border-1 shadow-2xl bg-purple-50 flex-col md:w-6/12 ml-96 h-fit">
      {booking && business && service ? (
        <>
          <div className="grid grid-cols-3  px-4 py-5 ">
            <Link
              to={`/businesses/${business?._id}`}
              className="rounded-md col-span-1 sm:h-[10rem] w-full"
              style={{
                backgroundImage: `url(${business?.images[0]?.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div className="flex flex-col px-4 w-full col-span-2">
              <Link
                to={`/businesses/${business?._id}`}
                className="md:text-2xl sm:text-sm text-rose-700 hover:underline delay-200 font-bold"
              >
                {business?.name}
              </Link>
              <div className="flex flex-row py-1.5 ">
                <CheckIcon className="h-5 w-5" fill="green" />
                <span className=" px-2 text-sm font-semibold">
                  Appointment Confirmed
                </span>
              </div>
              <span className="py-1 text-sm font-semibold">
                {" "}
                {service?.name}
              </span>
              <div className="flex flex-row py-1 ">
                <CalendarIcon className="h-5 w-5" fill="gray" />
                <span className="mx-2 py-0.5 text-sm font-semibold">
                  {" "}
                  {new Date(booking?.startTime).toLocaleDateString(
                    "en-US",
                    options
                  ) +
                    " at " +
                    new Date(booking?.startTime).toLocaleTimeString("en-US")}
                </span>
              </div>
            </div>
          </div>

          {new Date() > new Date(booking?.startTime) ? (
            <GiveReviews
              serviceId={service?._id}
              setError={setError}
              setSuccess={setSuccess}
              businessId={business?._id}
              bookingId={bookingId}
            />
          ) : (
            <SendNote
              setSuccess={setSuccess}
              setError={setError}
              bookingId={booking?._id}
            />
          )}
        </>
      ) : (
        <div className="md:text-xl sm:text-sm text-rose-700 font-bold">
          {" "}
          Booking not found! This booking is for an unregistered user. Please
          find the booking for this user in your business appointment calendar.
        </div>
      )}
      {success ? (
        <SuccessToast
          id="successElement"
          unmount={unmountElement}
          successMessage={success}
        />
      ) : error ? (
        <ErrorToast
          id="errorElement"
          unmount={unmountElement}
          errorMessage={error}
        />
      ) : (
        <div />
      )}
    </div>
  );
};
export default EditBooking;
