import React, { useEffect } from "react";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { useSelector } from "react-redux";
import { FaUser, FaStar } from "react-icons/fa";
import ReadMore from "../ReadMoreReadLess/readMoreReadLess.component";

const UserReview = ({ review }) => {
  console.log(review);
  const noOfReviews = review.author.noOfReviews ? review.author.noOfReviews : 1;
  const reviewText = review.comment
    ? review.comment
    : "The flat was spotless, very comfortable, and the host was amazing. highly recommend this accommodation for anyone visiting Brasov city centre. It' s quite a while since we are no longer using hote  facilities but self contained places. And the main reason is poor cleanliness and staff not being trained properly. This place exceeded our expectation and will return for sure.";
  const rating = review.rating ? review.rating : 4.5;
  const firstname = review.author.firstname;
  const lastname = review.author.lastname;
  //const currentUser = useSelector(getCurrentUser);
  // useEffect(() => {
  //   //console.log(currentUser);
  // });
  return (
    <>
      <article class=" w-3/4 my-4 md:grid md:grid-cols-3 border-b-2 pb-4 border-grey-400">
        <div>
          <div class="flex  justify-center items-center mb-6 space-x-4">
            <FaUser
              color={"grey"}
              class=" w-10 h-10 rounded-full bg-pink-200  "
              src="/docs/images/people/profile-picture-5.jpg"
              alt=""
            />
          </div>
          <ul class="space-y-4 text-sm text-gray-500 dark:text-gray-400">
            <li class="space-y-1 w-full text-center font-medium dark:text-black">
              <p className="text-lg font-semibold">{`${firstname} ${lastname}`}</p>
            </li>
            <li class="flex justify-center">
              <svg
                class="w-4 h-4 mr-1.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <p className="text-slate-800">{noOfReviews} review </p>
            </li>
          </ul>
        </div>
        <div class="col-span-2 mt-6 md:mt-0">
          <div class="flex items-start mb-5">
            <div className="flex flex-row ">
              {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                  <FaStar
                    className="hover:cursor-pointer"
                    color={ratingValue <= rating ? "#ffc107" : "lightgray"}
                    size={20}
                  />
                );
              })}
            </div>
            <p class="bg-blue-700 mx-2 text-white text-sm font-semibold inline-flex items-center p-1.5 rounded">
              {rating}
            </p>
          </div>
          <p class="mb-2 font-light text-slate-800 ">
            <ReadMore dataLimit={100}>{reviewText}</ReadMore>
          </p>

          <aside class="flex items-center space-x-4 h-fit">
            <a
              href="#"
              class="inline-flex items-center text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
            >
              <svg
                class="w-4 h-4 mr-1.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"></path>
              </svg>
              Helpful
            </a>
            <a
              href="#"
              class="inline-flex items-center text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
            >
              <svg
                class="w-4 h-4 mr-1.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"></path>
              </svg>
              Not helpful
            </a>
          </aside>
        </div>
      </article>
    </>
  );
};
export default UserReview;
