import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { getUserBookings } from "../../Backend API's/SlotsAPI/appointment";
import ViewAppointment from "../ViewAppointments/view-appointments.component";

const ViewBookedSlots = () => {
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getUserBookings(currentUser?.token);
      setAllEvents(resp.data);
      //console.log(resp.data);
      const userEvents = [];
      resp.data.forEach((event) => {
        userEvents.push({
          id: event?._id,
          title: event?.business?.name + " " + event?.service?.name,
          start: event?.startTime,
          end: event?.endTime,
        });
      });
      //console.log(userEvents);
      setEvents(userEvents);
    };
    fetchData().catch((error) => console.error);
  }, []);
  if (allEvents.length > 0 && events.length > 0)
    return (
      <>
        <ViewAppointment allEvents={allEvents} events={events} role="user" />
      </>
    );
  return <div> Loading </div>;
};

export default ViewBookedSlots;
