import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../assets/appointment.svg";
import { ReactComponent as Account } from "../../assets/account.svg";
import { getCurrentUser } from "../../Redux/user/user.selector";
import "./header.styles.scss";
import { removeCurrentUser } from "../../Redux/user/user.actions";
import UserProfilePage from "../UserProfile/user-profile.component";
import ViewBookedSlots from "../ViewUserBookings/viewuserbookings.component";
import { useSnackbar } from "notistack";

const BUSINESS = "business";
const USER = "basic";
const ADMIN = "admin";

const Header = () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false);
  const [accessRole, setAccessRole] = useState(currentUser?.user?.role);
  const { enqueueSnackbar } = useSnackbar();
  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleLogout = () => {
    //logout();
    setShowOptions(!showOptions);
    dispatch(removeCurrentUser());
    enqueueSnackbar(`Sign out successful`, {
      variant: "info",
    });
  };

  useEffect(() => {
    const CloseOptions = (e) => {
      if (e?.path[0] && e?.path[0]?.tagName != "svg") {
        setShowOptions(false);
      }
    };
    document.body.addEventListener("click", CloseOptions);
    return () => document.body.removeEventListener("click", CloseOptions);
  }, []);

  useEffect(() => {
    setAccessRole(currentUser?.user?.role);
    console.log(currentUser?.user?.role);
  }, [currentUser]);

  return (
    <div>
      <div className="header md:space-x-10">
        <nav className="flex flex-row justify-center logo-container pt-2 items-center">
          <Link to="/">
            {/* <Logo className='logo' /> */}
            {accessRole === BUSINESS ? (
              <Link
                to="/business"
                className="font-bold md:text-2xl sm:text-lg text-rose-700"
              >
                {" "}
                Open App (Business)
              </Link>
            ) : (
              <div className="font-bold md:text-2xl sm:text-lg text-rose-700">
                {accessRole === USER ? "Open App (User)" : "Open App"}
              </div>
            )}
          </Link>
        </nav>

        <div className="options">
          {/* <Link to='/' className='option'>
                    <Search className='search-icon' />
                </Link> */}
          {accessRole === ADMIN ? (
            <Link
              to="/admin"
              className="option md:md:text-2xl sm:text-sm sm:text-lg"
            >
              For Admin
            </Link>
          ) : (
            <div />
          )}

          {currentUser != null ? (
            <div
              className="cursor-pointer rounded-full"
              onClick={() => toggleShowOptions()}
            >
              {currentUser?.user?.image ? (
                <img
                  class="w-12 h-12 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                  src={currentUser?.user?.image}
                  alt="Bordered avatar"
                />
              ) : (
                <Account></Account>
              )}
            </div>
          ) : (
            <Link
              to="/signinandsignup"
              className="option md:md:text-2xl sm:text-sm sm:text-lg"
            >
              Sign In
            </Link>
          )}
          {showOptions && (
            <div className="flex flex-col z-50 origin-top-right right-1/5 top-24 ring-black ring-opacity-5 focus:outline-none md:w-1/5 sm:w-2/5 bg-white rounded-lg mt-1/5 py-1 shadow-xl  absolute">
              <div className="px-3 py-2 text-black font-bold bg-white md:text-lg text-sm">
                {" "}
                {`Hello, ${currentUser?.user?.firstname}  ${currentUser?.user?.lastname}!`}
              </div>
              {accessRole === BUSINESS ? (
                <Link
                  to="/"
                  onClick={() => setAccessRole(USER)}
                  className=" px-3  delay-200 text-black md:text-md sm:text-lg bg-slate-200 hover:bg-black hover:text-white py-3"
                >
                  Switch to User
                </Link>
              ) : (
                <>
                  <Link
                    className=" px-3 delay-200  md:text-md text-black sm:text-lg bg-white hover:bg-black hover:text-white py-3"
                    to="/viewbookings"
                    onClick={toggleShowOptions}
                  >
                    My Appointments
                  </Link>
                  <Link
                    to="/business"
                    onClick={() => setAccessRole(BUSINESS)}
                    className=" px-3  delay-200 text-black md:text-md sm:text-lg bg-white hover:bg-black hover:text-white py-3"
                  >
                    Switch to Business
                  </Link>
                  <Link
                    className=" px-3  delay-200 text-black md:text-md sm:text-lg bg-white hover:bg-black hover:text-white py-3"
                    to="/profile"
                    onClick={toggleShowOptions}
                  >
                    My Profile
                  </Link>
                </>
              )}

              <button
                className=" text-black delay-200 text-left px-3 bg-white hover:bg-black hover:text-white sm:text-lg py-3"
                onClick={handleLogout}
              >
                Sign out
              </button>
            </div>
          )}
        </div>
      </div>

      <Switch>
        <Route path="/viewbookings">
          <ViewBookedSlots />
        </Route>
        <Route path="/profile">
          <UserProfilePage />
        </Route>
      </Switch>
    </div>
  );
};

export default Header;
