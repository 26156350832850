import React from "react";

const UpdateService = ({
  saveServiceUpdate,
  userUpdate,
  onHandleChange,
  service,
  cancelUpdates,
}) => {
  return (
    <div class=" w-full lg:max-w-full lg:flex">
      <div
        class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
        style={{ backgroundImage: `url(${service?.images[0]?.url})` }}
        title="Mountain"
      ></div>
      <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r lg:w-full p-4 flex flex-col justify-between leading-normal">
        <div class="mb-8">
          <div class="text-sm space-y-1">
            <p> Name :</p>
            <input
              class="text-gray-900 appearance-none rounded-none relative block  border rounded-b border-gray-300  font-bold text-xl mb-2 w-full border border-1"
              value={userUpdate.name}
              name="name"
              onChange={onHandleChange}
            />
          </div>
          <div class="text-sm space-y-1">
            <p> Address :</p>
            <input
              class="text-sm appearance-none rounded-none relative block  border rounded-b border-gray-300 text-gray-600 flex items-center mb-1 w-full"
              value={userUpdate.address}
              name="address"
              onChange={onHandleChange}
            />
          </div>
          <div class="text-sm space-y-1">
            <div class="flex space-x-4 items-center">
              <p> Duration :</p>
              <input
                class="text-gray-900 appearance-none rounded-none relative block border rounded-b border-gray-300 leading-none"
                value={userUpdate.duration}
                name="duration"
                onChange={onHandleChange}
              />
            </div>
            <div class="flex space-x-4 items-center">
              <p> Price: </p>
              <input
                class="text-gray-600 appearance-none rounded-none relative block border rounded-b border-gray-300 "
                value={userUpdate.price}
                name="price"
                onChange={onHandleChange}
              />
            </div>
          </div>

          <div class="flex space-x-4 items-center">
            <p> Description: </p>

            <input
              class="break-words h-auto appearance-none rounded-none relative block text-gray-700 text-base w-full  border rounded-b border-gray-300 "
              value={userUpdate.description}
              name="description"
              onChange={onHandleChange}
            />
          </div>
        </div>
        <div>
          <button
            onClick={saveServiceUpdate}
            type="button"
            class=" text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 "
          >
            Update
          </button>
          <button
            onClick={cancelUpdates}
            type="button"
            class=" text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 "
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateService;
