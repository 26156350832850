import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./add-slots.scss";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import CustomButton from "../../CustomButton/custom-button.component";
import {
  addBusinessHours,
  getBusinessHours,
} from "../../../Backend API's/SlotsAPI/slots";
import DatePicker from "react-datepicker";
import { getBusinessByUserID } from "../../../Backend API's/BusinessAPI/business_api";
import { useSnackbar } from "notistack";

const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const AddSlots = ({ currentUser }) => {
  //get All Services and Add slots
  const [date, setDate] = useState(new Date());
  const [capacity, setCapacity] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [businessId, setBusinessId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  const [monHours, setMonHours] = useState(["10:00", "19:00"]);
  const [tueHours, setTueHours] = useState(["10:00", "19:00"]);
  const [wedHours, setWedHours] = useState(["10:00", "19:00"]);
  const [thursHours, setThursHours] = useState(["10:00", "19:00"]);
  const [friHours, setFriHours] = useState(["10:00", "19:00"]);
  const [satHours, setSatHours] = useState(["10:00", "19:00"]);
  const [sunHours, setSunHours] = useState(["10:00", "19:00"]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleDateChange = (date) => {
    const currentDayOfMonth = date.getDate();
    const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
    const currentYear = date.getFullYear();

    const dateString = new Date(currentYear, currentMonth, currentDayOfMonth);
    setDate(dateString);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getBusinessByUserID(currentUser?.token);
      const bId = res.data[0]?._id;
      setBusinessId(bId);
      const resp = await getBusinessHours(bId);
      const businessHours = resp?.data?.workingHours;
      if (businessHours) patchValues(businessHours);
      setIsLoading(false);
    };

    // call the function, make sure to catch any error
    fetchData().catch(console.error);
  }, []);

  const patchValues = (businessHoursFromApi) => {
    const data = {};
    for (let i = 0; i < businessHoursFromApi.length; i++) {
      const item = businessHoursFromApi[i];

      data[days[i]] = {
        isOpen: item ? item.isOpen : false,
        hours: item
          ? [
              item.startHour + ":" + placeZeroIfNeeded(item.startMinute),
              item.endHour + ":" + placeZeroIfNeeded(item.endMinute),
            ]
          : ["10:00", "19:00"],
      };

      if (days[i] == "monday") {
        setMonHours(data[days[i]].hours);
        setMonday(data[days[i]].isOpen);
      }
      if (days[i] == "tuesday") {
        setTueHours(data[days[i]].hours);
        setTuesday(data[days[i]].isOpen);
      }
      if (days[i] == "wednesday") {
        setWedHours(data[days[i]].hours);
        setWednesday(data[days[i]].isOpen);
      }
      if (days[i] == "thursday") {
        setThursHours(data[days[i]].hours);
        setThursday(data[days[i]].isOpen);
      }
      if (days[i] == "friday") {
        setFriHours(data[days[i]].hours);
        setFriday(data[days[i]].isOpen);
      }
      if (days[i] == "saturday") {
        setSatHours(data[days[i]].hours);
        setSaturday(data[days[i]].isOpen);
      }
      if (days[i] == "sunday") {
        setSunHours(data[days[i]].hours);
        setSunday(data[days[i]].isOpen);
      }
    }
  };

  const placeZeroIfNeeded = (minutes) => {
    return minutes >= 10 ? minutes : "0" + minutes;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "capacity") {
      setCapacity(value);
      return;
    }

    if (name === "monday") {
      setMonday(!monday);
    } else if (name === "tuesday") {
      setTuesday(!tuesday);
    } else if (name === "wednesday") {
      setWednesday(!wednesday);
    } else if (name === "thursday") {
      setThursday(!thursday);
    } else if (name === "friday") {
      setFriday(!friday);
    } else if (name === "saturday") {
      setSaturday(!saturday);
    } else if (name === "sunday") {
      setSunday(!sunday);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      capacity: capacity,
      timezone: timezone,
      startDate: date.toISOString(),
      operationalHours: {
        sunday: {
          isOpen: sunday,
          hours: sunHours,
        },
        monday: {
          isOpen: monday,
          hours: monHours,
        },
        tuesday: {
          isOpen: tuesday,
          hours: tueHours,
        },
        wednesday: {
          isOpen: wednesday,
          hours: wedHours,
        },
        thursday: {
          isOpen: thursday,
          hours: thursHours,
        },
        friday: {
          isOpen: friday,
          hours: friHours,
        },
        saturday: {
          isOpen: saturday,
          hours: satHours,
        },
      },
    };
    try {
      const res = await addBusinessHours(currentUser?.token, businessId, data);
      setSuccess("Slots Added");
      enqueueSnackbar("Slots Added", {
        variant: "info",
      });
      setError("");
    } catch (error) {
      setSuccess("");
      setError(error.response.data.msg);
      enqueueSnackbar(`${error.response.data.msg} Please try again`, {
        variant: "error",
      });
    }
  };

  if (isLoading) return <> Loading</>;
  return (
    <form
      className="flex flex-col md:pl-10 pl-5 py-5 items-center justify-center"
      action="#"
      onSubmit={handleSubmit}
      method="POST"
    >
      <p className="flex justify-center align-items  md:text-2xl sm:text-sm font-bold">
        Let us know when are you open through the week.
      </p>
      <p className=" flex md:text-2xl sm:text-sm font-bold mb-5 justify-center align-items ">
        Add your availability below. We will handle the rest.
      </p>

      <div className="flex flex-row  py-3.5 w-full">
        <div className="flex w-1/2 md:pl-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="monday"
            name="monday"
            checked={monday}
            value={monday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Monday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="monday_Hours"
            name="monday_Hours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!monday}
            onChange={setMonHours}
            value={monHours}
          />
        </div>
      </div>

      <div className="flex flex-row py-3.5 w-full">
        <div className="flex w-1/2 md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="tuesday"
            name="tuesday"
            checked={tuesday}
            value={tuesday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Tuesday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="tueHours"
            name="tueHours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!tuesday}
            onChange={setTueHours}
            value={tueHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 w-full">
        <div className="flex md:w-1/2 md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="wednesday"
            name="wednesday"
            value={wednesday}
            checked={wednesday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Wednesday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="wednesday"
            name="wednesday"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!wednesday}
            onChange={setWedHours}
            value={wedHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 w-full">
        <div className="flex w-1/2 md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="thursday"
            name="thursday"
            value={thursday}
            checked={thursday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Thursday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="thursHours"
            name="thursHours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!thursday}
            onChange={setThursHours}
            value={thursHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 w-full">
        <div className="flex w-1/2  md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="friday"
            name="friday"
            value={friday}
            checked={friday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Friday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="friHours"
            name="friHours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!friday}
            onChange={setFriHours}
            value={friHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 w-full">
        <div className="flex w-1/2 md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="saturday"
            name="saturday"
            value={saturday}
            checked={saturday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Saturday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="satHours"
            name="satHours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!saturday}
            onChange={setSatHours}
            value={satHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 w-full">
        <div className="flex w-1/2 md:px-5 align-items">
          <input
            className="appearance-none pt-1 h-7 w-7 rounded-lg border-2 checked:bg-green-700 rounded-md border-plastic-pink"
            type="checkbox"
            id="sunday"
            name="sunday"
            value={sunday}
            checked={sunday}
            onChange={handleChange}
          />
          <p className="flex px-2 justify-center items-center md:text-2xl sm:text-sm">
            Sunday{" "}
          </p>
        </div>

        <div className="w-1/2">
          <TimeRangePicker
            clockIcon={null}
            className="h-15 md:text-2xl sm:text-sm w-full rounded-2xl border-rose-500"
            id="sunHours"
            name="sunHours"
            amPmAriaLabel="Select AM/PM"
            clearAriaLabel="Clear value"
            clockAriaLabel="Toggle clock"
            disableClock
            rangeDivider=" to "
            minutePlaceholder="mm"
            maxTime="23:59"
            hourPlaceholder="hh"
            disabled={!sunday}
            onChange={setSunHours}
            value={sunHours}
          />
        </div>
      </div>
      <div className="flex flex-row py-3.5 mt-5 w-full">
        <div className=" md:text-2xl sm:text-sm w-1/2 align-items justify-center font-bold">
          Select Business Start Date{" "}
        </div>
        <div className=" required flex flex-col md:text-xl sm:text-sm align-items font-bold  border-b-2 justify-center focus:appearance-none px-2 focus:border-b-rose-700 text-black border-b-black w-1/4">
          <DatePicker
            onChange={handleDateChange}
            selected={date}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            // maxDate={addDays(new Date(), 7)}
          />
        </div>
      </div>

      <div className="flex flex-row  py-3.5 w-full">
        <div className=" md:text-2xl sm:text-sm w-1/2 align-items justify-center font-bold">
          How many employees do you have to provide the services ?{" "}
        </div>
        <select
          value={capacity}
          id="capacity"
          name="capacity"
          type="text"
          required
          className="appearance-none md:w-1/4 required justify-center rounded-none md:text-xl sm:text-sm relative border-b-2 px-3 py-2 rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none border-b-black align-items "
          onChange={handleChange}
          placeholder="Capacity"
        >
          <option hidden>Capacity</option>

          <option value="1" key="1">
            1
          </option>
          <option value="2" key="2">
            2
          </option>
          <option value="3" key="3">
            3
          </option>
        </select>
      </div>

      {error ? (
        <p className=" mt-2 text-center md:text-xl sm:text-sm text-red-600">
          <span className="font-medium text-red-600 "> {error}</span>
        </p>
      ) : success ? (
        <p className=" py-1 px-1 bg-green-700 h-12 w-96 rounded-md justify-center  text-center md:text-xl sm:text-sm text-white">
          <span className="py-2 font-medium "> {success}</span>
        </p>
      ) : (
        <div />
      )}

      <div className="flex flex-col w-full items-center ">
        <CustomButton
          type="submit"
          primary="bg-lime-700"
          secondary="bg-lime-500"
        >
          {" "}
          Save{" "}
        </CustomButton>
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(AddSlots);
