import React, { useState, useEffect } from "react";
import { useRouteMatch, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
// import DatePicker from 'react-datepicker';
// import CustomButton from '../../Components/CustomButton/custom-button.component';
import OverviewTab from "../../Components/OverviewTab/overview-tab.component";
import ContactTab from "../../Components/ContactTab/contact-tab.component";
import ServicesTab from "../../Components/ServicesTab/services-tab.component";
import ReviewsTab from "../../Components/ReviewsTab/reviews-tab.component";
import StaffInfoTab from "../../Components/StaffInfoTab/staff-info.component";
import { getBusinessesDetailsByBusinessId } from "../../Backend API's/BusinessAPI/business_api";
import MakeAppointment from "../../Components/MakeAppointment/makeappointment.component";
import { setSelectedBusiness } from "../../Redux/Businesses/businesses.action";
import ViewOpenAppointments from "../../Components/ViewOpenAppointments/view-open-appointments.component";

const BusinessDetails = ({
  currentUser,
  setSelectedBusiness,
  businessDetails,
}) => {
  const { businessId } = useParams();
  const { url, path } = useRouteMatch();
  //console.log(' Url ', url);
  //console.log(' path ', path);
  //const [businessDetails, setBusinessDetails] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  // const [service, setService] = useState('');
  // const [datePickerShown, setDatePickerShown] = useState(false);
  // const [date, setDate] = useState(new Date());
  // const [time, setTime] = useState('');
  const [toggleTab, setToggleTab] = useState(1);

  const toggle = (idx) => {
    setToggleTab(idx);
    // console.log(idx);
  };

  const tabs = [
    {
      name: "Overview",
      idx: 1,
    },
    {
      name: "Services",
      idx: 2,
    },
    {
      name: "Contact",
      idx: 3,
    },
    {
      name: "Reviews",
      idx: 4,
    },
    {
      name: "Staff Info",
      idx: 5,
    },
  ];

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const res = await getBusinessesDetailsByBusinessId(
        businessId,
        currentUser?.token
      );
      console.log(res.data);
      setSelectedBusiness(res.data);
      setImageUrl(res.data.images[0]?.url);
      // const resp = getServicesByBusinessId(res.data?._id);
    };

    // call the function, make sure to catch any error
    fetchData().catch(console.error);
  }, []);

  // const { category, name, description, license, phone, country, city, rating, reviews, state, user, _id } = businessDetails;
  return (
    <div>
      {
        // Object.keys(businessDetails).length !== 0 ?

        businessDetails ? (
          <div>
            <div
              className="  h-96 flex my-2.5 flex-col items-center justify-center "
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                // width: "900px",
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                overflow: "hidden",
                justifyContent: "center",
              }}
            ></div>

            <div className="md:flex md:w-full md:flex-row">
              <div className="md:flex flex-col md:w-1/4 md:h-100">
                <MakeAppointment businessId={businessId} />
                <div className="flex flex-col items-center shadow-xl border-2 justify-center px-4 py-9 w-full h-24 mt-2">
                  <Link
                    to={`/openappointments`}
                    className="w-5/7 text-center text-black shadow-xl rounded-full text-md px-3 bg-blue-300 hover:bg-blue-400 hover:text-white py-2"
                  >
                    View Open Appointments
                  </Link>
                </div>
              </div>
              <div className="md:w-3/4 grid sm:grid-cols-1  md:grid-cols-3 py-3.5 h-full overflow-auto">
                <div className="md:col-span-3 sm:col-span-1">
                  <div
                    className={`grid md:grid-cols-${tabs.length}  sm:grid-cols-1 px-4 pb-3 gap-2  `}
                  >
                    {tabs.map((tab) => (
                      <button
                        autoFocus
                        className={`col-span-1 flex justify-center py-2 md:text-xl sm:text-sm border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 ${
                          toggleTab === tab.idx
                            ? "bg-fuchsia-50 outline-none border-fuchsia-900 "
                            : ""
                        }`}
                        onClick={() => toggle(tab.idx)}
                      >
                        {tab.name}
                      </button>
                    ))}
                    {/* <button
                      autoFocus
                      className=" col-span-1 flex justify-center py-2 md:text-xl sm:text-sm border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 focus:bg-fuchsia-50 focus:outline-none focus:border-fuchsia-900 "
                      onClick={() => toggle(1)}
                    >
                      {" "}
                      Overview{" "}
                    </button>
                    <button
                      className=" col-span-1 flex justify-center py-2 md:text-xl sm:text-sm  border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 focus:bg-fuchsia-50 focus:outline-none focus:border-fuchsia-900"
                      onClick={() => toggle(2)}
                    >
                      {" "}
                      Services
                    </button>
                    <button
                      className=" col-span-1 flex justify-center py-2 md:text-xl sm:text-sm  border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 focus:bg-fuchsia-50 focus:outline-none focus:border-fuchsia-900"
                      onClick={() => toggle(3)}
                    >
                      {" "}
                      Contact
                    </button>
                    <button
                      className=" col-span-1 flex justify-center py-2 md:text-xl sm:text-sm  border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 focus:bg-fuchsia-50 focus:outline-none focus:border-fuchsia-900"
                      onClick={() => toggle(4)}
                    >
                      {" "}
                      Reviews
                    </button>
                    <button
                      className=" col-span-1 flex justify-center py-2 md:text-xl sm:text-sm  border-b-2 text-gray-700 font-bold hover:text-fuchsia-900 hover:border-fuchsia-900 focus:bg-fuchsia-50 focus:outline-none focus:border-fuchsia-900"
                      onClick={() => toggle(5)}
                    >
                      Staff Info
                    </button> */}
                  </div>

                  <div className="col-span-3 flex flex-col sm:col-span-1  px-2">
                    {toggleTab === 1 ? (
                      <OverviewTab
                        user={businessDetails?.user}
                        id={businessDetails?._id}
                        name={businessDetails?.name}
                        category={businessDetails?.category}
                        description={businessDetails?.description}
                        license={businessDetails?.license}
                      />
                    ) : toggleTab === 2 ? (
                      <ServicesTab businessId={businessDetails?._id} />
                    ) : toggleTab === 3 ? (
                      <ContactTab
                        businessId={businessId}
                        phone={businessDetails?.phone}
                        state={businessDetails?.state}
                        country={businessDetails?.country}
                        city={businessDetails?.city}
                      />
                    ) : toggleTab === 4 ? (
                      <ReviewsTab />
                    ) : toggleTab === 5 ? (
                      <StaffInfoTab />
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div> {console.log(businessDetails)} </div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  businessDetails: state.businessData.selectedBusiness,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedBusiness: (business) => dispatch(setSelectedBusiness(business)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);
