import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getBusinessByUserID,
  getBusinessesDetailsByBusinessId,
} from "../../Backend API's/BusinessAPI/business_api";
import { FaInstagram, FaFacebook, FaSnapchat } from "react-icons/fa";
import { getStaffByBusiness } from "../../Backend API's/StaffAPI/staff.api";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { getSelectedBusiness } from "../../Redux/Businesses/businesses.selector";

const StaffInfoTab = () => {
  const [allStaff, setAllStaff] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector(getCurrentUser);
  const selectedBusiness = useSelector(getSelectedBusiness);

  useEffect(() => {
    getAllStaff();
  }, []);

  const getAllStaff = async () => {
    setIsLoading(true);
    try {
      //console.log(currentUser);
      //const res = await getBusinessByUserID(currentUser?.token);

      const businessId = selectedBusiness._id;
      console.log(selectedBusiness);

      const resAllStaff = await getStaffByBusiness(
        businessId,
        currentUser?.token
      );
      setAllStaff([...resAllStaff?.data?.staff]);
      //console.log(resAllStaff?.data?.staff);
      const out = [];
      for (let item of resAllStaff?.data?.staff) {
        console.log(item);
        if (item?.socialmedia) {
          const links = Object.entries(item?.socialmedia);
          console.log(links);
          const linksArray = links.map((entry) => {
            return { name: entry[0], link: entry[1] };
          });
          //console.log(linksArray);
          item.socialmedia = linksArray;
        }
        out.push(item);
      }

      console.log(out);
      setAllStaff(out);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  if (isLoading) return <div>Loading</div>;
  if (allStaff.length === 0)
    return (
      <p className="md:col-span-2 px-4 sm:text-xl text-black md:text-xl font-bold">
        Sorry! Staff Information is not available for this business.
      </p>
    );
  return (
    <div className="grid md:grid-cols-2 gap-2 sm:grid-cols-1 h-full">
      <p className="md:col-span-2 px-4  sm:text-xl text-purple-600 md:text-2xl font-bold">
        Connect with our staff members below!
      </p>
      {allStaff.map((details) => (
        <div className="p-2 m-2 md:col-span-1" key={details.id}>
          <span className="flex justify-between flex-row">
            <div>
              <p className="text-black font-bold">
                {details.firstname} {details.lastname}
              </p>
              <p>
                <a className="text-sky-600" href={`mailto:${details.email}`}>
                  {details.email}
                </a>
              </p>

              {/* <p>
                <a className="text-fuchsia-700" href={`tel:${details.phone}`}>
                  {details.phone}
                </a>
              </p> */}
            </div>
            <div>
              {details?.socialmedia &&
                details.socialmedia.map((link) => (
                  <p key={link.name} class="flex flex-row space-x-1">
                    <span className="font-bold">{link.name}</span>
                    <a className="text-fuchsia-700" href={`${link?.link}`}>
                      {link.name === "instagram" ? (
                        <FaInstagram size={25} beatFade />
                      ) : link.name === "facebook" ? (
                        <FaFacebook size={25} color="Dodgerblue" beatFade />
                      ) : link.name === "snapchat" ? (
                        <FaSnapchat size={25} color="#243c64" beatFade />
                      ) : (
                        <div> Click me</div>
                      )}
                    </a>
                  </p>
                ))}
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default StaffInfoTab;
