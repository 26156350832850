import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getStaffByBusiness = (businessId,token) => {
    return axios({
        method: "GET",
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/staff`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};

export const addStaff = (formData, token, businessId) => {
    return axios({
        method: "POST",
        data: formData,
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/staff`,
    }).catch((error) => {
        //console.log("In axios", error.response);
        throw error;
    });
};

export const updateStaffById = (data, token, businessId) => {
    return axios({
        method: "PATCH",
        data: data,
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/staff/${data._id}`,
    }).catch((error) => {
        //console.log("In axios", error.response);
        throw error;
    });
};

export const deleteStaffById = (data, token, businessId) => {
    return axios({
        method: "DELETE",
        data: data,
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/staff/${data._id}`,
    }).catch((error) => {
        //console.log("In axios", error.response);
        throw error;
    });
};