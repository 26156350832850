import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../CustomButton/custom-button.component";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { getCities } from "../../Backend API's/Get-States-City/getCities";
import { getStatesInUSA } from "../../Backend API's/Get-States-City/getAllStates";
import { updateUserInfo } from "../../Backend API's/Authentication/authentication.api";
import { useSnackbar } from "notistack";
import { FaUser } from "react-icons/fa";
import { setCurrentUser } from "../../Redux/user/user.actions";
import { formControlLabelClasses } from "@mui/material";

const AccountDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [country, setCountry] = useState("US");
  const [userState, setUserState] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [preview, setPreview] = useState();
  const currentUser = useSelector(getCurrentUser);
  const [flag, setFlag] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", userCity);
    formData.append("state", userState);
    formData.append("country", country);
    console.log(image);
    if (image) {
      formData.append("image", image);
    }

    try {
      const res = await updateUserInfo(
        formData,
        currentUser?.token,
        currentUser?.user?.id
      );
      console.log(res);
      const newUser = {
        token: currentUser?.token,
        user: res.data,
      };

      dispatch(setCurrentUser(newUser));
      enqueueSnackbar("Profile is updated ", { variant: "info" });
    } catch (error) {
      enqueueSnackbar(error.response.data.msg, { variant: "error" });
    }
  };
  useEffect(() => {
    currentUser?.user?.city && !flag && setUserCity(currentUser?.user?.city);
    console.log(currentUser?.user?.city, flag);
  }, [cities, flag]);

  useEffect(() => {
    const res = getStatesInUSA();
    console.log(res);
    setStates(res);
    setFirstName(currentUser?.user?.firstname);
    setLastName(currentUser?.user?.lastname);
    setEmail(currentUser?.user?.email);
    currentUser?.user?.phone && setPhone(currentUser?.user?.phone);
    currentUser?.user?.address && setAddress(currentUser?.user?.address);
    currentUser?.user?.state && setUserState(currentUser?.user?.state);
    const currentCities = getCities(currentUser?.user?.state);
    setCities(currentCities);
    currentUser?.user?.image && setPreview(currentUser?.user?.image);
  }, []);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "firstname") {
      setFirstName(value);
    } else if (name === "lastname") {
      setLastName(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "country") {
      setCountry(value);
    } else if (name === "userstate") {
      setUserState(value);
      const currentCities = getCities(value);
      // let modifiedCities = [];
      // currentCities.forEach((item, index) => {
      //     modifiedCities.push({ ...item, id: index })
      // });
      console.log(currentCities);
      setCities([]);
      //console.log(modifiedCities);
      setCities(currentCities);
      setFlag(true);
    } else if (name === "usercity") {
      setUserCity(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "image") {
      setImage(files[0]);
      //convertfileToUrl(files[0]);
      const objectUrl = URL.createObjectURL(files[0]);
      setPreview(objectUrl);
    }
  };
  return (
    <>
      <div className="text-center text-2xl w-full ">
        {" "}
        Edit your profile details here!
      </div>
      <form
        className="md:mt-5 space-y-6 flex w-full"
        action="#"
        onSubmit={handleSubmit}
        method="POST"
        encType="multipart/form-data"
      >
        <div className="flex flex-col justify-center md:w-1/2">
          <div className="">
            <div>
              {error ? (
                <p className="mt-2 bg-red-300 text-center h-12 justify-center  rounded-md md:text-xl sm:text-sm text-black">
                  <span className="py-2 font-medium "> {error}</span>
                </p>
              ) : success ? (
                <p className=" mt-1 bg-green-400 h-12 rounded-md justify-center  text-center md:text-xl sm:text-sm text-black">
                  <span className="py-2 font-medium "> {success}</span>
                </p>
              ) : (
                <div />
              )}
            </div>

            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm md:space-y-5">
              <div>
                <label htmlFor="name" className="sr-only">
                  First Name
                </label>
                <input
                  value={firstName}
                  id="firstname"
                  name="firstname"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div>
                <label htmlFor="description" className="sr-only">
                  Last Name
                </label>
                <input
                  value={lastName}
                  id="lastname"
                  name="lastname"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
              <div>
                <label htmlFor="description" className="sr-only">
                  Email
                </label>
                <input
                  value={email}
                  id="email"
                  name="email"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone Number
                </label>
                <input
                  value={phone}
                  id="phone"
                  name="phone"
                  type="text"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
              </div>
              <div>
                <label htmlFor="address" className="sr-only">
                  Street Address
                </label>
                <input
                  value={address}
                  id="address"
                  name="address"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Street Address"
                />
              </div>
              <div>
                <label htmlFor="state" className="sr-only">
                  State
                </label>
                <select
                  value={userState}
                  id="userState"
                  name="userstate"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="State"
                >
                  <option hidden>State</option>

                  {states.map(({ name, isoCode }) => (
                    <option value={name} key={isoCode}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="city" className="sr-only">
                  City
                </label>
                <select
                  value={userCity}
                  id="userCity"
                  name="usercity"
                  type="text"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="City"
                >
                  <option hidden>City</option>

                  {cities.map(({ name, id }) => (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                    </div>
                </div> */}
          </div>
        </div>
        <div
          className="flex flex-col  items-center md:w-1/2"
          style={{ marginLeft: "2rem" }}
        >
          {preview ? (
            <div
              className="container h-96"
              style={{
                backgroundImage: `url(${preview})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <FaUser size={"80%"} color={"gray"} />
          )}
          <div>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleChange}
              name="image"
              id=""
              className="appearance-none rounded-none my-2 relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900 font-bold  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>
        </div>
      </form>
      <div className="flex flex-col w-full items-center ">
        <CustomButton type="submit" onClick={handleSubmit}>
          {" "}
          Update Profile
        </CustomButton>
      </div>
    </>
  );
};
export default AccountDetails;
