import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getServicesByBusinessId } from "../../Backend API's/BusinessAPI/services_api";
import { connect } from "react-redux";
import ReadMore from "../ReadMoreReadLess/readMoreReadLess.component";
import {
  getBusinessId,
  getServices,
} from "../../Redux/Businesses/businesses.selector";

const ServicesTab = ({ businessId, currentUser }) => {
  const servicesFromRedux = useSelector(getServices);
  const id = useSelector(getBusinessId);
  const [services, setServices] = useState(servicesFromRedux);
  return (
    <div>
      {
        // services.map(item => (

        //     < div  className={`category-item `
        //     }>

        //         <div className="background-image"
        //             style={{
        //                 backgroundImage: `url(${item.images[0]?.url})`
        //             }} />
        //         <div className="content">
        //             <h1 className="title"> {item.name}</h1>
        //         </div>
        //     </div>
        // ))
        services.map(
          ({
            business,
            duration,
            city,
            description,
            images,
            location,
            name,
            phone,
            price,
            state,
          }) => (
            <div className="md:grid md:grid-cols-4 sm:grid-cols-1 md: max-h-max w-full px-2 py-2 pb-4 pt-4 border-b-2">
              <div
                className="rounded-md col-span-1 sm:h-24 sm:w-24"
                style={{
                  height: "auto",
                  width: "auto",
                  backgroundImage: `url(${images[0]?.url})`,
                  backgroundSize: "cover",
                }}
              />
              <div className="flex flex-col pl-2 w-full sm:col-span-1 md:col-span-3">
                <div className="md:text-xl sm:text-sm font-semibold">
                  {name}
                </div>
                <div className="text-sm"> {location.formattedAddress}</div>
                <div className=" pt-1 justify-between ">
                  <b>Price:</b> {price == 0 ? "Free of cost" : price + "$"}
                </div>
                {duration ? (
                  <div className="  pt-1 justify-between ">
                    <b>Duration:</b> {duration + " min"}
                  </div>
                ) : (
                  <div />
                )}

                <div className="  pt-1 justify-between ">
                  <b>Phone:</b> {phone}
                </div>
                <div className="flex text-sm pt-1">
                  <ReadMore>{description}</ReadMore>
                </div>
              </div>
            </div>
          )
        )
      }
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(ServicesTab);
