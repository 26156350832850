import { SET_CURRENT_APPOINTMENT, SET_ALL_APPOINTMENTS,REMOVE_CURRENT_APPOINTMENT } from "./appt.actiontypes"

export const setAllAppointments = appointments => ({
    type: SET_ALL_APPOINTMENTS,
    payload: appointments
})

export const setCurrentAppointment = appointment => ({
    type: SET_CURRENT_APPOINTMENT,
    payload: appointment
})

export const removeCurrentAppointment = () => ({
    type: REMOVE_CURRENT_APPOINTMENT,
})