import React from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBusinesses } from "../../Redux/Businesses/businesses.selector";
import { setSelectedMarker } from "../../Redux/MapView/mapview.actions";

const BusinessesMapView = ({ businesses }) => {
  const dispatch = useDispatch();
  const searchedBusinesses = useSelector(getAllBusinesses);
  const handleMarker = (e, searchId) => {
    const found = searchedBusinesses.find(
      (business) => business._id === searchId
    );

    console.log(found);
    dispatch(setSelectedMarker(found));
  };
  return businesses.map(
    ({ _id, name, images, rating, category, description, location }) => (
      <div
        key={_id}
        className="grid md:grid-cols-5 max-h-max w-full px-2 py-2 pb-4 pt-4 border-b-2 "
        onMouseEnter={(e) => handleMarker(e, _id)}
      >
        <Link
          to={`/businesses/${_id}`}
          className="rounded-md col-span-2"
          style={{
            backgroundImage: `url(${images[0]?.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
        <div className="flex flex-col px-4 w-full col-span-3">
          <Link to={`/businesses/${_id}`} className="md:text-xl sm:text-sm font-semibold">
            {name}
          </Link>
          <div className="text-sm"> {location?.formattedAddress}</div>
          <div className="flex flex-row ">
            {[...Array(5)].map((star, i) => {
              const ratingValue = i + 1;
              return (
                <FaStar
                  className="hover:cursor-pointer"
                  color={ratingValue <= rating ? "#ffc107" : "lightgray"}
                  size={20}
                />
              );
            })}
            {rating > 4.5 ? (
              <div className="text-sm px-2"> Exceptional</div>
            ) : (
              <div className="text-sm px-2"> Awesome</div>
            )}
          </div>
          <div className="text-sm"> {category.title}</div>
          {/* <div className='grid grid-cols-5 h-10 pt-1 justify-between '>
                {
                    appointments.map((item) => {
                        console.log(item);
                        let time = item.split(":");
                        return (<button className="bg-black text-red-50 px-2 w-20 col-span-1 hover:bg-white hover:border hover:text-black hover:font-bold h-10 rounded-md  border-2 items-center justify-center">
                            {time[0] + ':' + time[1]}
                        </button>)
                    }
                    )
                }

            </div> */}
          <Link
            className="px-2 py-1 border-2 mt-2  align-items-center delay-200 rounded-md border-rose-700 text-center text-rose-700 bg-white hover:bg-rose-700 hover:text-white"
            to={`/businesses/${_id}`}
          >
            Find Appointment
          </Link>
        </div>
      </div>
    )
  );
};

export default BusinessesMapView;
