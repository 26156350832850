import React from 'react';
import ReadMore from '../ReadMoreReadLess/readMoreReadLess.component';
const OverviewTab = ({ name, description, category }) => {
    console.log(description);
    return (
        <div className='flex flex-col'>
            <div className='text-center border-b-2 py-8 md:text-3xl sm:text-sm border-grey-100 font-bold'>
                {name}
            </div>
            <div className='grid grid-cols-3 py-5'>
                <div className='col-span-1  text-m  font-bold'>
                    Business Type : {category?.title}
                </div>
                <div className='col-span-1  text-m  font-bold'>
                    { }
                </div>
                <div className='col-span-1  text-m  font-bold'>
                    { }
                </div>
            </div>
            <div className='flex flex-col py-5'>
                <div className='col-span-1 text-m  font-bold'>
                    <ReadMore>{description}</ReadMore>
                </div>
            </div>

        </div>

    );
}

export default OverviewTab;