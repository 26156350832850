import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as StarEmpty } from "../../assets/starEmpty.svg";
import { ReactComponent as StarFilled } from "../../assets/starFilled.svg";

const ShowPopup = ({ business }) => {
  const { _id, rating, category, name, images } = business;
  return (
    <div>
      <Link
        to={`/businesses/${_id}`}
        className="text-lg mb-3 text-rose-700 hover:text-rose-500 hover:underline font-bold"
      >
        {name}
      </Link>
      <div className="grid grid-cols-5 h-24 w-96">
        <Link
          to={`/businesses/${_id}`}
          className="rounded-md col-span-1 outline:none focus:appearence-none"
          style={{
            backgroundImage: `url(${images[0]?.url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "auto",
            height: "auto",
          }}
        />

        <div className="flex flex-col px-4 w-full col-span-4">
          <div className="flex flex-row ">
            <ul className="flex">
              <li>
                <StarFilled />
              </li>
              <li>
                <StarFilled />
              </li>
              <li>
                <StarFilled />
              </li>
              <li>
                <StarEmpty />
              </li>
              <li>
                <StarEmpty />
              </li>
            </ul>
          </div>
          {rating > 4.5 ? (
            <div className="text-sm">Exceptional</div>
          ) : (
            <div className="text-sm font-semibold">Awesome</div>
          )}
          <div className="text-sm text-amber-600 font-semibold">
            {" "}
            {category.title}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowPopup;
