import React, { useState, useEffect } from "react";
import {
  Route,
  useRouteMatch,
  useParams,
  Link,
  Switch,
} from "react-router-dom";
import SearchBar from "../../Components/SearchBar/searchbar.component";
import {
  getBusinessesByCategory,
  getBusinessesBySortingFilter,
} from "../../Backend API's/all_businesses.api";
import AllBusinessMarker from "../../Components/AllBusinessMapMarker/allbusinessesmarker.component";
import { connect, useSelector } from "react-redux";
import "./categoriespage.styles.scss";
// import BusinessDetails from "../BusinessDetailsPage/businessdetails.component";
import {
  setAllBusinesses,
  setMarkers,
  setSelectedBusiness,
} from "../../Redux/Businesses/businesses.action";
import DisplayBusinessesList from "../../Components/DisplayBusinessesList/display-businesses-list.component";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { FaHeart, FaRoute, FaStar } from "react-icons/fa";
import SearchBusinessesAcrossCategories from "../../Components/SearchBusinessesAcrossCategories/SearchBusinessesAcrossCategories";

const CategoriesPage = ({
  businessData,
  setAllBusinesses,
  setSelectedBusiness,
  setMarkers,
}) => {
  const { categoryId } = useParams();
  const { url, path } = useRouteMatch();
  console.log(" Url ", url);
  console.log(" path ", path);

  const SortingFeatures = [
    {
      _id: 0,
      title: "Featured",
    },
    {
      _id: 1,
      title: "Distance",
    },
    {
      _id: 2,
      title: "Highest Rated",
    },
    {
      _id: 2,
      title: "Earilest Appointment",
    },
  ];

  // const [markers, setMarkers] =useState([]);
  const [businesses, setBusinesses] = useState([]);
  // const [allBusinesses, setAllBusinesses] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [userCategory, setUserCategory] = useState("");
  const [searchBusiness, setSearchBusiness] = useState("");
  const [sortBy, setSortBy] = useState(0);
  const [location, setLocation] = useState({});
  const [range, setRange] = useState(5000);
  const [rating, setRating] = useState(null);

  const currentUser = useSelector(getCurrentUser);
  //     {
  //         id: 1,
  //         title: 'International Student Services',
  //         imageUrl: 'https://www.buffalo.edu/content/www/international-student-services/jcr:content/top/keytasks/keytaskspar/image.img.1440.800.q65.jpg/1628024321818.jpg',
  //         rating: '4.5',
  //         appointments: ['10:00', '11:00', '13:15'],
  //         category: 'Student Services',
  //         description: 'UB is a global community of students, faculty and staff with ties to various regions of the world.  Counseling Services and International Student Services recognize the traumatic impact that armed conflict can have, even when we are not physically present or directly involved.The conflict in the Ukraine is undoubtedly impacting the mental health and well-being of our campus community.'
  //     },
  //     {
  //         id: 2,
  //         title: 'Campus Dining Shops',
  //         imageUrl: 'http://www.buffalo.edu/content/dam/www/reporter/2020-photos/07/Ellicott-COVID-best-practices-2/Ellicott-sanitization-1.jpg',
  //         rating: '4.5',
  //         appointments: ['10:00', '11:00', '13:15'],
  //         category: 'On Campus Jobs',
  //         description: 'Different perspectives, unique ways of thinking — our employees are as varied as our menus. This is true no matter what your role is or where you are in the organization. Our employees are welcomed into a culture where they are engaged, challenged, and supported — helping make us a leader in collegiate foodservice and support services.'
  //     },

  // ])

  const handleSearchFieldChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (name === "userCategory") setUserCategory(value);
    if (name === "searchCity") setSearchCity(value);
    if (name === "searchState") setSearchState(value);
    if (name === "searchBusiness") setSearchBusiness(value);
  };

  const handleSortFilterChange = (event) => {
    const { name, value } = event.target;
    setSortBy(value);
  };

  useEffect(() => {
    if (Object.keys(location).length == 0) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
    if (sortBy == 0) {
      fetchData(categoryId).catch(console.error);
      return;
    }
    fetchSortedBusinessData().catch(console.error);
  }, [sortBy, range]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }, []);

  const fetchSortedBusinessData = async () => {
    if (Object.keys(location).length == 0) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }

    const formData = {
      longitude: location.longitude,
      latitude: location.latitude,
      distance: range,
      categoryId: categoryId,
    };
    const res = await getBusinessesBySortingFilter(
      currentUser?.token,
      formData
    );
    setBusinesses(res.data.data);
    setAllBusinesses(res.data.data);
    const marks = res.data.data.map((business) => {
      const marked = [
        business.location.coordinates[0],
        business.location.coordinates[1],
        business._id,
      ];
      return marked;
    });
    setMarkers(marks);
  };

  const fetchData = async (id) => {
    const res = await getBusinessesByCategory(id);
    setBusinesses(res.data);
    setAllBusinesses(res.data);
    const marks = res.data.map((business) => {
      const marked = [
        business.location.coordinates[0],
        business.location.coordinates[1],
        business._id,
      ];
      return marked;
    });
    setMarkers(marks);
  };

  useEffect(() => {
    // declare the data fetching function

    //if category id from the parameter does not match the stored categoryId then Call the API

    // call the function, make sure to catch any error
    fetchData(categoryId).catch(console.error);
  }, []);

  const handleSearchSubmit = () => {
    try {
      if (categoryId !== userCategory)
        fetchData(userCategory).catch(console.error);
      const businessSortedByCity = businessData?.allBusinesses.filter(
        (business) => {
          if (searchCity == "") return business;
          else if (
            business.city.toLowerCase().includes(searchCity.toLowerCase())
          )
            return business;
        }
      );
      const businessSortedByState = businessSortedByCity.filter((business) => {
        if (searchState == "") return business;
        else if (
          business.state.toLowerCase().includes(searchState.toLowerCase())
        )
          return business;
      });

      const businessSortedByName = businessSortedByState.filter((business) => {
        if (searchBusiness == "") return business;
        else if (
          business.name.toLowerCase().includes(searchBusiness.toLowerCase())
        )
          return business;
      });

      console.log(businessSortedByName);
      setBusinesses(businessSortedByName);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex flex-col h-screen">
        <div className="flex flex-col md:h-24 sm:h-fit bg-black w-full rounded-md  items-center justify-center md:my-2">
          <SearchBar
            handleSearchSubmit={handleSearchSubmit}
            handleSearchFieldChange={handleSearchFieldChange}
            userCategory={userCategory}
            searchBusiness={searchBusiness}
            searchCity={searchCity}
            searchState={searchState}
          />
        </div>

        <div className="mt-2 justify-center">
          <div
            className="flex flex-col items-center px-2"
            style={{ flexDirection: "row" }}
          >
            <div
              // style={{
              //   backgroundImage: "map_image.jpeg",
              //   backgroundSize: "cover",
              //   backgroundRepeat: "no-repeat",
              // }}
              className="flex rounded-md h-24 w-1/3 items-center "
            >
              <Link
                className="text-white bg-cyan-500 px-2 py-2 mx-auto rounded-md font-bold hover:bg-cyan-600 text-center "
                to={`/maps`}
              >
                Map View
              </Link>
              <SearchBusinessesAcrossCategories categoryId={categoryId} />
            </div>
            <span>Click this to view all appointments within the category</span>
            {/* Filters */}
            <div className="ml-auto">
              <select
                id="sortBy"
                value={sortBy}
                name="sortBy"
                className="sm:w-full pr-2 md:wrap-content h-full pl-7 border-transparent bg-white sm:text-sm md:rounded-l-full sortby-dropdown "
                placeholder="Sort By"
                onChange={handleSortFilterChange}
              >
                {SortingFeatures.map((feature) => (
                  <option value={feature._id}>{feature.title}</option>
                ))}
              </select>
            </div>
          </div>
          <div
            className="divide-x-2 flex flex-col items-center px-2"
            style={{ flexDirection: "row" }}
          >
            <div style={{ alignSelf: "flex-start" }}>
              <div>
                <label>
                  <FaRoute
                    size={15}
                    beatFade
                    color="#BE123C"
                    style={{ display: "inherit", marginBottom: "5px" }}
                  />
                  <span style={{ paddingLeft: "7px" }}>Distance</span>
                </label>
                <br />
                <input
                  type="radio"
                  name="range"
                  value="10"
                  onClick={(event) => setRange(event.target.value)}
                  checked={range == 10 && "checked"}
                />{" "}
                Upto 10 miles
                <br />
                <input
                  type="radio"
                  name="range"
                  value="30"
                  onClick={(event) => setRange(event.target.value)}
                  checked={range == 30 && "checked"}
                />{" "}
                Upto 30 miles
                <br />
                <input
                  type="radio"
                  name="range"
                  value="50"
                  onClick={(event) => setRange(event.target.value)}
                  checked={range == 50 && "checked"}
                />{" "}
                Upto 50 miles
                <br />
                <input
                  type="radio"
                  name="range"
                  value="5000"
                  onClick={(event) => setRange(event.target.value)}
                  checked={range == 5000 && "checked"}
                />{" "}
                Beyond 50 miles
                <br />
              </div>
              <div className="rating-filter">
                <label>
                  <FaHeart
                    size={15}
                    beatFade
                    color="#BE123C"
                    style={{ display: "inherit", marginBottom: "5px" }}
                  />
                  <span style={{ paddingLeft: "7px" }}>Rating</span>
                </label>
                <br />
                <input
                  type="radio"
                  name="rating"
                  value="4"
                  onClick={(event) => setRating(event.target.value)}
                  checked={rating == 4 && "checked"}
                />{" "}
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="gray" size={15} /> &amp; up
                <br />
                <input
                  type="radio"
                  name="rating"
                  value="3"
                  onClick={(event) => setRating(event.target.value)}
                  checked={rating == 3 && "checked"}
                />{" "}
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} /> &amp; up
                <br />
                <input
                  type="radio"
                  name="rating"
                  value="2"
                  onClick={(event) => setRating(event.target.value)}
                  checked={rating == 2 && "checked"}
                />{" "}
                <FaStar color="#ffc107" size={15} />
                <FaStar color="#ffc107" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} /> &amp; up
                <br />
                <input
                  type="radio"
                  name="rating"
                  value="1"
                  onClick={(event) => setRating(event.target.value)}
                  checked={rating == 1 && "checked"}
                />{" "}
                <FaStar color="#ffc107" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} />
                <FaStar color="gray" size={15} /> &amp; up
                <br />
              </div>
              <div></div>
            </div>
            <div className="businessList flex flex-col sm:w-full h-screen px-2 overflow-scroll">
              <DisplayBusinessesList businesses={businesses} />
            </div>
          </div>
          {/* <div className="businessList flex flex-col sm:w-full h-screen px-2 overflow-scroll">
            <DisplayBusinessesList businesses={businesses} />
          </div> */}
        </div>
      </div>

      <Switch>
        <Route path={`/maps`}>
          <AllBusinessMarker />
        </Route>
      </Switch>
    </div>
  );
};
const mapStateToProps = (state) => ({
  businessData: state.businessData,
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setAllBusinesses: (data) => dispatch(setAllBusinesses(data)),
  setSelectedBusiness: (business) => dispatch(setSelectedBusiness(business)),
  setMarkers: (markers) => dispatch(setMarkers(markers)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
