import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../Redux/user/user.selector";
import { getBusinessByUserID } from "../../../Backend API's/BusinessAPI/business_api";
import ReadMore from "../../ReadMoreReadLess/readMoreReadLess.component";
import useInput from "../../../hooks/use-input";
import CustomButton from "../../CustomButton/custom-button.component";
import { getCategories } from "../../../Backend API's/Authentication/authentication.api";
import { getStatesInUSA } from "../../../Backend API's/Get-States-City/getAllStates";
import { getCities } from "../../../Backend API's/Get-States-City/getCities";
import { updateBusinessProfile } from "../../../Backend API's/BusinessAPI/business_api";
import { useSnackbar } from "notistack";
const FORM_CONTROL = "form-control";

const Home = () => {
  const currentUser = useSelector(getCurrentUser);
  const [business, setBusiness] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [media, setMedia] = useState();
  const [preview, setPreview] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const {
    value: enteredName,
    isTouched: isEnteredNameTouched,
    hasError: isEnteredNameInvalid,
    inputChangeHandler: nameChangeHandler,
    inputBlueHandler: nameBlurHandler,
    reset: nameReset,
    patch: namePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: city,
    isTouched: isEnteredCityTouched,
    hasError: isEnteredCityInvalid,
    inputChangeHandler: cityChangeHandler,
    inputBlueHandler: cityBlurHandler,
    reset: cityReset,
    patch: cityPatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredPhone,
    isTouched: isEnteredPhoneTouched,
    hasError: isEnteredPhoneInvalid,
    inputChangeHandler: phoneChangeHandler,
    inputBlueHandler: phoneBlurHandler,
    reset: phoneReset,
    patch: phonePatch,
  } = useInput((value) => value.trim().length === 10);

  const {
    value: category,
    isTouched: isCategoryTouched,
    hasError: isCategoryInvalid,
    inputChangeHandler: categoryChangeHandler,
    inputBlueHandler: categoryBlurHandler,
    reset: categoryReset,
    patch: categoryPatch,
  } = useInput((value) => Object.keys(value).length > 0);

  const {
    value: state,
    isTouched: isStateTouched,
    hasError: isStateInvalid,
    inputChangeHandler: stateChangeHandler,
    inputBlueHandler: stateBlurHandler,
    reset: stateReset,
    patch: statePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: address,
    isTouched: isAddressTouched,
    hasError: isAddressInvalid,
    inputChangeHandler: addressChangeHandler,
    inputBlueHandler: addressBlurHandler,
    reset: addressReset,
    patch: addressPatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: description,
    isTouched: isDescriptionTouched,
    hasError: isDescriptionInvalid,
    inputChangeHandler: descriptionChangeHandler,
    inputBlueHandler: descriptionBlurHandler,
    reset: descriptionReset,
    patch: descriptionPatch,
  } = useInput((value) => value.trim().length > 0);

  let isFormValid = false;

  if (
    !(
      isEnteredNameInvalid &&
      isEnteredCityInvalid &&
      isEnteredPhoneInvalid &&
      isCategoryInvalid &&
      isStateInvalid &&
      isAddressInvalid &&
      isDescriptionInvalid
    )
  ) {
    isFormValid = true;
  }
  const nameClasses = isEnteredNameInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const enteredCityClasses = isEnteredCityInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const enteredPhoneClasses = isEnteredPhoneInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const categoryClasses = isCategoryInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const stateClasses = isStateInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const addressClasses = isAddressInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;
  const descriptionClasses = isDescriptionInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const handleFormDisplay = (isShow) => {
    patchValues();
    setShowForm(isShow);
  };
  const handleCancel = () => {
    handleFormDisplay(false);
  };

  const stateChangeAndPopulateCities = (event) => {
    const { name, value } = event.target;
    stateChangeHandler(event);
    populateCities(value);
  };

  const populateCities = (value) => {
    const currentCities = getCities(value);
    console.log(currentCities);
    //setCities([]);
    //console.log(modifiedCities);
    setCities(currentCities);
  };
  const patchValues = () => {
    populateCities(business?.state);
    namePatch(business?.name || "");
    cityPatch(business?.city || "");
    categoryPatch(business?.category || {});
    phonePatch(business?.phone || "");
    statePatch(business?.state || "");
    addressPatch(business?.location?.formattedAddress || "");
    descriptionPatch(business?.description || "");
  };

  const fetchData = async () => {
    const resp = await getBusinessByUserID(currentUser?.token);
    console.log(resp.data[0]);
    setMedia(null);
    setPreview(resp?.data[0]?.images[0]?.url);
    setBusiness(resp.data[0]);
  };
  const convertfileToUrl = (file) => {
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    //console.log(objectUrl);
  };
  const fetchCatStates = async () => {
    const categoriesResp = await getCategories();

    const res = getStatesInUSA();
    //console.log(res);
    setStates(res);

    //console.log(resp.data[0]);
    //console.log("Categories", categoriesResp.data);
    setCategories(categoriesResp.data);
  };

  useEffect(() => {
    fetchData().catch((error) => console.error);
    fetchCatStates().catch((error) => console.error);
  }, []);

  const submitHandler = async (event) => {
    //console.log(currentUser?.token);
    event.preventDefault();

    const updatedData = {
      ...business,
      name: enteredName,
      category: category,
      city: city,
      state: state,
      description: description,
      address: address,
      phone: enteredPhone,
    };
    //add image and json to formdata object
    const formData = new FormData();
    if (media) formData.append("image", media);
    formData.append("document", JSON.stringify(updatedData));
    const resp = await updateBusinessProfile(
      business._id,
      formData,
      currentUser?.token
    );
    enqueueSnackbar("Business profile updated", {
      variant: "info",
    });

    fetchData().catch((error) => console.error);
    handleCancel();
  };

  const mediaChangeHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(file);
      //console.log("file", file);
      convertfileToUrl(file);
    }
  };
  if (Object.keys(business).length == 0) return <div>Loading</div>;

  return (
    Object.keys(business).length > 0 && (
      <div class="p-10">
        {!showForm && (
          <div class="max-w-full rounded overflow-hidden">
            <div
              className="container h-96"
              style={{
                backgroundImage: `url(${preview})`,

                //backgroundImage: { preview },
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <img class="w-full" src={preview} alt="Image Business" /> */}
            </div>

            <div class="grid grid-cols-1 px-1 py-4 gap-2 md:grid-cols-2">
              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Name </div>
                <div class="px-1 md:text-lg text-sm">{business?.name}</div>
              </div>

              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Category </div>
                <div class="px-1  md:text-lg text-sm">
                  {business?.category?.title}
                </div>
              </div>

              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">City</div>
                <div class="px-1  md:text-lg text-sm">{business?.city}</div>
              </div>

              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">State</div>
                <div class="px-1  md:text-lg text-sm">{business?.state}</div>
              </div>

              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Location</div>
                <div class=" md:text-lg text-sm ">
                  {business?.location?.formattedAddress}
                </div>
              </div>
              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">
                  Number of Ratings
                </div>
                <div class=" md:text-lg text-sm ">
                  {business?.numberOfRatings}
                </div>
              </div>
              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Rating</div>
                <div class=" md:text-lg text-sm ">{business?.rating?.avg}</div>
              </div>

              <div class="py-2 flex flex-row space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Phone</div>
                <div class=" md:text-lg text-sm ">{business?.phone}</div>
              </div>

              <div class="py-2 flex flex-col md:flex-row md:space-x-3 md:col-span-1">
                <div class="font-bold md:text-lg text-sm">Description</div>
                <div class=" md:text-lg text-sm w-3/4">
                  <ReadMore dataLimit="200">{business?.description}</ReadMore>
                </div>
              </div>
            </div>
            <CustomButton onClick={(e) => handleFormDisplay(true)}>
              Edit
            </CustomButton>
          </div>
        )}
        {showForm && (
          <>
            <form
              onSubmit={submitHandler}
              class="max-w-full rounded overflow-hidden"
            >
              <div className="control-group">
                <div
                  className="container h-96"
                  style={{
                    backgroundImage: `url(${preview})`,

                    //backgroundImage: { preview },
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img class="w-full" src={preview} alt="Image Business" /> */}
                </div>
                <input
                  onChange={mediaChangeHandler}
                  accept="image/*"
                  type="file"
                />

                <div class="w-full grid grid-cols-1 px-1 py-4 gap-2 md:grid-cols-2">
                  <div
                    className={nameClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label
                      htmlFor="firstName"
                      class="font-bold md:text-lg text-sm"
                    >
                      Name<em class="error-text">*</em>
                    </label>
                    <input
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="name"
                      value={enteredName}
                      onChange={nameChangeHandler}
                      onBlur={nameBlurHandler}
                    />
                    {isEnteredNameInvalid && (
                      <p className="error-text">
                        Business Name can not be empty
                      </p>
                    )}
                  </div>

                  <div
                    className={categoryClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label
                      htmlFor="category"
                      class="font-bold md:text-lg text-sm"
                    >
                      Category<em class="error-text">*</em>
                    </label>
                    <select
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="category"
                      value={category._id}
                      onChange={categoryChangeHandler}
                      onBlur={categoryBlurHandler}
                    >
                      {categories.map((category) => (
                        <option value={category._id} key={category._id}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                    {isCategoryInvalid && (
                      <p className="error-text">Category can not be empty</p>
                    )}
                  </div>

                  <div
                    className={enteredCityClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label htmlFor="city" class="font-bold md:text-lg text-sm">
                      City<em class="error-text">*</em>
                    </label>
                    <select
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="category"
                      value={city}
                      onChange={cityChangeHandler}
                      onBlur={cityBlurHandler}
                    >
                      <option hidden>City</option>

                      {cities.map(({ name, id }) => (
                        <option value={name} key={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                    {isEnteredCityInvalid && (
                      <p className="error-text">City can not be empty</p>
                    )}
                  </div>

                  <div
                    className={stateClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label htmlFor="state" class="font-bold md:text-lg text-sm">
                      State<em class="error-text">*</em>
                    </label>
                    <select
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="category"
                      value={state}
                      onChange={stateChangeAndPopulateCities}
                      onBlur={stateBlurHandler}
                    >
                      <option hidden>State</option>

                      {states.map(({ name, isoCode }) => (
                        <option value={name} key={isoCode}>
                          {name}
                        </option>
                      ))}
                    </select>
                    {isStateInvalid && (
                      <p className="error-text">State can not be empty</p>
                    )}
                  </div>

                  <div
                    className={addressClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label htmlFor="state" class="font-bold md:text-lg text-sm">
                      Address<em class="error-text">*</em>
                    </label>
                    <input
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="category"
                      value={address}
                      onChange={addressChangeHandler}
                      onBlur={addressBlurHandler}
                    />
                    {isAddressInvalid && (
                      <p className="error-text">Address can not be empty</p>
                    )}
                  </div>

                  <div class="py-2 flex flex-col space-x-3 md:col-span-1">
                    <div class="font-bold md:text-lg text-sm">
                      Number of Ratings
                    </div>
                    <div class=" md:text-lg text-sm ">
                      {business?.numberOfRatings}
                    </div>
                  </div>
                  <div class="py-2 flex flex-col space-x-3 md:col-span-1">
                    <div class="font-bold md:text-lg text-sm">Rating</div>
                    <div class=" md:text-lg text-sm ">
                      {business?.rating?.avg}
                    </div>
                  </div>

                  <div
                    className={enteredPhoneClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label htmlFor="phone" class="font-bold md:text-lg text-sm">
                      Phone<em class="error-text">*</em>
                    </label>
                    <input
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="phone"
                      value={enteredPhone}
                      onChange={phoneChangeHandler}
                      onBlur={phoneBlurHandler}
                    />
                    {isEnteredPhoneInvalid && (
                      <p className="error-text">
                        Phone should contain 10 digits
                      </p>
                    )}
                  </div>

                  <div
                    className={descriptionClasses}
                    class="py-2 flex flex-col space-x-3 md:col-span-1"
                  >
                    <label htmlFor="phone" class="font-bold md:text-lg text-sm">
                      Description<em class="error-text">*</em>
                    </label>
                    <input
                      class="w-3/4 px-2 py-2 border-2"
                      type="text"
                      id="description"
                      value={description}
                      onChange={descriptionChangeHandler}
                      onBlur={descriptionBlurHandler}
                    />
                    {isEnteredPhoneInvalid && (
                      <p className="error-text">Descrition cannot be empty</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-actions">
                <CustomButton
                  type="submit"
                  disabled={!isFormValid}
                  primary="bg-lime-700"
                  secondary="bg-lime-500"
                >
                  Update
                </CustomButton>
                <CustomButton
                  type="button"
                  primary="bg-rose-700"
                  secondary="bg-rose-500"
                  onClick={handleCancel}
                >
                  cancel
                </CustomButton>
              </div>
            </form>
          </>
        )}
      </div>
    )
  );
};

export default Home;
