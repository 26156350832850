import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllAppointments } from "../../Redux/BookedAppointments/appt.selector";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "./../../assets/calendar-solid.svg";
import { ReactComponent as CheckIcon } from "./../../assets/circle-check-regular.svg";
import GiveReviews from "../GiveReviews/give-reviews.component";
import SuccessToast from "../ToastAlerts/toast-alerts.component";
import ErrorToast from "../ToastAlerts/error-toast.component";
import { Appointment } from "./Appointment";

const EditBooking = () => {
  const allAppointments = useSelector(getAllAppointments);
  //const [business, setBusiness] = useState(null);
  //const [service, setService] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const renderBookings = allAppointments.map((booking) => {
    return <Appointment key={booking?._id} booking={booking} />;
  });

  return (
    <div className="flex border-1 shadow-2xl bg-purple-50 flex-col h-fit">
      {renderBookings}
    </div>
  );
};
export default EditBooking;
