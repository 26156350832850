import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const updateService = (businessId, serviceId, userUpdate, token) => {
  return axios({
    method: "PATCH",
    withCredentials: true,
    headers: { "x-auth-token": token },
    data: userUpdate,
    url: `${SERVER_URL}/api/businesses/${businessId}/services/${serviceId}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const removeService = (token, businessId, serviceId) => {
  return axios({
    method: "DELETE",
    withCredentials: true,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/businesses/${businessId}/services/${serviceId}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const fetchServicesWithinCategory = (categoryId) => {
  return axios({
    method: "GET",
    withCredentials: true,
    //headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/categories/${categoryId}/services`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};
