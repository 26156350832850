import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as CalendarIcon } from "./../../assets/calendar-solid.svg";
import { ReactComponent as CheckIcon } from "./../../assets/circle-check-regular.svg";
import GiveReviews from "../GiveReviews/give-reviews.component";
import SuccessToast from "../ToastAlerts/toast-alerts.component";
import ErrorToast from "../ToastAlerts/error-toast.component";

export const Appointment = ({ booking }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (new Date() > new Date(booking?.startTime)) {
    return booking ? (
      <>
        <div className="grid grid-cols-3  px-4 py-1 ">
          <Link
            to={`/businesses/${booking?.business?._id}`}
            className="rounded-md col-span-1 sm:h-[10rem] w-full"
            style={{
              backgroundImage: `url(${booking?.business?.images[0]?.url})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div className="flex flex-col px-4 w-full col-span-2">
            <Link
              to={`/businesses/${booking?.business?._id}`}
              className="md:text-lg sm:text-sm text-rose-700 hover:underline delay-200 font-bold"
            >
              {booking?.business?.name}
            </Link>
            <div className="flex flex-row py-1.5 ">
              <CheckIcon className="h-5 w-5" fill="green" />
              <span className=" px-2 text-sm font-semibold">
                Appointment Completed
              </span>
            </div>
            <span className="py-1 text-sm font-semibold">
              {" "}
              {booking?.service?.name}
            </span>
            <div className="flex flex-row py-1">
              <CalendarIcon className="h-5 w-5" fill="gray" />
              <span className="mx-2 py-0.5 text-sm font-semibold">
                {" "}
                {new Date(booking?.startTime).toLocaleDateString(
                  "en-US",
                  options
                ) +
                  " at " +
                  new Date(booking?.startTime).toLocaleTimeString("en-US")}
              </span>
            </div>
          </div>
        </div>
        <GiveReviews
          serviceId={booking?.service?._id}
          setError={setError}
          setSuccess={setSuccess}
          businessId={booking?.business?._id}
          bookingId={booking?._id}
        />
      </>
    ) : (
      <></>
    );
  }

  return null;
};
