import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const getUnverifiedBusiness = (token) => {
    return axios({
        method: "GET",
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/admin/unapproved-businesses`
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};

export const saveApprovedBusiness = (businessId, token) => {
    console.log(businessId);
    return axios({
        method: "POST",
        data: {
            businessId
        },
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/admin/business-verification`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};