import React, { useState } from "react";
import MapMarkers from "../MapComponent/map-marker.component";
import { Link, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAllBusinesses,
  getMarkers,
} from "../../Redux/Businesses/businesses.selector";
import SearchBar from "../SearchBar/searchbar.component";
import BusinessesMapView from "../BusinessesMapView/businesses-map-view.component";

const AllBusinessMarker = () => {
  const allBusinesses = useSelector(getAllBusinesses);
  const [businesses, setBusinesses] = useState(allBusinesses);

  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [personCount, setPersonCount] = useState("");
  const [searchBusiness, setSearchBusiness] = useState("");

  const handleSearchFieldChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (name === "personCount") setPersonCount(value);
    if (name === "searchCity") setSearchCity(value);
    if (name === "searchState") setSearchState(value);
    if (name === "searchBusiness") setSearchBusiness(value);
  };
  const handleSearchSubmit = () => {
    try {
      const businessSortedByCity = allBusinesses.filter((business) => {
        if (searchCity == "") return business;
        else if (business.city.toLowerCase().includes(searchCity.toLowerCase()))
          return business;
      });
      const businessSortedByState = businessSortedByCity.filter((business) => {
        if (searchState == "") return business;
        else if (
          business.state.toLowerCase().includes(searchState.toLowerCase())
        )
          return business;
      });

      const businessSortedByName = businessSortedByState.filter((business) => {
        if (searchBusiness == "") return business;
        else if (
          business.name.toLowerCase().includes(searchBusiness.toLowerCase())
        )
          return business;
      });

      console.log(businessSortedByName);
      setBusinesses(businessSortedByName);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex flex-col h-24 bg-black w-full rounded-md  items-center justify-center my-2">
        <SearchBar
          handleSearchSubmit={handleSearchSubmit}
          handleSearchFieldChange={handleSearchFieldChange}
          personCount={personCount}
          searchBusiness={searchBusiness}
          searchCity={searchCity}
          searchState={searchState}
        />
      </div>
      <div className="grid md:grid-cols-7 divide-x-2 h-screen w-full mt-2 justify-center">
        <div className="flex flex-col md:col-span-4 w-full">
          <MapMarkers />
        </div>
        <div className="flex flex-col h-screen md:col-span-3 overflow-scroll">
          <BusinessesMapView businesses={businesses} />
        </div>
      </div>
    </div>
  );
};

export default AllBusinessMarker;

{
  /* // borderStyle = 'solid' 
        // borderColor='black' 
        // borderWidth = {2}  
        // markers ={markers}
        // width={1000}
        // height={1000}
        // marginBlock={20}
        // mapStyle ="mapbox://styles/mapbox/streets-v9" */
}
