import React, { useState, useEffect } from "react";
import { businessRegister } from "../../Backend API's/BusinessAPI/business_api";
import CustomButton from "../../Components/CustomButton/custom-button.component";
import { connect } from "react-redux";
import { getCities } from "../../Backend API's/Get-States-City/getCities";
import { getStatesInUSA } from "../../Backend API's/Get-States-City/getAllStates";
import { getCategories } from "../../Backend API's/Authentication/authentication.api";
import BusinessFunctions from "../../Components/BusinessFunctions/businessfunctions.component";
import validator from "validator";

const BusinessPage = ({ currentUser }) => {
  const [image, setImage] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [license, setLicense] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [categories, setCategories] = useState([]);

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    return isValidPhoneNumber;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("license", license);
    formData.append("category", category);
    formData.append("image", image);

    try {
      console.log(formData);
      const res = await businessRegister(formData, currentUser.token);
      console.log(res);
      setSuccess("Registration success");
      setError("");
    } catch (error) {
      setSuccess("");
      setError(error.response.data.msg);
      console.log(error.response.data.msg);
    }
    setName("");
    setDescription("");
    setPhone("");
    setCountry("");
    setState("");
    setCity("");
    setLicense("");
    setCategory("");
    setAddress("");
    setImage([]);
  };
  useEffect(() => {
    const res = getStatesInUSA();
    console.log(res);
    setStates(res);
    const fetchData = async () => {
      const res = await getCategories();
      console.log(res.data);
      setCategories(res.data);
    };
    // call the function, make sure to catch any error
    fetchData().catch(console.error);
  }, []);

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        setIsPhoneValid(false);
      } else setIsPhoneValid(true);
      setPhone(value);
    } else if (name === "country") {
      setCountry(value);
    } else if (name === "state") {
      setState(value);
      const currentCities = getCities(value);
      // let modifiedCities = [];
      // currentCities.forEach((item, index) => {
      //     modifiedCities.push({ ...item, id: index })
      // });
      console.log(currentCities);
      setCities([]);
      //console.log(modifiedCities);
      setCities(currentCities);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "license") {
      setLicense(value);
    } else if (name === "category") {
      setCategory(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "image") {
      setImage(files[0]);
    }
  };
  return (
    <div>
      {currentUser === null ? (
        <div className="md:text-3xl sm:text-sm text-center text-green-900">
          Please sign in to view this page!
        </div>
      ) : currentUser?.user?.role === "business" ? (
        <BusinessFunctions />
      ) : currentUser?.user?.role === "basic" &&
        currentUser?.user?.businessRequested == true ? (
        <div className="md:text-3xl sm:text-sm text-center text-green-900">
          {" "}
          Request for business approval has been sent, please wait for approval
        </div>
      ) : currentUser?.user?.role !== "admin" ? (
        <div className=" flex flex-col items-center w-screen">
          <h2 className="mt-6 text-center md:text-3xl sm:text-sm font-extrabold text-gray-900">
            Register your Business now by submitting the request
          </h2>
          <h4 className="mt-6 text-center md:text-2xl sm:text-sm text-gray-900">
            One of our admins will approve the business if the details are
            valid. This process might take up to 24 hrs.
          </h4>

          <div className="w-1/3">
            <div className="">
              <div>
                {error ? (
                  <p className="mt-2 bg-red-300 text-center h-12 justify-center  rounded-md md:text-xl sm:text-sm text-black">
                    <span className="py-2 font-medium "> {error}</span>
                  </p>
                ) : success ? (
                  <p className=" mt-1 bg-green-400 h-12 rounded-md justify-center  text-center md:text-xl sm:text-sm text-black">
                    <span className="py-2 font-medium "> {success}</span>
                  </p>
                ) : (
                  <div />
                )}
              </div>
              <form
                className="mt-8 space-y-6"
                action="#"
                onSubmit={handleSubmit}
                method="POST"
                encType="multipart/form-data"
              >
                <input type="hidden" name="remember" value="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Name
                    </label>
                    <input
                      value={name}
                      id="name"
                      name="name"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="Business Name"
                    />
                  </div>
                  <div>
                    <label htmlFor="description" className="sr-only">
                      Descrription
                    </label>
                    <input
                      value={description}
                      id="description"
                      name="description"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="Business description"
                    />
                  </div>
                  <div
                    className={
                      isPhoneValid ? "form-control" : "form-control invalid"
                    }
                  >
                    <label htmlFor="phone" className="sr-only">
                      Phone Number
                    </label>
                    <input
                      value={phone}
                      id="phone"
                      name="phone"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div>
                    <label htmlFor="address" className="sr-only">
                      Street Address
                    </label>
                    <input
                      value={address}
                      id="address"
                      name="address"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="Street Address"
                    />
                  </div>
                  <div>
                    <label htmlFor="state" className="sr-only">
                      State
                    </label>
                    <select
                      value={state}
                      id="state"
                      name="state"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="State"
                    >
                      <option hidden>State</option>

                      {states.map(({ name, isoCode }) => (
                        <option value={name} key={isoCode}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="city" className="sr-only">
                      City
                    </label>
                    <select
                      value={city}
                      id="city"
                      name="city"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="City"
                    >
                      <option hidden>City</option>

                      {cities.map(({ name, id }) => (
                        <option value={name} key={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="license" className="sr-only">
                      License
                    </label>
                    <input
                      value={license}
                      id="license"
                      name="license"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="License"
                    />
                  </div>
                  <div>
                    <label htmlFor="category" className="sr-only">
                      Category
                    </label>
                    <select
                      value={category}
                      id="category"
                      name="category"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      onChange={handleChange}
                      placeholder="Category"
                    >
                      <option hidden>Categories</option>

                      {categories.map(({ title, _id }) => (
                        <option value={title} key={_id}>
                          {title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleChange}
                      name="image"
                      id=""
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                    </div>
                </div> */}

                <div className="flex flex-col w-full items-center ">
                  <CustomButton type="submit"> SIGN UP</CustomButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:text-3xl sm:text-sm text-center">
          {" "}
          Either you are an admin or you have not signed in!!{" "}
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(BusinessPage);
