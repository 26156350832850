import { SET_ALL_BUSINESSES, REMOVE_ALL_BUSINESSES, SET_SEARCHED_BUSINESSES, REMOVE_SEARCHED_BUSINESSES, SET_SELECTED_BUSINESS, REMOVE_SELECTED_BUSINESS, SET_MARKERS, REMOVE_MARKERS } from "./businesses.types"

const INITIAL_STATE = {
    allBusinesses: null,
    selectedBusiness: null,
    markers: null,
    searchedBusinesses:null
}
const businessReducer = (state = INITIAL_STATE, action) => {
    const {payload} = action;
    switch (action.type) {
        case SET_ALL_BUSINESSES:
            return {
                ...state,
                allBusinesses: payload
            }
        case REMOVE_ALL_BUSINESSES:
            return {
                ...state,
                allBusinesses: null
            }
            case SET_SEARCHED_BUSINESSES:
                return {
                    ...state,
                    searchedBusinesses: payload
                }
            case REMOVE_SEARCHED_BUSINESSES:
                return {
                    ...state,
                    searchedBusinesses: null
                }
        case SET_SELECTED_BUSINESS:
            return {
                ...state,
                selectedBusiness: payload
            }
        case REMOVE_SELECTED_BUSINESS:
            return {
                ...state,
                selectedBusiness: null
            }
        case SET_MARKERS:
            return {
                ...state,
                markers: payload
            }
        case REMOVE_MARKERS:
            return {
                ...state,
                markers: null
            }
        default:
            return state;
    }
}

export default businessReducer;