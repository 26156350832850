import React, { useState } from "react";

const ReadMore = ({ children, dataLimit }) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false);
  console.log(children);
  const toggleButton = () => {
    setReadMoreShown((prevState) => !prevState);
  };
  return (
    <div>
      {isReadMoreShown ? children : children.substr(0, dataLimit)}
      <button className=" font-md text-red-700" onClick={toggleButton}>
        {isReadMoreShown ? "Read Less" : "... Read More"}
      </button>
    </div>
  );
};

export default ReadMore;
