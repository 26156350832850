import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../CustomButton/custom-button.component";
import { sendNote } from "../../Backend API's/SlotsAPI/slots";
import { getCurrentUser } from "../../Redux/user/user.selector";

const SendNote = ({ setSuccess, setError, bookingId }) => {
  const [userNote, setUserNote] = useState(null);
  const currentUser = useSelector(getCurrentUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == userNote) setUserNote(value);
  };

  const submitNote = async () => {
    try {
      const resp = await sendNote(bookingId, userNote, currentUser?.token);
      console.log(resp);
      setSuccess(resp.data.msg);
      setError("");
      setUserNote("");
    } catch (error) {
      setError(error.response.data.msg);
      setSuccess("");
      console.log(error.response.data.msg);
    }
  };

  return (
    <div>
      <span className="py-2 px-3  text-lg font-bold">
        What to do before your appointment
      </span>
      <div className="px-3 flex flex-col">
        <div className="py-2 text-md font-bold">Note from the reception.</div>
        <div className="py-2 text-sm font-semibold"></div>
      </div>
      <div className="px-3 flex flex-col">
        <div className=" py-2 text-lg font-bold">
          Add a note for your attender.
        </div>
        <input
          type="text"
          value={userNote}
          name="userNote"
          className="py-2 px-3 text-md font-bold"
          onChange={handleChange}
        />
        <div className="w-2/5">
          <CustomButton
            onClick={() => submitNote()}
            primary="bg-red-700"
            secondary="bg-red-400"
          >
            Send Note{" "}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
export default SendNote;
