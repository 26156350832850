import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const register = (email, password, firstname, lastname) => {
  return axios({
    method: "POST",
    data: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
    },
    withCredentials: true,
    url: `${SERVER_URL}/api/users`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const updateUserInfo = (formData, token, userId) => {
  // console.log(formData);
  return axios({
    method: "PATCH",
    data: formData,
    withCredentials: true,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/users/${userId}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const login = (email, password) => {
  console.log(SERVER_URL);
  return axios({
    method: "POST",
    data: {
      email: email,
      password: password,
    },
    withCredentials: true,
    url: `${SERVER_URL}/api/auth`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const loginWithGoogle = () => {
  console.log(SERVER_URL);
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/auth/google`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const loginWithFacebook = () => {
  console.log(SERVER_URL);
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/auth/facebook`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};
export const getCategories = () => {
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/categories`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const search = (token, personCount, location, business) => {
  return axios({
    method: "GET",
    data: {
      personCount: personCount,
      location: location,
      business: business,
    },
    withCredentials: true,
    header: token,
    url: `${SERVER_URL}/api/search`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

// export const logout = (loginUsername, loginPassword) => {
//     axios({
//         method: "POST",
//         data: {
//             username: loginUsername,
//             password: loginPassword,
//         },
//         withCredentials: true,
//         url: "http://localhost:4000/logout",
//     }).then((res) => console.log(res));
// };

// export const getUser = () => {
//     axios({
//         method: "GET",
//         withCredentials: true,
//         url: "http://localhost:4000/User",
//     }).then((res) => {
//         console.log(res.data);
//     });
// };
