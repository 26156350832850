import React, { useState, useEffect } from "react";
import CustomButton from "../../CustomButton/custom-button.component";
import { getCities } from "../../../Backend API's/Get-States-City/getCities";
import { getStatesInUSA } from "../../../Backend API's/Get-States-City/getAllStates";
import { getBusinessByUserID } from "../../../Backend API's/BusinessAPI/business_api";
import { connect } from "react-redux";
import { addServiceToBusiness } from "../../../Backend API's/BusinessAPI/services_api";
import { useSnackbar } from "notistack";
import validator from "validator";

const AddServices = ({ currentUser, setShowAddServices }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    return isValidPhoneNumber;
  };
  const validatePostalCode = (code) => {
    const isValidPostalCode = validator.isPostalCode(code, "US");
    return isValidPostalCode;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("zipcode", zipcode);
    formData.append("state", state);
    formData.append("price", price);
    formData.append("image", image);
    formData.append("duration", duration);

    try {
      const res = await getBusinessByUserID(currentUser?.token);
      const businessId = res.data[0]?._id;
      console.log("formdata inside", name);
      const resp = await addServiceToBusiness(
        formData,
        currentUser?.token,
        businessId
      );
      console.log(resp.data.msg);
      setSuccess("Service Added");
      setError("");
    } catch (error) {
      setSuccess("");
      setError(error.response.data.msg);
      alert(error);
      enqueueSnackbar(`${error.response.data.msg} Please try again`, {
        variant: "info",
      });
    }

    enqueueSnackbar("Service added", { variant: "info" });
    setName("");
    setDescription("");
    setPhone("");
    setState("");
    setCity("");
    setPrice("");
    setAddress("");
    setZipcode("");
    setImage(null);
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "name") {
      setName(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        console.log("invalid");
        setIsPhoneValid(false);
      } else setIsPhoneValid(true);

      setPhone(value);
    } else if (name === "state") {
      setState(value);
      const currentCities = getCities(value);
      // let modifiedCities = [];
      // currentCities.forEach((item, index) => {
      //     modifiedCities.push({ ...item, id: index })
      // });
      // console.log(currentCities);
      setCities([]);
      //console.log(modifiedCities);
      setCities(currentCities);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "price") {
      setPrice(value);
    } else if (name === "duration") {
      setDuration(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "zipcode") {
      if (!validatePostalCode(value)) {
        console.log("invalid");
        setIsPostalCodeValid(false);
      } else setIsPostalCodeValid(true);
      setZipcode(value);
    } else if (name === "image") {
      setImage(files[0]);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const res = getStatesInUSA();
      setStates(res);
    };
    // call the function, make sure to catch any error
    fetchData().catch(console.error);
  }, []);

  return (
    <form
      className="mt-8 md:px-8 px-3 space-y-6 "
      action="#"
      onSubmit={handleSubmit}
      method="POST"
      encType="multipart/form-data"
    >
      <div className="flex flex-wrap sm:items-start gap-10 md:justify-center shadow-sm -space-y-px">
        <div className="form-control">
          <label
            htmlFor="name"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Service Name
          </label>
          <input
            value={name}
            id="name"
            name="name"
            type="text"
            required
            className="appearance-none rounded-t-md relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 font-bold text-sm"
            onChange={handleChange}
            placeholder="Service Name"
          />
        </div>
        <div className="form-control">
          <label
            htmlFor="description"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Service Description
          </label>
          <input
            value={description}
            id="description"
            name="description"
            type="text"
            required
            className="appearance-none rounded-none relative  block md:w-96 sm:w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 font-bold text-sm"
            onChange={handleChange}
            placeholder="Service Description"
          />
        </div>
        <div className={isPhoneValid ? "form-control" : "form-control invalid"}>
          <label
            htmlFor="phone"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Phone Number
          </label>
          <input
            value={phone}
            id="phone"
            name="phone"
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="Phone Number"
          />
        </div>
        <div className="form-control">
          <label
            htmlFor="address"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Street Address
          </label>
          <input
            value={address}
            id="address"
            name="address"
            type="text"
            required
            className="appearance-none rounded-none relative block md:w-96 sm:w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="Street Address"
          />
        </div>
        <div className="form-control">
          <label
            htmlFor="state"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            State
          </label>
          <select
            value={state}
            id="state"
            name="state"
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="State"
          >
            <option hidden>State</option>

            {states.map(({ name, isoCode }) => (
              <option value={name} key={isoCode}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-control">
          <label
            htmlFor="city"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            City
          </label>
          <select
            value={city}
            id="city"
            name="city"
            type="text"
            required
            className="appearance-none rounded-none relative block w-24 px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="City"
          >
            <option hidden>City</option>

            {cities.map(({ name, id }) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div
          className={
            isPostalCodeValid ? "form-control" : "form-control invalid"
          }
        >
          <label
            htmlFor="zipcode"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Zip Code
          </label>
          <input
            value={zipcode}
            id="zipcode"
            name="zipcode"
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="Zip Code"
          />
        </div>
        <div className="form-control">
          <label
            htmlFor="price"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Price
          </label>
          <input
            value={price}
            id="price"
            name="price"
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="Price"
          />
        </div>
        <div className="form-control">
          <label
            htmlFor="duration"
            className="font-bold md:text-xl sm:text-sm mb-5"
          >
            Duration (in minutes){" "}
          </label>
          <input
            value={duration}
            id="duration"
            name="duration"
            type="text"
            required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
            onChange={handleChange}
            placeholder="Duration"
          />
        </div>
        <div className="form-control">
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleChange}
            name="image"
            id=""
            className="block border-none w-full text-sm text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-md file:border-0
      file:text-sm file:font-semibold
      file:bg-rose-50 file:text-rose-700
      hover:file:bg-rose-100"
            placeholder="Choose Image"
          />
        </div>
      </div>
      {image && (
        <img
          style={{
            paddingTop: "3rem",
            margin: "auto",
            height: "400px",
            width: "400px",
          }}
          src={URL.createObjectURL(image)}
        />
      )}

      {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                    </div>
                </div> */}
      {error ? (
        <p className=" mt-2 text-center md:text-xl sm:text-sm text-red-600">
          <span className="font-medium text-red-600 "> {error}</span>
        </p>
      ) : success ? (
        <p className=" mt-1 bg-green-400 h-12 rounded-md justify-center  text-center md:text-xl sm:text-sm text-black">
          <span className="py-2 font-medium "> {success}</span>
        </p>
      ) : (
        <div />
      )}

      <div className="flex justify-center gap-5 w-full items-center ">
        <CustomButton type="submit"> Add Service</CustomButton>
        <button
          onClick={() => setShowAddServices(false)}
          type="button"
          class="text-white w-fit bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2  md:text-lg"
        >
          Collapse Form
        </button>
      </div>
    </form>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(AddServices);
