import "./App.css";
import React,{useState} from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect,useHistory } from "react-router-dom";
//import { useRouteMatch } from 'react-router';
import { SnackbarProvider } from "notistack";
import HomePage from "./Pages/HomePage/homepage.component";
import Header from "./Components/Header/header.component";
import SignInAndSignUpPage from "./Pages/SignInAndSignUp/sign-in-and-sign-up.component";
import BusinessPage from "./Pages/BusinessPage/businesspage.component";
import AdminPage from "./Pages/AdminPage/admin.component";
import CategoriesPage from "./Pages/CategoriesPage/categoriespage.components";
import BusinessDetails from "./Pages/BusinessDetailsPage/businessdetails.component";
import AllBusinessMarker from "./Components/AllBusinessMapMarker/allbusinessesmarker.component";
import EditBooking from "./Components/EditBooking/edit-booking.component";
import ViewOpenAppointments from "./Components/ViewOpenAppointments/view-open-appointments.component";
import { useEffect } from "react";
const BUSINESS = "business";
const USER = "basic";
const ADMIN = "admin";

function App({ currentUser }) {
  const [role,setRole] =useState(currentUser?.user?.role);
   const history = useHistory();

  useEffect(() => {
       console.log(history);
    const timeoutId = setTimeout(() => {
       console.log(role);
      if(role === BUSINESS) history.push('/business');
    }, 10);
    return () => clearTimeout(timeoutId);
  }, [history]);

 useEffect( ()=>{
   setRole(currentUser?.user?.role);

 },[currentUser]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration= {3000}
    >
    <div className="App">
      <Header/>
      <Switch>
        <Route exact path='/'  component={HomePage} />
        <Route exact path='/admin' component={AdminPage} />
        <Route exact path='/business' component={BusinessPage} />
        <Route exact path={`/categories/:categoryId`} component={CategoriesPage} />
        <Route exact path={`/businesses/:businessId`} component={BusinessDetails} />
        <Route exact path="/booking/:bookingId" component ={EditBooking}/>
        <Route path="/openappointments" component={ViewOpenAppointments} />

        <Route path='/signinandsignup'
          render={() => (role === 'basic' || role === 'admin') ? (
            <Redirect to='/' />
          ) : (role === 'business') ? (
            <Redirect to='/business' />
          ) :
           (<SignInAndSignUpPage />)
          } />
       {/* <Route path="/openappointments" component={ViewOpenAppointments} /> */}



{/* <Route path='/maps'
          render={() => (<MapMarkers />)
          } /> */}

        <Switch>
        <Route path='/maps'>
          <AllBusinessMarker />
        </Route>
        </Switch>


      </Switch>
    </div>
    </SnackbarProvider>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
})
export default connect(mapStateToProps)(App);
