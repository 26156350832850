import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addCategory = (formData) => {
  console.log("inside");
  return axios({
    method: "POST",
    data: formData,
    withCredentials: true,
    url: `${SERVER_URL}/api/categories`,
  }).catch((error) => {
    console.log(SERVER_URL);
    console.log("In axios", error.response);
    throw error;
  });
};
