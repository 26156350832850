import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getUserBookings = (token) => {
  return axios({
    method: "GET",
    withCredentials: true,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/users/user/bookings`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const getBusinessBookings = (token, businessId) => {
  return axios({
    method: "GET",
    withCredentials: true,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/businesses/${businessId}/bookings`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const cancelUserBooking = (token, bookingId) => {
  return axios({
    method: "DELETE",
    withCredentials: true,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/users/user/bookings/${bookingId}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const getOpenAppointments = (data) => {
  const { serviceId, timezone, date } = data;
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/businesses/6232a1b3867d915af1224ca1/slots/open-appointments?serviceId=${serviceId}&timeZone=${timezone}&clientDateString=${date}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const getAppointmentsInCategory = (data) => {
  const { categoryId, serviceName, startDate, endDate } = data;
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/categories/${categoryId}/appointments?serviceName=${serviceName}&startDate=${startDate}&endDate=${endDate}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};
