import React, { createRef, useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import { Link, Switch, Route } from "react-router-dom";
import listPlugin from "@fullcalendar/list";
import {
  getUserBookings,
  cancelUserBooking,
} from "../../Backend API's/SlotsAPI/appointment";
import Dialog from "../DialogBox/dialogbox.component";
import { getAllAppointments } from "../../Redux/BookedAppointments/appt.selector";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { setAllAppointments } from "../../Redux/BookedAppointments/appt.actions";
import EditBooking from "../EditBooking/edit-booking.component";

const ViewAppointment = ({ allEvents, events, role }) => {
  const calendarRef = createRef();
  const dispatch = useDispatch();
  //get appointments  everything in redux
  const allEventsData = allEvents;
  //const clickedEvent = useSelector(getCurrentAppointment);
  const currentUser = useSelector(getCurrentUser);
  const [calendarObj, setCalendarObj] = useState();
  //const [allEventsData, setAllEventsData] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [cancelledMessage, setCancelledMessage] = useState("");
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [clickedEvent, setClickedEvent] = useState();
  console.log(allEvents, events, role);
  const DialogActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-blue-500 text-white
              font-medium  hover:bg-blue-600 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={handleCloseDialog}
        >
          Close
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base 
              font-medium text-white hover:bg-red-700 hover:text-white focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleCancelAppointment}
        >
          Cancel Appointment
        </button>
        {role === "user" ? (
          <Link
            to={`/booking/${clickedEvent._id}`}
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md
              border border-gray-300 shadow-sm px-4 py-2 bg-cyan-600 text-base 
              font-medium text-white hover:bg-cyan-700 hover:text-white focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleCancelAppointment}
          >
            Edit Booking
          </Link>
        ) : (
          <> </>
        )}
      </div>
    );
  };
  const DialogCancelActions = (btnColor) => {
    return (
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border text-base 
              border-transparent shadow-sm px-4 py-2 bg-blue-500 text-white
              font-medium  hover:bg-blue-600 focus:outline-none
              focus:ring-2 focus:ring-offset-2 
              focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
          onClick={handleCloseCancelDialog}
        >
          OK
        </button>
      </div>
    );
  };

  const handleCancelAppointment = async () => {
    if (!showCancel) setShowCancel(true);
    else {
      //cancel
      try {
        //notify backend

        const resp = await cancelUserBooking(
          currentUser?.token,
          clickedEvent._id
        );
        setCancelledMessage(resp.data.msg);

        //take calendarRef obj and remove it from calendar
        console.log(calendarObj);
        const toRemoveEvent = calendarObj.getEventById(clickedEvent._id);
        console.log(toRemoveEvent);
        toRemoveEvent.remove();

        // setAllEventsData(allEventsData.map(event => {
        //     if(clickedEvent._id !== event.id)
        //     return event;
        // }));
      } catch (error) {
        console.error(error);
        setCancelledMessage(error.response.data.err + " Please try again.");
      }
      setShowCancelMessage(!showCancelMessage);
      handleCloseDialog();
    }
  };
  const handleCloseCancelDialog = () => {
    setShowCancelMessage(!showCancelMessage);
  };
  const handleCloseDialog = () => {
    setShowCancel(false);
    setShowDialog(!showDialog);
  };
  const handleEventClick = (info) => {
    //console.log(info.event._def.publicId);
    //Calendar object
    const calendar = calendarRef.current.getApi();
    //console.log(calendar);
    setCalendarObj(calendar);

    const clickedEv = allEventsData.find(
      (evt) => info.event._def.publicId === evt._id
    );
    console.log(clickedEv);
    setClickedEvent(clickedEv);
    setShowDialog(!showDialog);
  };

  return (
    <Fragment>
      <FullCalendar
        events={
          events
          //     [
          //     {"title":"Conference","start":"2022-05-02","end":"2022-05-04"},
          //     {"title":"Meeting","start":"2022-05-03T10:30:00+00:00","end":"2022-05-03T12:30:00+00:00"},
          //     {"title":"Lunch","start":"2022-05-03T12:00:00+00:00"},
          //     {"title":"Birthday Party","start":"2022-05-04T07:00:00+00:00"}
          // ]
        }
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        customButtons={{
          timeDayButton: {
            text: "day",
            click() {
              const calendar = calendarRef.current;
              if (calendar) {
                const calendarApi = calendar.getApi();
                calendarApi.changeView("timeGridDay");
              }
            },
          },
          timeWeekButton: {
            text: "week",
            click() {
              const calendar = calendarRef.current;
              if (calendar) {
                const calendarApi = calendar.getApi();
                calendarApi.changeView("timeGridWeek");
              }
            },
          },
        }}
        headerToolbar={{
          left: "prev,next",
          center: "title",
          right: "today,dayGridMonth,timeDayButton,timeWeekButton,listWeek",
        }}
        eventClick={handleEventClick}
      />
      {showDialog && (
        <Dialog
          title={
            role == "user"
              ? `You have booked an appointment with ${clickedEvent?.business?.name} for ${clickedEvent.service.name}`
              : `You have an appointment with customer ${
                  clickedEvent?.user
                    ? clickedEvent?.user?.firstname +
                      " " +
                      clickedEvent?.user?.lastname
                    : clickedEvent?.unregisteredUserName
                }`
          }
          handleCloseDialog={handleCloseDialog}
          actionsPannel={DialogActions("bg-red")}
          size={"w-3/5"}
          color={"bg-red"}
        >
          {" "}
          {
            <div className="flex flex-col">
              <p>
                {role == "businesss" ? <b> Service : </b> : <div />}
                <b> Appointment Time :</b>{" "}
                {` From ${new Date(
                  clickedEvent.startTime
                ).toLocaleTimeString()} to ${new Date(
                  clickedEvent.endTime
                ).toLocaleTimeString()}`}
              </p>
              {showCancel ? (
                <p className="md:text-xl sm:text-sm font-bold text-color-rose-900">
                  Are you sure you want to cancel this appointment ?{" "}
                </p>
              ) : (
                <div>
                  <p>
                    <b> Contact Information : </b>
                    {role == "user"
                      ? clickedEvent.service.phone
                      : clickedEvent.user
                      ? clickedEvent.user.email
                      : clickedEvent.unregisteredUserName}
                  </p>
                  {role == "user" ? (
                    <p className="">
                      {" "}
                      <b>Address: </b>{" "}
                      {clickedEvent.service?.location?.formattedAddress}{" "}
                    </p>
                  ) : (
                    <p>
                      <b>Staff Member: </b> {clickedEvent.staff.firstname}{" "}
                      {clickedEvent.staff.lastname}
                    </p>
                  )}

                  {clickedEvent?.note && (
                    <p className="">
                      {" "}
                      <b>Note: </b> {clickedEvent.note}{" "}
                    </p>
                  )}
                </div>
              )}
            </div>
          }
        </Dialog>
      )}
      {showCancelMessage && (
        <Dialog
          title={cancelledMessage}
          handleCloseDialog={handleCloseCancelDialog}
          actionsPannel={DialogCancelActions("bg-red")}
          size={"w-3/5"}
          color={"bg-red"}
        ></Dialog>
      )}
    </Fragment>
  );
};

// const mapDispatchToProps = dispatch => ({
//     addAppointment: appointment => dispatch(addAppointment(appointment))
// });
export default ViewAppointment;
