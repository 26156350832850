import React, { useState } from "react";
import CustomButton from "../CustomButton/custom-button.component";
import FormInput from "../Form-Input/form-input.component";
import { login } from "../../Backend API's/Authentication/authentication.api";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../Redux/user/user.actions";
import "./sign-in.styles.scss";
import { useSnackbar } from "notistack";
import { setAllAppointments } from "../../Redux/BookedAppointments/appt.actions";
import { getUserBookings } from "../../Backend API's/SlotsAPI/appointment";
import validator from "validator";

const SignIn = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validateEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    return isValidEmail;
  };
  const validatePassword = (password) => {
    const isValidPassword = validator.isStrongPassword(password);
    return isValidPassword;
  };
  const handleSubmit = async (event) => {
    //const { setCurrentUser } = this.props;
    const emptyStr = "";

    event.preventDefault();
    try {
      const res = await login(email, password);

      if (res.data) {
        dispatch(setCurrentUser(res.data));
        const bookings = await getUserBookings(res?.data?.token);
        dispatch(setAllAppointments(bookings.data));
      }
      enqueueSnackbar("Sign in successful...", { variant: "info" });
      setError(emptyStr);
      setEmail(emptyStr);
      setPassword(emptyStr);
    } catch (error) {
      setError(error.response.data.msg);
      enqueueSnackbar(`${error.response.data.msg} Please try again`, {
        variant: "error",
      });
    }
    setEmail(emptyStr);
    setPassword(emptyStr);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "email") {
      if (!validateEmail(value)) {
        setIsEmailValid(false);
      } else setIsEmailValid(true);
      setEmail(value);
    } else if (name == "password") {
      if (!validatePassword(value)) {
        setIsPasswordValid(false);
      } else setIsPasswordValid(true);

      setPassword(value);
    }
  };
  const glogin = () => {
    window.open(
      `${SERVER_URL}/api/auth/google`,
      "mywindow",
      "location=1,status=1,scrollbars=1, width=800,height=800"
    );
  };

  const flogin = () => {
    window.open(
      `${SERVER_URL}/api/auth/facebook`,
      "mywindow",
      "location=1,status=1,scrollbars=1, width=800,height=800"
    );
  };

  const handleLoginOAuth = async (event) => {
    console.log(event.target.name);

    try {
      let res = null;
      event.target.name == "signInWithGoogle" ? glogin() : flogin();
      let listener = window.addEventListener("message", (message) => {
        if (message?.data?.token && message?.data?.user) {
          res = message.data;
          console.log("inside oauth32ueagjy78fsbd", res);
          dispatch(setCurrentUser(res));
          setError(" ");
        }
      });
    } catch (error) {
      setError(error.response.data.msg);
    }
  };

  return (
    <div className="sign-in md:w-1/2 sm:w-full">
      {/* <h2> I already have an account</h2>
            <span>Sign In with your email and password</span> */}

      <div className="min-h-full flex items-center justify-center ">
        <div className=" flex flex-col max-w-md w-full space-y-8 items-center ">
          <div>
            <h2 className="mt-6 text-center  sm:text-md md:md:text-3xl sm:text-sm font-extrabold text-gray-900">
              I already have an account
            </h2>
            {error ? (
              <p className=" mt-2 text-center md:text-xl sm:text-sm text-red-600">
                <span className="font-medium text-red-600 "> {error}</span>
              </p>
            ) : (
              <div />
            )}
          </div>
          <form
            className="mt-8 space-y-6 md:w-48 lg:w-full"
            action="#"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div class={isEmailValid ? "" : "invalid"}>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  value={email}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Email address"
                />
              </div>
              <div class={isPasswordValid ? "" : " invalid"}>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                                <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password?</a>
                            </div> */}
            </div>
            <CustomButton type="submit"> SIGN IN</CustomButton>
          </form>
          <div className="flex flex-row  space-x-4">
            <button
              name="signInWithGoogle"
              onClick={handleLoginOAuth}
              className="px-3 rounded-md py-2 delay-200  w-fit text-white text-lg bg-blue-500 hover:bg-blue-600 hover:text-white "
            >
              Sign in with Google
            </button>
            <button
              name="signInWithFacebook"
              onClick={handleLoginOAuth}
              className="px-3 py-2 delay-200 rounded-md w-fit  text-white text-lg bg-blue-500 hover:bg-blue-600  hover:text-white"
            >
              Sign in with Facebook
            </button>
          </div>
        </div>
      </div>

      {/* <form onSubmit={handleSubmit}>
                <FormInput name='email' type='text' value={email} onChange={handleChange} label='Email' required />
                <FormInput name='password' type='password' value={password} onChange={handleChange} label='Password' required />

                <CustomButton type="submit">SIGN IN</CustomButton>

            </form> */}
    </div>
  );
};

export default SignIn;
