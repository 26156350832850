import { REMOVE_CATEGORY_ID, SET_CATEGORIES, SET_CATEGORY_ID } from "./categories.types";
const INITIAL_STATE = {
    categoryID: null,
    categorysections:[],
}

const categoryReducer = (state = INITIAL_STATE, action) => {
    const {payload} = action;
        switch (action.type) {
        case SET_CATEGORY_ID:
            return {
                ...state,
                categoryID: payload
            }
        case REMOVE_CATEGORY_ID:
            return {
                ...state,
                categoryID: null
            }
        case SET_CATEGORIES:
            return {
                ...state,
                categorysections:payload
            }
        default:
            return state;
    }
}

export default categoryReducer;
