import BookSlot from "../MakeAppointment/ActonsAfterSlotSelected";
import { useState, useEffect } from "react";
import { getBusinessByUserID } from "../../Backend API's/BusinessAPI/business_api";
import { getCurrentUser } from "../../Redux/user/user.selector";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
export const SlotCards = ({ service, business, slots, setNext }) => {
  const [navigatetToBook, setNavigatetToBook] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentUserBusinessId, setCurrentUserBusinessId] = useState(null);
  const currentUser = useSelector(getCurrentUser);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const handleBook = (e, slot) => {
    console.log(slot);
    setSelectedSlot(slot);
    setNavigatetToBook(true);
  };

  useEffect(() => {
    // api to fetch all services within 1 category

    const fetchCurrentUserBusinessId = async () => {
      const res = await getBusinessByUserID(currentUser?.token);
      const userBusinessId = res.data[0]?._id;
      setCurrentUserBusinessId(userBusinessId || null);
    };

    fetchCurrentUserBusinessId().catch(console.error);
    // setServiceList();
  }, []);
  if (slots?.length === 0)
    return (
      <span class="text-md text-black">
        {" "}
        No slots to display for this business. Please go back and select another
        business.{" "}
      </span>
    );
  console.log(slots);
  return (
    <div class="flex">
      <div class="flex h-20 h-full pt-5">
        <button
          href="#"
          class=" h-20 items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={(e) => setNext(false)}
        >
          <FaArrowLeft />
        </button>
      </div>
      <div class="flex flex-wrap gap-2 justify-center py-5">
        {slots?.length > 0 &&
          slots?.map((slot) => (
            <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div class="flex flex-col items-center py-4">
                {/* <img
                            class="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={`url(${business.business?.images[0]?.url})`}
                            alt=""
                            style={{
                              backgroundImage: `url(${business.business?.images[0]?.url})`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          /> */}
                {/* <Link
                            to={`/businesses/${business._id}`}
                            class="mb-1 text-xl font-medium text-gray-900 dark:text-white"
                          >
                            {business?.business?.name}
                          </Link>
                          <span class="text-sm text-gray-500 dark:text-gray-400">
                            Service : {selectedService}
                          </span> */}
                <span class="text-lg text-white dark:text-white">
                  {" "}
                  {new Date(slot.start).toLocaleDateString(
                    "en-US",
                    options
                  )}{" "}
                  {new Date(slot.start).toLocaleTimeString("en-US")}
                </span>
                <span class="text-sm text-gray-500 dark:text-gray-400">
                  Capacity : {slot.cap} slots
                </span>
                <div class="flex mt-4 space-x-3 md:mt-6">
                  <button
                    href="#"
                    class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={(e) => handleBook(e, slot)}
                  >
                    Book
                  </button>
                  {/* <button
                              href="#"
                              class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              //onClick={}
                            >
                              Message
                            </button> */}
                </div>
              </div>
            </div>
          ))}
      </div>
      {navigatetToBook && (
        <BookSlot
          service={service}
          businessId={business._id}
          date={selectedSlot.start}
          setDialogOpen={setNavigatetToBook}
          selectedSlot={selectedSlot}
          next={navigatetToBook}
          setNext={setNavigatetToBook}
          staff={selectedSlot.availableStaff}
          currentUserBusinessId={currentUserBusinessId}
          setStaff={setSelectedSlot}
        />
      )}
    </div>
  );
};
