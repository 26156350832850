import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaPenFancy, FaTrash } from "react-icons/fa";
import {
  removeService,
  updateService,
} from "../../../Backend API's/Services/service";
import ReadMore from "../../ReadMoreReadLess/readMoreReadLess.component";
import UpdateService from "./update-service.component";
import { getCurrentUser } from "../../../Redux/user/user.selector";
import { useSnackbar } from "notistack";

const ServiceCard = ({ service, setIsUpdated }) => {
  const [toUpdate, setToUpdate] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const { enqueueSnackbar } = useSnackbar();
  const [userUpdate, setUserUpdate] = useState({
    name: service.name,
    price: service.price,
    address: service.location.formattedAddress,
    description: service.description,
    duration: service.duration,
    phone: service.phone,
    state: service.state,
    city: service.city,
    zipcode: service.zipcode,
  });

  console.log(service);
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    let newService = { ...userUpdate };
    newService[name] = value;
    //console.log(newService);
    setUserUpdate(newService);
  };
  const handleUpdate = () => {
    setToUpdate(true);
  };
  const handleDelete = () => {
    setToDelete(true);
    deleteService();
  };

  const deleteService = async () => {
    const resp = await removeService(
      currentUser?.token,
      service?.business,
      service._id
    );
    //console.log(resp.data);
    setIsUpdated(true);

    enqueueSnackbar("Service is deleted", {
      variant: "info",
    });
  };
  const cancelServiceUpdates = () => {
    setToUpdate(false);
  };

  const saveServiceUpdate = async () => {
    setToUpdate(false);
    console.log(userUpdate);
    const resp = await updateService(
      service?.business,
      service._id,
      userUpdate,
      currentUser?.token
    );
    //console.log(resp.data);
    setIsUpdated(true);
    enqueueSnackbar("Service is updated", {
      variant: "info",
    });
  };

  // const deleteService = () => {
  //   setIsUpdated(true);
  //   setToDelete(false);
  // };

  const ShowService = () => {
    return (
      <div class=" w-full lg:max-w-full lg:flex">
        <div
          class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{ backgroundImage: `url(${service?.images[0]?.url})` }}
          title="Mountain"
        ></div>
        <div class="border-r w-full border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          <div class="mb-8">
            <div class="text-gray-900 font-bold text-xl mb-2">
              {service.name}
            </div>
            <p class="text-sm text-gray-600 flex items-center mb-1">
              {service.location.formattedAddress}
            </p>
            <div class="text-sm space-y-1">
              <p class="text-gray-900 leading-none">
                Duration : {service.duration}
              </p>
              <p class="text-gray-600">Price: {service.price}</p>
            </div>

            <p class="text-gray-700 text-base">
              {" "}
              <ReadMore dataLimit="200">{service?.description}</ReadMore>
            </p>
          </div>
          <div class="flex space-x-4 items-center">
            <FaPenFancy
              onClick={handleUpdate}
              size={20}
              className="service-actions"
            />
            <FaTrash
              onClick={handleDelete}
              size={20}
              className="service-actions"
            />
          </div>
        </div>
      </div>
    );
  };
  if (toDelete)
    return (
      <>
        {/* <Dialog
        title="Please select a staff member from the available staff"
        handleCloseDialog={handleCloseDialog}
        actionsPannel={DialogActions("bg-red")}
        size={"w-4/5"}
        color={"bg-red"}
      >

      </Dialog> */}
      </>
    );
  return (
    <>
      {toUpdate ? (
        <UpdateService
          saveServiceUpdate={saveServiceUpdate}
          userUpdate={userUpdate}
          service={service}
          onHandleChange={onHandleChange}
          cancelUpdates={cancelServiceUpdates}
        />
      ) : (
        <ShowService />
      )}
    </>
  );
};

export default ServiceCard;
