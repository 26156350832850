import { SET_ALL_APPOINTMENTS,SET_CURRENT_APPOINTMENT,REMOVE_CURRENT_APPOINTMENT } from "./appt.actiontypes";
const INITIAL_STATE = {
    allAppts: [],
    currentAppt:null,
}

const apptReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case SET_CURRENT_APPOINTMENT:
            return {
                ...state,
                currentAppt: payload
            }
        case REMOVE_CURRENT_APPOINTMENT:
            return {
                ...state,
                currentAppt: null
            }
            case SET_ALL_APPOINTMENTS:
                return {
                    ...state,
                    allAppts: payload
                }
        default:
            return state;
    }
}

export default apptReducer;