import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Map, Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import ShowPopup from "../PopupComponent/popup.component";
import {
  getMarkers,
  getAllBusinesses,
} from "../../Redux/Businesses/businesses.selector";
import { ReactComponent as MarkerIcon } from "../../assets/mapMarker.svg";
import { getSelectedMarker } from "../../Redux/MapView/mapview.selector";
import { setSelectedMarker } from "../../Redux/MapView/mapview.actions";
import { connect } from "react-redux";

const MapMarkers = ({ setSelectedMarker }) => {
  const markers = useSelector(getMarkers);
  const searchedBusinesses = useSelector(getAllBusinesses);
  const selectedMarker = useSelector(getSelectedMarker);

  const [viewState, setViewState] = useState({
    longitude: -78.82282,
    latitude: 42.986482,
    width: "100%",
    height: "100%",
    zoom: 8,
  });
  const toggleShowPopup = (e, marker) => {
    const found = searchedBusinesses.find(
      (business) => business?._id === marker[2]
    );
    setSelectedMarker(found);
  };
  // const {
  //     // borderWidth,
  //     // borderStyle,
  //     // borderColor,
  //     markers,
  //     // width,
  //     // marginBlock,
  //     // height,
  //     // mapStyle
  // } = props;
  //   useEffect(() => {
  //     const CloseOptions = (e) => {
  //       console.log(e);
  //       // if (e.path[0].tagName !== "BUTTON") {
  //       //   setSelectedMarker(null);
  //       // }
  //     };
  //     document.body.addEventListener("mouseenter", CloseOptions);
  //     return () => document.body.removeEventListener("mouseenter", CloseOptions);
  //   }, []);
  return (
    <div className="flex h-screen w-full">
      <Map
        {...viewState}
        // onMove={evt => setViewState(evt.viewState)}
        // style={{ borderStyle: borderStyle,
        //       borderWidth: borderWidth,
        //       borderColor: borderColor ,
        //       marginBlock: marginBlock,
        //       width: width,
        //       height: height }}
        // mapStyle={mapStyle}

        // initialViewState = {{
        //     latitude: 37.8,
        //     longitude: -122.4,
        //     zoom: 14
        //   }}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{
          width: "100%",
          height: "100%",
        }}
        mapboxAccessToken="pk.eyJ1Ijoic2FyYS1yZGthciIsImEiOiJjbDI3b24wNzIwMDhtM2RuN2NucHFyY3EzIn0.tEcoXi9a7knVQ-HUGNCzrw"
        mapStyle="mapbox://styles/mapbox/streets-v9"
        onMouseEnter={(e) => setSelectedMarker(null)}
      >
        {markers.map((marker) => (
          <Marker
            longitude={marker[0]}
            latitude={marker[1]}
            color="red"
            // onHover={(e) => toggleShowPopup(e, marker)}
          >
            <button
              onMouseEnter={(e) => toggleShowPopup(e, marker)}
              onMouseLeave={(e) => {
                // setSelectedMarker(null);
              }}
            >
              <MarkerIcon />
            </button>
          </Marker>
        ))}
        {selectedMarker ? (
          <Popup
            longitude={selectedMarker?.location.coordinates[0]}
            latitude={selectedMarker?.location.coordinates[1]}
            onClose={() => {
              setSelectedMarker(null);
            }}
          >
            <ShowPopup business={selectedMarker} />
          </Popup>
        ) : (
          <div />
        )}
      </Map>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setSelectedMarker: (marker) => dispatch(setSelectedMarker(marker)),
});

export default connect(null, mapDispatchToProps)(MapMarkers);
