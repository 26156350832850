import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const sendReview = (reviewData, businessId, token) => {
  return axios({
    method: "POST",
    withCredentials: true,
    data: reviewData,
    headers: { "x-auth-token": token },
    url: `${SERVER_URL}/api/businesses/${businessId}/reviews`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const getReview = (businessId) => {
  return axios({
    method: "GET",
    withCredentials: true,
    url: `${SERVER_URL}/api/businesses/${businessId}/reviews`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};

export const checkIfReviewed = (businessId, slotId, userId, token) => {
  return axios({
    method: "GET",
    headers: { "x-auth-token": token },
    withCredentials: true,
    url: `${SERVER_URL}/api/businesses/${businessId}/reviews/check-reviewed/${slotId}/${userId}`,
  }).catch((error) => {
    console.log("In axios", error.response);
    throw error;
  });
};
