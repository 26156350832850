import React, { useState } from 'react';
import { connect } from 'react-redux';
import AddCategory from '../../Components/Add-Categories/add-categories.component';
import DashBoard from '../../Components/DashBoard/dashboard.component';
import UnverifiedBusiness from '../../Components/UnverifiedBusiness/unverifiedbusiness.component';

const AdminPage = ({ currentUser }) => {
    const [options, setOptions] = useState([
        {
            idx: 1,
            title: 'Dashboard'
        },
        {
            idx: 2,
            title: 'Add Categories'
        },
        {
            idx: 3,
            title: 'View Unverified Businesses'
        },
        {
            idx: 4,
            title: 'Admin Profile'
        }
    ]);

    const [toggleTab, setToggleTab] = useState(0);
    const toggle = (idx) => {
        setToggleTab(idx);
        console.log(idx);
    }
    return (

        <div>
            {
                (currentUser?.user?.role === 'admin') ?

                    (
                        <div className='grid grid-cols-4 h-full w-full pr-3 justify-center'>
                            <div className='flex flex-col col-span-1 h-96 rounded-md bg-red-700 items-center'>
                                <button className=' md:text-xl sm:text-sm py-3 text-white px-2 w-5/6 hover:bg-red-600 hover:transition hover:duration-300 mt-5 hover:text-white hover:font-bold focus:font-bold rounded h-15  focus:bg-red-200 focus:text-black' onClick={() => toggle(1)}>DashBoard</button>
                                <button className=' md:text-xl sm:text-sm py-3  text-white px-2 w-5/6 hover:bg-red-600  hover:transition hover:duration-300 hover:text-white hover:font-bold  focus:font-bold  rounded h-15  focus:bg-red-200 focus:text-black' onClick={() => toggle(2)}>Add Categories</button>
                                <button className=' md:text-xl sm:text-sm py-3  text-white px-2 w-5/6 hover:bg-red-600  hover:transition hover:duration-300 hover:text-white hover:font-bold focus:font-bold rounded  h-15  focus:bg-red-200 focus:text-black ' onClick={() => toggle(3)}>View & Verify Business</button>

                            </div>

                            <div className='flex flex-col col-span-3 px-2'>
                                {toggleTab === 1 ?
                                    (<DashBoard />) :
                                    (toggleTab === 2 ?
                                        (<AddCategory />) : (toggleTab === 3 ? <UnverifiedBusiness currentUser /> : (<div />))
                                    )
                                }
                            </div>
                        </div>
                    )
                    : (<div className='md:text-3xl sm:text-sm text-center'> You are not admin or you are not logged in</div>)
            }
        </div >
    )


}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps)(AdminPage);