import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { sendReview } from "../../Backend API's/reviews.api";
import { getCurrentUser } from "../../Redux/user/user.selector";
import CustomButton from "../CustomButton/custom-button.component";
import { useSelector } from "react-redux";
import { getReview } from "../../Backend API's/reviews.api";
import { useSnackbar } from "notistack";
import { checkIfReviewed } from "../../Backend API's/reviews.api";

const GiveReviews = ({
  serviceId,
  businessId,
  setSuccess,
  setError,
  bookingId,
}) => {
  const [rating, setRating] = useState(null);
  const currentUser = useSelector(getCurrentUser);
  const [comment, setComment] = useState("");
  const [hasReviewed, setHasReviewed] = useState(false);
  const [review, setReview] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const checkIfUserReviewed = async () => {
    const response = await checkIfReviewed(
      businessId,
      bookingId,
      currentUser?.user?.id,
      currentUser?.token
    );
    //f(response.)
    //setIsReviewed(true);
    //console.log(response);
    console.log(response);
    if (response?.data?.msg) {
      setHasReviewed(false);
    } else {
      setHasReviewed(true);
      setReview(response.data);
    }
  };

  const fetchReviewsData = async () => {
    //const resp = await getReview(businessId);
    //console.log(resp.data);
    // const found = resp.data.filter(
    //   (review) =>
    //     serviceId === review.service._id &&
    //     review.author._id === currentUser?.user?.id
    // );
    // console.log(found);
  };
  useEffect(() => {
    fetchReviewsData().catch((error) => console.log(error));
    checkIfUserReviewed().catch(console.error);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "comment") setComment(value);
  };
  const handleSubmitReview = async () => {
    const reviewData = {
      serviceId: serviceId,
      rating: rating,
      comment: comment,
      slotId: bookingId,
    };
    try {
      if (rating == null) throw new TypeError("Rating cannot be blank");
      console.log(reviewData);
      const resp = await sendReview(reviewData, businessId, currentUser.token);
      setSuccess(resp.data.msg);
      enqueueSnackbar(resp.data.msg, { variant: "info" });
      setError("");
    } catch (error) {
      let err = "";
      if (rating == null) err = error.message;
      else err = error.response.data.msg;
      enqueueSnackbar(err, { variant: "error" });
      setError(err);
      setSuccess("");
    }
    setComment("");
  };
  return hasReviewed ? (
    <div className="flex flex-col justify-center ">
      <span className="md:text-lg sm:text-sm font-bold mx-3 text-red-800 ">
        You have reviewed this appointment
      </span>
      <div className="flex flex-row justify-items-start px-3 mx-2 h-fit items-center">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;
          return (
            <label>
              <FaStar
                color={ratingValue <= review?.rating ? "#ffc107" : "gray"}
                className="hover:cursor-pointer transition-colors"
                size={25}
              />
            </label>
          );
        })}
      </div>
      <span className="md:text-lg sm:text-sm mx-3 font-semibold">
        {review?.comment}
      </span>
    </div>
  ) : (
    <div className="flex flex-col justify-center ">
      <span className="md:text-lg sm:text-sm font-bold mx-3 text-red-800 ">
        Leave your rating here
      </span>
      <div className="flex flex-row justify-items-start px-3 mx-2 h-fit items-center">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;
          return (
            <label>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                className="hidden"
                onClick={() => setRating(ratingValue)}
              />
              <FaStar
                color={ratingValue <= rating ? "#ffc107" : "gray"}
                className="hover:cursor-pointer transition-colors"
                size={25}
              />
            </label>
          );
        })}
      </div>
      <span className="md:text-lg sm:text-sm mx-3 font-semibold">
        Tell us how did the appointment go
      </span>
      <input
        type="text"
        name="comment"
        value={comment}
        onChange={handleChange}
        className="mx-2 h-fit px-3 "
      />
      <div className="flex  justify-center ">
        <CustomButton
          onClick={() => handleSubmitReview()}
          primary="bg-red-800"
          secondary="bg-white"
          children="Submit Rating"
        />
      </div>
    </div>
  );
};
export default GiveReviews;
