import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getServicesByBusinessId } from "../../../Backend API's/BusinessAPI/services_api";
import { getCurrentUser } from "../../../Redux/user/user.selector";
import AddServices from "./addServices.component";
import ServiceCard from "./serviceCard.component";

const ViewAddServices = ({ business }) => {
  const currentUser = useSelector(getCurrentUser);
  const [services, setServices] = useState([]);
  const [showAddServices, setShowAddServices] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const fetchData = async () => {
    const resp = await getServicesByBusinessId(
      currentUser?.token,
      business._id
    );
    console.log(resp.data);
    setServices(resp.data);
  };

  useEffect(() => {
    fetchData().catch((error) => console.error);
    console.log(isUpdated);
    setIsUpdated(false);
  }, [isUpdated]);

  return (
    <>
      <div className="flex flex-col items-center">
        {showAddServices ? (
          <>
            <AddServices setShowAddServices={setShowAddServices} />
          </>
        ) : (
          <button
            onClick={() => setShowAddServices(true)}
            type="button"
            class="text-white  w-fit bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-1 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 md:text-lg"
          >
            Add New Service
          </button>
        )}
        {services?.map((service) => (
          <ServiceCard service={service} setIsUpdated={setIsUpdated} />
        ))}
      </div>
    </>
  );
};

export default ViewAddServices;
