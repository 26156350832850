import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { getCurrentUser } from "../../Redux/user/user.selector";
import ChartReview from "./chart-reviews.component";
import UserReview from "./user-review.component";
import { useSelector } from "react-redux";
import { getReview } from "../../Backend API's/reviews.api";
import { getSelectedBusiness } from "../../Redux/Businesses/businesses.selector";

const ReviewsTab = () => {
  const currentUser = useSelector(getCurrentUser);
  const selectedBusiness = useSelector(getSelectedBusiness);
  const [globalAvgRating, setGlobalAvgRating] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [globalStarRatings, setGlobalStarRatings] = useState({});
  const fetchData = async () => {
    console.log(selectedBusiness);
    try {
      const resp = await getReview(selectedBusiness._id);
      console.log(resp.data);
      //let averageSum = 0;
      // let totalReviews = 0;
      // let globalReviews = {
      //   one: 0,
      //   two: 0,
      //   three: 0,
      //   four: 0,
      //   five: 0,
      // };

      // for (let review of resp.data) {
      //   averageSum += review.rating;
      //   totalReviews++;
      //   const val =
      //     review.rating === 5
      //       ? "five"
      //       : review.rating === 4
      //       ? "four"
      //       : review.rating === 3
      //       ? "three"
      //       : review.rating === 2
      //       ? "two"
      //       : review.rating === 1
      //       ? "one"
      //       : "zero";
      //   globalReviews[val]++;
      // }
      // console.log(globalReviews);
      // console.log(averageSum / totalReviews);
      // console.log(totalReviews);
      //

      // globalReviews["one"] = (globalReviews["one"] * 100) / totalReviews;
      // globalReviews["two"] = (globalReviews["two"] * 100) / totalReviews;
      // globalReviews["three"] = (globalReviews["three"] * 100) / totalReviews;
      // globalReviews["four"] = (globalReviews["four"] * 100) / totalReviews;
      // globalReviews["five"] = (globalReviews["five"] * 100) / totalReviews;

      setGlobalAvgRating(selectedBusiness.rating.avg);
      setGlobalStarRatings(selectedBusiness.rating);
      setReviewData(resp.data);
      setTotalReviews(selectedBusiness.numberOfRatings);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData().catch((error) => console.log(error));
  }, []);

  if (totalReviews === 0)
    return (
      <p className=" px-4 md:text-2xl sm:text-2xl  py-2 text-red-600  font-bold">
        No reviews for this business yet.
      </p>
    );

  return (
    <div className="flex flex-col w-full ">
      <span className=" md:text-4xl sm:text-2xl font-black  text-red-600 my-3 ">
        {" "}
        What {totalReviews} people are saying{" "}
      </span>
      <span className="md:text-xl sm:text-sm font-semibold my-2 ">
        {" "}
        Overall Ratings and reviews{" "}
      </span>
      <div className="flex flex-row ">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;
          return (
            <FaStar
              className="hover:cursor-pointer"
              color={ratingValue <= globalAvgRating ? "#ffc107" : "lightgray"}
              size={20}
            />
          );
        })}
        {/* {rating > 4.5 ? (
          <div className="text-lg px-2 font-black"> Exceptional</div>
        ) : (
          <div className="text-lg px-2 font-black"> Awesome</div>
        )} */}
        <span className="text-sm mx-2 font-black py-1 text-black ">
          {globalAvgRating?.toFixed(2)} based on all time customers ratings{" "}
        </span>
      </div>
      <ChartReview
        globalStarRatings={globalStarRatings}
        totalRating={globalAvgRating}
      />

      <div className="overscroll-auto md:overscroll-contain">
        {reviewData.map((review) => (
          <UserReview review={review} />
        ))}
      </div>
    </div>
  );
};
export default ReviewsTab;
