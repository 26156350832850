import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const businessRegister = (formData, token) => {
    console.log(formData);
    return axios({
        method: "POST",
        data: formData,
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};

export const getBusinessesDetailsByBusinessId = (businessId, token) => {
    return axios({
        method: "GET",
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};

export const getBusinessByUserID = (token) => {
    return axios({
        method: "GET",
        withCredentials: true,
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/business`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}

export const updateBusinessProfile=(businessId, data, token)=>{
 return axios({
        method: "PATCH",
        withCredentials: true,
        headers: { "x-auth-token": token },
        data:data,
        url: `${SERVER_URL}/api/businesses/${businessId}`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}