import { SET_CATEGORY_ID, SET_CATEGORIES } from "./categories.types"
import { REMOVE_CATEGORY_ID } from "./categories.types"

export const setCategoryID = categoryId => ({
    type: SET_CATEGORY_ID,
    payload: categoryId
});

export const removeCategoryID = () => ({
    type: REMOVE_CATEGORY_ID,
});
export const setCategories=(categories)=>({
   type: SET_CATEGORIES,
   payload:categories
});
