import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const addBusinessHours = (token, businessId, data) => {
    return axios({
        method: "POST",
        withCredentials: true,
        data: { data },
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/hours`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};

export const getBusinessHours = (businessId) => {
    return axios({
        method: "GET",
        withCredentials: true,
        url: `${SERVER_URL}/api/businesses/${businessId}/hours`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
};


export const getSlots = (date, serviceId, businessId) => {
    return axios({
        method: "GET",
        withCredentials: true,
        url: `${SERVER_URL}/api/businesses/${businessId}/slots?dateString=${date}&serviceId=${serviceId}
        `,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}




export const addSlot = (startTime, serviceId, businessId, token,staffId) => {
    return axios({
        method: "POST",
        withCredentials: true,
        data: {
            "startTime": startTime,
            "serviceId": serviceId,
            "staffId": staffId
        },
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/slots`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}

export const sendNote =(bookingId, note,token)=>{
     return axios({
        method: "POST",
        withCredentials: true,
        data: {
            "note": note,
        },
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/users/user/bookings/${bookingId}/note`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}

export const addBusinessBookedSlot = (startTime, serviceId, businessId, staffId, userEmail, userName, token) => {
    return axios({
        method: "POST",
        withCredentials: true,
        data: {
            "startTime": startTime,
            "serviceId": serviceId,
            "staffId": staffId,
            "userEmail": userEmail,
            "userName": userName
        },
        headers: { "x-auth-token": token },
        url: `${SERVER_URL}/api/businesses/${businessId}/business-bookings`,
    }).catch((error) => {
        console.log("In axios", error.response);
        throw error;
    });
}