import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../assets/search.svg";
import { Link } from "react-router-dom";
import { search } from "../../Backend API's/Authentication/authentication.api";
import { connect } from "react-redux";
import CategoryItem from "../Category-Item/category-item.component";
import { Combobox } from "@headlessui/react";
import { getCities } from "../../Backend API's/Get-States-City/getCities";
import { getStatesInUSA } from "../../Backend API's/Get-States-City/getAllStates";
import { getCategorysections } from "../../Redux/Categories/categories.selector";
import { useSelector } from "react-redux";

const SearchBar = (props) => {
  const categorySections = useSelector(getCategorysections);
  const {
    userCategory,
    searchCity,
    searchState,
    searchBusiness,
    handleSearchFieldChange,
    handleSearchSubmit,
  } = props;
  // const [personCount, setPersonCount] = useState('');
  // const [location, setLocation] = useState('');
  // const [business, setBusiness] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [locationCity, setLocationCity] = useState('');
  const [query, setQuery] = useState("");

  const filteredCities =
    query === ""
      ? cities
      : cities.filter(({ name }) => {
          return name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    const res = getStatesInUSA();
    console.log(res);
    setStates(res);
  }, []);

  // const handleSubmit = () => {
  //     try {
  //         const res = search(currentUserToken, personCount, location, business);
  //         console.log(res);
  //     }
  //     catch (error) {
  //         console.log(error.response.data.msg);
  //     }

  // }
  useEffect(() => {
    const currentCities = getCities(searchState);
    // let modifiedCities = [];
    // currentCities.forEach((item, index) => {
    //     modifiedCities.push({ ...item, id: index })
    // });
    console.log(currentCities);
    // setCities([]);
    //console.log(modifiedCities);
    setCities(currentCities);
  }, [searchState]);

  // const handleChange = (event) => {
  //     const { name, value } = event.target;
  //     if (name == "personCount")
  //         setPersonCount(value);
  //     else if (name == "location") {
  //         setLocation(value);

  //         const currentCities = getCities(value);
  //         // let modifiedCities = [];
  //         // currentCities.forEach((item, index) => {
  //         //     modifiedCities.push({ ...item, id: index })
  //         // });
  //         console.log(currentCities);
  //         setCities([]);
  //         //console.log(modifiedCities);
  //         setCities(currentCities);
  //     }

  //     else if (name == "business")
  //         setBusiness(value);
  //     else if (name == "locationCity")
  //         setLocationCity(value);

  // }

  return (
    <div className="w-full flex flex-col px-1.5  my-1.5 items-center md:flex md:flex-row md:h-24 md:items-center md:justify-center md:w-full sm:h-fit  ">
      {/* <label htmlFor="Buisness" className=" flex h-50 flex-col text-sm pt-150 absolute  font-medium text-gray-700 ">Buisness</label> */}
      {/* <div className=" flex flex-row items-center justify-center w-screen rounded-md shadow-sm "> */}
      {/* <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <span class="text-gray-500 sm:text-sm"> $ </span>
    </div> */}
      <div className="md:h-3/5 md:flex  md:rounded-l-full md:justify-center justify-center flex flex-col h-fit w-full rounded-md h-fit">
        <div>
          {" "}
          <label htmlFor="userCategory" className="sr-only">
            Categories
          </label>
        </div>
        <select
          id="userCategory"
          value={userCategory}
          name="userCategory"
          className="focus:ring-indigo-500 sm:w-full pr-2 md:wrap-content  focus:border-indigo-500 h-full pl-7 border-transparent bg-white sm:text-sm md:rounded-l-full "
          placeholder="Category"
          onChange={handleSearchFieldChange}
        >
          <option value="0" hidden>
            Select Category
          </option>

          {categorySections.map((category) => (
            <option value={category._id}>{category.title}</option>
          ))}
        </select>
      </div>
      <div className=" md:h-3/5 md:flex  md:rounded-l-full md:justify-center justify-center flex flex-col h-fit w-full rounded-md h-fit">
        <div>
          {" "}
          <label htmlFor="location" className="sr-only">
            State
          </label>
        </div>
        <select
          id="searchState"
          value={searchState}
          name="searchState"
          className="focus:ring-indigo-500 sm:w-full  pr-2 md:wrap-content  focus:border-indigo-500 h-full pl-7 border-transparent bg-white sm:text-sm"
          placeholder="People"
          onChange={handleSearchFieldChange}
        >
          <option value="">State</option>

          {states.map(({ name, isoCode }) => (
            <option value={name} key={isoCode}>
              {name}
            </option>
          ))}
        </select>
      </div>
      {/* <div className="  h-16  flex  rounded-l-full justify-center">
                    <div> <label htmlFor="locationCity" className="sr-only">Number of Persons</label></div>

                    <div className="focus:ring-black focus:border-black block border h-16 w-48 pl-2 pr-1 sm:text-xl border-gray-300">
                        <Combobox value={locationCity} name={locationCity} onChange={setLocationCity} >
                            <Combobox.Input onChange={(event) => setQuery(event.target.value)} />
                            <Combobox.Options>
                                {filteredCities.map(({ name }) => (
                                    <Combobox.Option key={name} value={name}>
                                        {name}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Combobox>
                    </div>
                </div> */}

      <div className="md:h-3/5  md:flex  md:rounded-l-full md:justify-center justify-center flex flex-col h-fit w-full rounded-md h-fit">
        <div>
          {" "}
          <label htmlFor="locationCity" className="sr-only">
            City
          </label>
        </div>
        <select
          id="searchCity"
          value={searchCity}
          name="searchCity"
          className="focus:ring-indigo-500 sm:w-full  pr-2 md:wrap-content  focus:border-indigo-500 h-full pl-7 border-transparent bg-white sm:text-sm  "
          placeholder="People"
          onChange={handleSearchFieldChange}
        >
          <option value="">City</option>

          {cities.map(({ name, id }) => (
            <option value={name} key={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
      {/* <input type="text" name="location" value={location} id="location" className="focus:ring-black focus:border-black block border border-black h-16 w-48 pl-7 pr-12 sm:text-2xl border-gray-300" placeholder="Location" onChange={handleChange} /> */}

      <input
        type="text"
        name="searchBusiness"
        value={searchBusiness}
        id="searchBusiness"
        className=" md:h-3/5  focus:ring-indigo-500 w-full pr-2 md:wrap-content  focus:border-indigo-500 h-full pl-7 border-transparent bg-white sm:text-sm  "
        placeholder="Buisness name"
        onChange={handleSearchFieldChange}
      />
      <div className="md:h-3/5">
        <button
          onClick={handleSearchSubmit}
          className="md:rounded-r-full bg-red-500 h-full  px-9 text-xl font-bold hover:bg-red-700 text-white sm:text-md"
        >
          Search
        </button>
      </div>

      {/* </div> */}
    </div>
  );
};

// const mapStateToProps = (state) => ({
//     currentUserToken: state.user.currentUser.token
// })

// export default connect(mapStateToProps)(SearchBar);
export default SearchBar;
