import React, { useEffect } from "react";
import Directory from "../../Components/Directory/directory.component";
import "./homepage.styles.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import SearchBar from "../../Components/SearchBar/searchbar.component";
import { getAllAppointments } from "../../Redux/BookedAppointments/appt.selector";
import { setAllAppointments } from "../../Redux/BookedAppointments/appt.actions";
import { getUserBookings } from "../../Backend API's/SlotsAPI/appointment";
const HomePage = ({ currentUser }) => {
  const dispatch = useDispatch();
  const allAppointments = useSelector(getAllAppointments);
  useEffect(() => {
    const fetchData = async () => {
      if (allAppointments.length == 0 && currentUser != null) {
        const resp = await getUserBookings(currentUser?.token);
        dispatch(setAllAppointments(resp.data));
      }
    };
    fetchData().catch((error) => console.error);
  }, []);
  const backgroundImg =
    "https://images.unsplash.com/photo-1624969862293-b749659ccc4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YXBwb2ludG1lbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60";
  return (
    <div className="homepage">
      <div
        className="background-image flex flex-col  justify-center "
        style={{
          backgroundImage: `url(${backgroundImg})`,
        }}
      >
        {/* <SearchBar /> */}
      </div>

      <div className="flex flex-col w-full justify-center items-center">
        <Directory currentUser={currentUser} />
      </div>

      {/* <h1>Welcome to OpenApp home page. </h1> */}
      {/* <Directory /> */}
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps)(HomePage);
