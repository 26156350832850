import React, { useState } from "react";
import { setCurrentUser } from "../../Redux/user/user.actions";
import { connect } from "react-redux";
import CustomButton from "../CustomButton/custom-button.component";
import { register } from "../../Backend API's/Authentication/authentication.api";
import { useSnackbar } from "notistack";
import validator from "validator";

const SignUp = ({ setCurrentUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validateEmail = (email) => {
    const isValidEmail = validator.isEmail(email);
    return isValidEmail;
  };
  const validatePassword = (password) => {
    const isValidPassword = validator.isStrongPassword(password);
    return isValidPassword;
  };
  const handleSubmit = async (event) => {
    const emptyStr = "";
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const res = await register(email, password, firstName, lastName);
      setCurrentUser(res.data);
      setError(emptyStr);
    } catch (error) {
      setError(error.response.data.msg);
      // alert(error.response.data.msg);
      enqueueSnackbar(`${error.response.data.msg} Please try again`, {
        variant: "error",
      });
    }
    enqueueSnackbar("Sign up successful...", { variant: "info" });
    setEmail(emptyStr);
    setPassword(emptyStr);
    setConfirmPassword(emptyStr);
    setFirstName(emptyStr);
    setLastName(emptyStr);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      if (!validateEmail(value)) {
        setIsEmailValid(false);
      } else setIsEmailValid(true);
      setEmail(value);
    } else if (name === "password") {
      if (!validatePassword(value)) {
        setIsPasswordValid(false);
      } else setIsPasswordValid(true);

      setPassword(value);
    } else if (name === "confirmPassword") setConfirmPassword(value);
    else if (name === "firstName") setFirstName(value);
    else if (name === "lastName") setLastName(value);
  };

  return (
    <div className="flex items-center sm:w-full sm:pt-8  justify-center md:w-1/2 ">
      <div className="max-w-md w-96 ">
        <h2 className=" text-center md:text-3xl sm:text-sm font-extrabold text-gray-900">
          I do not have an account
        </h2>
        {error ? (
          <p className=" mt-2 text-center text-xl text-gray-600">
            <span className="font-medium text-red-600 "> {error}</span>
          </p>
        ) : (
          <div />
        )}
        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="firstName" className="sr-only">
                First Name
              </label>
              <input
                value={firstName}
                id="firstName"
                name="firstName"
                type="text"
                autoComplete="current-firstName"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-tranparent focus:z-10 sm:text-sm"
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="sr-only">
                Last Name
              </label>
              <input
                value={lastName}
                id="lastName"
                name="lastName"
                type="text"
                autoComplete="current-lastName"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-tranparent focus:z-10 sm:text-sm"
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <div class={isEmailValid ? "" : "invalid"}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                value={email}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-tranparent focus:z-10 text-sm"
                onChange={handleChange}
                placeholder="Email address"
              />
            </div>
            <div class={isPasswordValid ? "" : "invalid"}>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                value={password}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border  border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm"
                onChange={handleChange}
                placeholder="Password"
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="sr-only">
                Confirm Password
              </label>
              <input
                value={confirmPassword}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2  border-gray-300 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                onChange={handleChange}
                placeholder="Confirm Password"
              />
            </div>
          </div>
          <div className="text-sm text-left">
            <b>Password requirements:</b>
            <ul className="list-disc">
              <li>Minimum length 8</li>
              <li>Must have atleast one uppercase letter</li>
              <li>Must have atleast one lowercase letter</li>
              <li>Must have at least 1 digit</li>
              <li>Should not have spaces</li>
            </ul>
          </div>

          {/* <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900"> Remember me </label>
                            </div>
                        </div> */}

          <CustomButton type="submit"> SIGN UP</CustomButton>
        </form>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(null, mapDispatchToProps)(SignUp);
