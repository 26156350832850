import React from "react";

const ChartReview = ({ globalStarRatings, totalRating }) => {
  const { fiveStar, fourStar, oneStar, threeStar, twoStar } = globalStarRatings;
  console.log(globalStarRatings, totalRating);

  return (
    <div className="flex flex-col border-b-2 border-grey-700 pt-2 pb-4">
      {" "}
      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
        {totalRating?.toFixed(2)} Global Ratings
      </p>
      <div className="flex items-center mt-4">
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          5 star
        </span>
        <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-400">
          <div
            className="h-5 bg-yellow-400 rounded"
            style={{ width: `${fiveStar}%` }}
          ></div>
        </div>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          {fiveStar?.toFixed(2)}
        </span>
      </div>
      <div className="flex items-center mt-4">
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          4 star
        </span>
        <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-400">
          <div
            className="h-5 bg-yellow-400 rounded"
            style={{ width: `${fourStar}%` }}
          ></div>
        </div>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          {fourStar?.toFixed(2)}
        </span>
      </div>
      <div className="flex items-center mt-4">
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          3 star
        </span>
        <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-400">
          <div
            className="h-5 bg-yellow-400 rounded"
            style={{ width: `${threeStar}%` }}
          ></div>
        </div>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          {threeStar?.toFixed(2)}
        </span>
      </div>
      <div className="flex items-center mt-4">
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          2 star
        </span>
        <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-400">
          <div
            className="h-5 bg-yellow-400 rounded"
            style={{ width: `${twoStar}%` }}
          ></div>
        </div>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          {twoStar?.toFixed(2)}
        </span>
      </div>
      <div className="flex items-center mt-4">
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          1 star
        </span>
        <div className="w-2/4 h-5 mx-4 bg-gray-200 rounded dark:bg-gray-400">
          <div
            className="h-5 bg-yellow-400 rounded"
            style={{ width: `${oneStar}%` }}
          ></div>
        </div>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-500">
          {oneStar?.toFixed(2)}
        </span>
      </div>{" "}
    </div>
  );
};
export default ChartReview;
