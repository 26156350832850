import { SET_ALL_BUSINESSES, REMOVE_ALL_BUSINESSES,SET_SEARCHED_BUSINESSES, REMOVE_SEARCHED_BUSINESSES, SET_SELECTED_BUSINESS, REMOVE_SELECTED_BUSINESS, SET_MARKERS, REMOVE_MARKERS } from "./businesses.types"

export const setAllBusinesses = allBusinesses => ({
    type: SET_ALL_BUSINESSES,
    payload: allBusinesses
})

export const removeAllBusinesses = () => ({
    type: REMOVE_ALL_BUSINESSES,
})
export const setSearchedBusinesses = businesses => ({
    type: SET_SEARCHED_BUSINESSES,
    payload: businesses
})
export const removeSearchedBusinesses = () => ({
    type: REMOVE_SEARCHED_BUSINESSES,
})
export const setSelectedBusiness = business => ({
    type: SET_SELECTED_BUSINESS,
    payload: business
})
export const removeSelectedBusiness = () => ({
    type: REMOVE_SELECTED_BUSINESS,
})
export const setMarkers = markers => ({
    type: SET_MARKERS,
    payload: markers
})
export const removeMarkers = () => ({
    type: REMOVE_MARKERS,
})