import React, { useState, useEffect } from "react";
import AddSlots from "../BuisnessPageComponents/AddSlots/addSlots.component";
import Home from "../BuisnessPageComponents/Home/home.component";
import Profile from "../BuisnessPageComponents/Profile/profile.component";
import ViewBookedSlots from "../BuisnessPageComponents/ViewBookedSlots/viewBookedSlots.component";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { Link } from "react-router-dom";
import { getBusinessByUserID } from "../../Backend API's/BusinessAPI/business_api";
import ViewAddServices from "../BuisnessPageComponents/AddServices/ViewAddServices.component";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../Redux/user/user.selector";
import {
  FaHouseDamage,
  FaCalendarWeek,
  FaUtensils,
  FaBusinessTime,
  FaUser,
  FaBars,
} from "react-icons/fa";
import StaffList from "../BuisnessPageComponents/staff/staffList/staffList.component";

const BusinessFunctions = () => {
  const currentUser = useSelector(getCurrentUser);
  const [toggleTab, setToggleTab] = useState(1);
  const [businessObject, setBusinessObject] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([
    {
      svg: <FaHouseDamage size={25} onClick={() => toggle(1)} />,
      idx: 1,
      title: "Home",
    },
    {
      svg: <FaUtensils size={25} onClick={() => toggle(2)} />,
      idx: 2,
      title: "Services",
    },
    {
      svg: <FaBusinessTime size={25} onClick={() => toggle(3)} />,
      idx: 3,
      title: "Working Hours",
    },
    {
      svg: <FaCalendarWeek size={25} onClick={() => toggle(4)} />,
      idx: 4,
      title: "Appointments",
    },
    {
      svg: <FaUser size={25} onClick={() => toggle(5)} />,
      idx: 5,
      title: "Profile",
    },
  ]);
  const toggle = (idx) => {
    setToggleTab(idx);
    console.log(idx);
  };
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
    console.log(isOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      const resp = await getBusinessByUserID(currentUser?.token);
      console.log(resp.data[0]._id);
      setBusinessObject(resp.data[0]);
    };
    fetchData().catch((error) => console.error);
  }, []);
  return (
    <div className="flex flex-row h-full space-x-3 w-full ">
      <div className="flex flex-col w-fit ">
        <div className="flex flex-col w-full h-screen  rounded-md bg-red-50 space-y-2">
          {isOpen ? (
            <button
              className=" py-4 px-6 md:text-xl sm:text-sm font-bold"
              onClick={toggleIsOpen}
            >
              Collapse Menu{" "}
            </button>
          ) : (
            <div class="py-4 px-6 cursor-pointer">
              <FaBars size={25} onClick={toggleIsOpen} />
            </div>
          )}

          {options.map((option) => (
            <>
              {" "}
              <div class="cursor-pointer flex flex-row space-x-2 mx-2">
                <div
                  class={`py-4 px-4  ${
                    toggleTab === option.idx ? "bg-red-300" : "bg-red-50"
                  }`}
                >
                  {option.svg}
                </div>
                {isOpen ? (
                  <div class={`w-full`}>
                    <button
                      className=" text-left md:text-lg w-fit wrap-content  sm:text-sm py-3 text-black  font-bold hover:transition hover:duration-100 hover:text-black hover:font-bold focus:font-bold rounded h-15"
                      key={option.idx}
                      onClick={() => toggle(option.idx)}
                    >
                      {option.title}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ))}
        </div>

        {/* {isOpen ?
                    <div className='flex flex-col w-full h-full rounded-md bg-red-50 items-center'>
                        <button className="top-4 right-8 md:text-2xl sm:text-sm" onClick={toggleIsOpen}> x </button>
                        {options.map(option => (<button className=' md:text-xl sm:text-sm py-3 text-black w-full hover:bg-red-300 font-bold hover:transition hover:duration-100 mt-5 hover:text-black hover:font-bold focus:font-bold rounded h-15  focus:bg-red-700 focus:text-white' key={option.idx} onClick={() => toggle(option.idx)}>{option.title}</button>)
                        )}



                    </div> :
                    <button onClick={() => toggleIsOpen()}>
                        <MenuIcon />
                    </button>
                } */}
      </div>

      <div className="flex flex-col w-11/12 ">
        {toggleTab === 1 ? (
          <Home />
        ) : toggleTab === 2 ? (
          <ViewAddServices business={businessObject} />
        ) : toggleTab === 3 ? (
          <AddSlots />
        ) : toggleTab === 4 ? (
          <ViewBookedSlots />
        ) : toggleTab === 5 ? (
          <StaffList />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default BusinessFunctions;
