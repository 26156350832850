import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUnverifiedBusiness, saveApprovedBusiness } from '../../Backend API\'s/Admin API/admin.api';
import { Link } from 'react-router-dom';
import ReadMore from '../../Components/ReadMoreReadLess/readMoreReadLess.component';

const UnverifiedBusiness = ({ currentUser }) => {
    const [unverifiedBusiness, setUnverifiedBusiness] = useState([]);

    const saveData = async (businessId) => {
        const res = await saveApprovedBusiness(businessId, currentUser.token);
        fetchData();
    }
    const fetchData = async () => {
        const res = await getUnverifiedBusiness(currentUser.token);
        console.log(res.data);
        setUnverifiedBusiness(res.data);
    }
    useEffect(() => {
        fetchData().catch(console.error);;
    }, []);

    return (<div className='flex flex-col px-5'>
        {unverifiedBusiness.length === 0 ?
            (<span className=' md:text-xl sm:text-sm font-semibold'>No new businesses to verify yet
            </span>)
            :
            unverifiedBusiness.map(({ _id, name, license, description, location, phone, services, images, isVerified }) => (
                <div className='grid grid-cols-4 max-h-max w-full px-2 py-2 pb-4 pt-4 border-b-2'>
                    <Link to='/' className='rounded-md col-span-1' style={{
                        backgroundImage: `url(${images[0]?.url})`,
                        backgroundSize: 'cover'
                    }} />
                    <div className='flex flex-col px-4 w-full col-span-2'>
                        <Link to='/' className='md:text-xl sm:text-sm font-semibold' >{name}</Link>
                        <div className='text-sm'> {location.formattedAddress}</div>
                        <div className=' h-10 pt-1 justify-between '>
                            License: {license}
                        </div>
                        <div className=' h-10 pt-1 justify-between '>
                            Phone : {phone}
                        </div>
                        {/* <div className='grid grid-cols-5 h-10 pt-1 justify-between '>
                            {
                                services.map((item) => {
                                    console.log(item);
                                    return (<button className="bg-black text-red-50 px-2 w-20 col-span-1 hover:bg-white hover:border hover:text-black hover:font-bold h-10 rounded-md  border-2 items-center justify-center">
                                        {item.name}
                                    </button>)
                                }
                                )
                            }

                        </div> */}
                        <div className='flex text-sm pt-5'>
                            <ReadMore>{description}</ReadMore>
                        </div>
                    </div>
                    <div className='flex flex-col px-4 w-full col-span-1  justify-center'>
                        <button onClick={() => saveData(_id)} className="bg-black text-red-50 px-2 w-30 col-span-1 hover:bg-white hover:border hover:text-black hover:font-bold h-10 rounded-md  border-2 items-center justify-center">
                            Approve
                        </button>
                        {
                            isVerified === true ?
                                (<div className='flex text-sm pt-5'>
                                    Business Approved
                                </div>) :
                                (<div />)
                        }
                    </div>
                </div>
            ))

        }

    </div>)
}
const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser
});
export default connect(mapStateToProps)(UnverifiedBusiness);