import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import apptReducer from './BookedAppointments/appt.reducer';
import  businessReducer from './Businesses/businesses.reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import categoryReducer from './Categories/categories.reducer';
import mapViewReducer from './MapView/mapview.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user','businessData','categories','appointments']
}

const rootReducer = combineReducers({
    user: userReducer,
    appointments: apptReducer,
    businessData: businessReducer,
    categories: categoryReducer,
    mapview:mapViewReducer
});


// export default rootReducer;
export default persistReducer(persistConfig, rootReducer);