
import { State, City } from 'country-state-city';

export const getCities = (stateValue = 'New York') => {
    if(stateValue === '') stateValue = 'New York';
   
    const states = State.getStatesOfCountry('US');
    const state = states.find(s => s.name === stateValue);
    console.log(stateValue);
    const currentCities = City.getCitiesOfState('US', state.isoCode);
    console.log(currentCities);

    return currentCities;
    
}