import { useState } from "react";
import {
  FaFacebook,
  FaGlobeAmericas,
  FaInstagram,
  FaPinterest,
  FaTrash,
} from "react-icons/fa";
import { connect } from "react-redux";
import { getBusinessByUserID } from "../../../../Backend API's/BusinessAPI/business_api";
import {
  deleteStaffById,
  updateStaffById,
} from "../../../../Backend API's/StaffAPI/staff.api";
import useInput from "../../../../hooks/use-input";
import CustomButton from "../../../CustomButton/custom-button.component";
import StaffSocialsInput from "../StaffSocialsInput/StaffSocialsInput.component";
import { useSnackbar } from "notistack";

const FORM_CONTROL = "form-control";

const StaffListItem = ({
  currentUser,
  details,
  refreshAllStaffListHandler,
  businessId,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [socialMediaSections, setSocialMediaSections] = useState(1);
  const { enqueueSnackbar } = useSnackbar();

  const {
    value: enteredFirstName,
    isTouched: isEnteredFirstNameTouched,
    hasError: isEnteredFirstNameInvalid,
    inputChangeHandler: firstNameChangeHandler,
    inputBlueHandler: firstNameBlurHandler,
    reset: firstNameReset,
    patch: firstNamePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredLastName,
    isTouched: isEnteredLastNameTouched,
    hasError: isEnteredLastNameInvalid,
    inputChangeHandler: lastNameChangeHandler,
    inputBlueHandler: lastNameBlurHandler,
    reset: lastNameReset,
    patch: lastNamePatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredEmail,
    isTouched: isEnteredEmailTouched,
    hasError: isEnteredEmailInvalid,
    inputChangeHandler: emailChangeHandler,
    inputBlueHandler: emailBlurHandler,
    reset: emailReset,
    patch: emailPatch,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: enteredPhone,
    isTouched: isEnteredPhoneTouched,
    hasError: isEnteredPhoneInvalid,
    inputChangeHandler: phoneChangeHandler,
    inputBlueHandler: phoneBlurHandler,
    reset: phoneReset,
    patch: phonePatch,
  } = useInput((value) => value.trim().length > 0);

  let isFormValid = false;

  if (
    !(
      isEnteredFirstNameInvalid &&
      isEnteredLastNameInvalid &&
      isEnteredEmailInvalid &&
      isEnteredPhoneInvalid
    )
  ) {
    isFormValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!isFormValid) {
      return;
    }

    var socialMedia = {};
    console.log("Sections", socialMediaSections);

    const _ = [...Array(socialMediaSections)].map((item, key) => {
      const selection = event.target.elements?.[`social_media_${key}`]?.value;
      const otherKey =
        event.target.elements?.[`social_media_other_${key}`]?.value;
      if (selection === "other") {
        socialMedia[otherKey] =
          event.target.elements[`social_media_other_url_${key}`].value;
      } else if (selection !== "Choose an option") {
        socialMedia[selection] =
          event.target.elements[`social_media_other_url_${key}`].value;
      }
    });

    //socialMedia = JSON.stringify(socialMedia);
    console.log("Social Media", socialMedia);

    const updatedData = {
      ...details,
      firstname: enteredFirstName,
      lastname: enteredLastName,
      email: enteredEmail,
      phone: enteredPhone,
      socialmedia: socialMedia,
    };

    try {
      const res = await getBusinessByUserID(currentUser?.token);
      const businessId = res.data[0]?._id;
      const updateRes = await updateStaffById(
        updatedData,
        currentUser?.token,
        businessId
      );
      enqueueSnackbar("Staff info updated", { variant: "info" });
      refreshAllStaffListHandler();
      handleCancel();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error.response.data.msg} Please try again`, {
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    handleFormDisplay(false);
  };

  const patchValues = () => {
    firstNamePatch(details.firstname || "");
    lastNamePatch(details.lastname || "");
    emailPatch(details.email || "");
    phonePatch(details.phone || "");
  };

  const handleFormDisplay = (isShow) => {
    patchValues();
    setShowForm(isShow);
  };

  const firstNameClasses = isEnteredFirstNameInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const lastNameClasses = isEnteredLastNameInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const emailClasses = isEnteredEmailInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  const phoneClasses = isEnteredPhoneInvalid
    ? `${FORM_CONTROL} invalid`
    : FORM_CONTROL;

  var socials = null;
  try {
    console.log(details);
    if (details?.socialmedia) socials = JSON.parse(details?.socialmedia);
  } catch (Exception) {
    console.log(Exception);
  }

  const handleDeleteStaff = async (e) => {
    try {
      const updateRes = await deleteStaffById(
        details,
        currentUser?.token,
        businessId
      );
      refreshAllStaffListHandler();
      handleCancel();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-2 m-2">
      {!showForm && (
        <span className="flex justify-between">
          <div>
            <p className="text-black font-bold">
              {details.firstname} {details.lastname}
            </p>
            <p>
              <a className="text-sky-600" href={`mailto:${details.email}`}>
                {details.email}
              </a>
            </p>
            <p>
              <a className="text-fuchsia-700" href={`tel:${details.phone}`}>
                {details.phone}
              </a>
            </p>
            <div style={{ display: "flex" }}>
              {socials?.["facebook"] && (
                <a href={socials?.["facebook"]} target="_blank">
                  <FaFacebook size="20" className="fb-icon" />
                </a>
              )}
              {socials?.["instagram"] && (
                <a href={socials?.["instagram"]} target="_blank">
                  <FaInstagram size="20" className="instagram-icon" />
                </a>
              )}
              {socials?.["pinterest"] && (
                <a href={socials?.["pinterest"]} target="_blank">
                  <FaPinterest size="20" className="pinterest-icon" />
                </a>
              )}
              {socials?.["other"] &&
                Object.keys(socials?.["other"]).map((key, value) => (
                  <a href={socials["other"][key]} target="_blank" key={key}>
                    <FaGlobeAmericas size="20" className="other-icons" />
                  </a>
                ))}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <CustomButton onClick={(e) => handleFormDisplay(true)}>
              Edit
            </CustomButton>
            <CustomButton
              onClick={handleDeleteStaff}
              style={{ backgroundColor: "red", marginLeft: "1em" }}
            >
              <FaTrash size="20" />
            </CustomButton>
          </div>
        </span>
      )}
      {showForm && (
        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className={firstNameClasses}>
              <label htmlFor="firstName">
                First Name<em class="error-text">*</em>
              </label>
              <input
                type="text"
                id="firstName"
                value={enteredFirstName}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {isEnteredFirstNameInvalid && (
                <p className="error-text">First Name can not be empty</p>
              )}
            </div>
            <div className={lastNameClasses}>
              <label htmlFor="lastName">
                Last Name<em class="error-text">*</em>
              </label>
              <input
                type="text"
                id="lastName"
                value={enteredLastName}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
              />
              {isEnteredLastNameInvalid && (
                <p className="error-text">Last Name can not be empty</p>
              )}
            </div>
            <div className={emailClasses}>
              <label htmlFor="lastName">
                Email<em class="error-text">*</em>
              </label>
              <input
                type="email"
                id="email"
                value={enteredEmail}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
              />
              {isEnteredEmailInvalid && (
                <p className="error-text">Please enter a valid Email </p>
              )}
            </div>
            <div className={phoneClasses}>
              <label htmlFor="lastName">
                Phone<em class="error-text">*</em>
              </label>
              <input
                type="p"
                id="email"
                value={enteredPhone}
                onChange={phoneChangeHandler}
                onBlur={phoneBlurHandler}
              />
              {isEnteredPhoneInvalid && (
                <p className="error-text">Please enter a valid Phone number </p>
              )}
            </div>
            <div style={{ flexBasis: "100%" }}></div>
            {[...Array(socialMediaSections)].map((item, key) => (
              <StaffSocialsInput suffix={key} key={key} />
            ))}
            <div
              className=" social-media-container"
              style={{ paddingTop: "0.5em" }}
            >
              {
                <CustomButton
                  type="button"
                  primary="bg-blue-700"
                  secondary="bg-blue-500"
                  onClick={() => {
                    setSocialMediaSections(socialMediaSections + 1);
                  }}
                >
                  +
                </CustomButton>
              }
            </div>
          </div>
          <div className="form-actions">
            <CustomButton
              type="submit"
              disabled={!isFormValid}
              primary="bg-lime-700"
              secondary="bg-lime-500"
            >
              Update
            </CustomButton>
            <CustomButton
              type="button"
              primary="bg-rose-700"
              secondary="bg-rose-500"
              onClick={handleCancel}
            >
              cancel
            </CustomButton>
          </div>
        </form>
      )}
      <hr />
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(StaffListItem);
